import store from 'utils/../reduxStore'
import { batch } from 'react-redux'
import { createAction } from '@reduxjs/toolkit'
import _ from 'lodash'
import ADP from 'awesome-debounce-promise'
import { formatConveyorsAndConveyorSections } from 'utils/api/conveyor/helpers'
import ConveyorService from 'utils/api/conveyor/ConveyorService'
import { setLoading, setLoadingOverride, openConfirmModal } from 'shared/redux/ScreenActions'
import { formatIdAsKey } from 'utils/helpers'
import {
  updateVersionMetadata,
  setAllMetaDataForVersionsConveyors,
  selectVersion,
  getVersionOptions,
} from '../../Version/redux/VersionActions'
import {
  fetchDefaultAccessoriesForConveyor,
  fetchDefaultMaterialsForConveyor,
  initializeDefaultMaterialsAcessoriesMetadata,
} from '../../Estimator/redux/EstimatorActions'
import conveyorDefaultValues from 'shared/constants/conveyorDefaultValues'
import { addConveyorSectionMetadata } from '../../Estimator/components/ConveyorBuilder/redux/ConveyorBuilderActions'
import { getGearmotorForConveyor } from 'features/Estimator/components/Gearmotor/redux/GearmotorActions'
import { updateProjectReducerVersion } from 'features/Project/redux/ProjectActions'
import { getChainSerieInfo, updateConveyorChain } from 'features/Chain/redux/ChainActions'
import { getConveyorsOrders } from './ConveyorSelectors'
import { getProductsForConveyor, createProduct } from 'features/Product/redux/ProductActions'
import { validateProjectAndUpdateTabs } from 'features/Project/components/ProjectSummary/utils/validateProject'
import ChainService from 'utils/api/chain/ChainService'
import { IConveyor, IConveyorOrderRequest, IPriceValidation, IVersion } from 'shared/types/swagger'
import { IConveyorWithKeyedSections } from 'shared/types/Conveyor'
import { IConveyorMeta } from '../shared/types'
import { IPriceValidationVersionAndConveyorsResponse } from 'shared/types/PriceValidationVersionAndConveyorsResponse'

export const resetConveyorStore = createAction('RESET_CONVEYOR_STORE')
export const saveConveyorToConveyors = createAction<IConveyor | IConveyorWithKeyedSections>(
  'SAVE_CONVEYOR_TO_CONVEYORS'
)
export const saveConveyorOrder = createAction<{ id: string; order: number }>('SAVE_CONVEYOR_ORDER')
export const saveOptionToOptions = createAction<IPriceValidation>('SAVE_OPTION_TO_OPTIONS')
export const saveOptionToConveyorOptions = createAction<{
  conveyorId: number;
  optionType: string;
  data: Record<string, IPriceValidation>;
}>('SAVE_OPTION_TO_CONVEYOR_OPTIONS')
export const saveVersionConveyors = createAction<Record<string, IConveyorWithKeyedSections>>(
  'SAVE_VERSION_CONVEYORS'
)
export const saveVersionOptions = createAction<Partial<Record<string, IPriceValidation>>>(
  'SAVE_VERSION_OPTIONS'
)
export const updateOptions = createAction<Partial<Record<string, IPriceValidation>>>(
  'UPDATE_OPTIONS'
) // Replaces the options array with payload
export const saveVersionOptionsOrder = createAction<Array<IPriceValidation['id']>>('SAVE_VERSION_OPTIONS_ORDER')
export const deleteOneFromVersionOptionsOrder = createAction<IPriceValidation['id']>('DELETE_ONE_FROM_VERSION_OPTIONS_ORDER')

// Conveyor Metadata Reducer
export const collapseAllConveyors = createAction('COLLAPSE_ALL_CONVEYORS')
export const collapseConveyor = createAction<{
  conveyorId: number;
  versionId: number;
  collapsed: boolean;
}>('COLLAPSE_CONVEYOR')
export const deleteConveyorMetadata = createAction<{ conveyorId: number; versionId: number }>(
  'DELETE_CONVEYOR_METADATA'
)
export const updateConveyorMetadata = createAction<{
  conveyorId: number;
  conveyorMetadata: IConveyorMeta;
  versionId: number;
}>('UPDATE_CONVEYOR_METADATA')
export const updateCompletedTabs = createAction<{
  conveyorId: number;
  versionId: number;
  completedTabs: { chain?: boolean; product?: boolean; estimator?: boolean };
}>('UPDATE_COMPLETED_TABS')
export const updateCurrentTab = createAction('UPDATE_CURRENT_TAB')
export const removeNotQueuedMasterVersionsMetadata = createAction<{ versionIds: Array<number> }>(
  'REMOVE_NOT_QUEUED_MASTER_VERSIONS_METADATA'
)

function onUnload(e) {
  // the method that will be used for both add and remove event
  // eslint-disable-next-line no-param-reassign
  const _e = e || window.event
  const confirmationMessage = 'You may lose unsaved changes if you leave this page.'
  _e.returnValue = confirmationMessage
  return confirmationMessage
}

export const getConveyor = (conveyorId) => async (dispatch) => {
  return new Promise(async (resolve, reject) => {
    await ConveyorService.getConveyor(conveyorId)
      .then(async (data) => {
        dispatch(saveConveyorToConveyors(data[conveyorId]))
      })
      .catch((e) => {
        dispatch(setLoading(false))
        reject(e)
      })
  })
}

export const addConveyorsMetadata = (versionId, conveyors) => async (dispatch) => {
  const existingMetadata = formatIdAsKey(
    Object.values(store.getState().ConveyorMetaReducer[versionId] || {})
  )
  const conveyorsWithMetadata = existingMetadata ? Object.keys(existingMetadata) : []

  const newMetadata: Array<IConveyorMeta> = _.map(conveyors, (conveyor) => {
    if (!conveyorsWithMetadata.includes(`${conveyor.id}`)) {
      return {
        id: conveyor.id,
        collapsed: false,
        conveyorFirstValidation: false, // Conveyor needs to start open to check validation state
        allowedTabs: {
          chain: true,
          product: false,
          estimator: false,
        },
        completedTabs: {
          chain: false,
          product: false,
          estimator: false,
        },
        currentTab: 'chain',
      }
    } else {
      return existingMetadata[conveyor.id]
    }
  })
  dispatch(updateVersionMetadata({ versionId, metadata: formatIdAsKey(newMetadata) }))
}

export const cloneConveyor = (conveyorId) => async (dispatch) => {
  const { versionId } = store.getState().VersionReducer
  const { conveyors } = store.getState().ConveyorReducer
  dispatch(setLoading({ loading: true, loadingMessage: 'Cloning Conveyor...' }))
  await ConveyorService.cloneConveyor(conveyorId)
    .then(async (data) => {
      const { products, ...conveyor } = Object.values(data)[0]
      batch(() => {
        dispatch(addConveyorsMetadata(versionId, { ...conveyors, ...data }))
        dispatch(addConveyorSectionMetadata([conveyor]))
        dispatch(getProductsForConveyor(conveyor.id, products))
        dispatch(initializeDefaultMaterialsAcessoriesMetadata({ conveyorId: conveyor.id }))
        dispatch(saveConveyorToConveyors(conveyor))
      })

      const stdchaindescriptions = await ChainService.getChainSerieInfo(
        conveyor.chain.chainserieid,
        conveyor.chain.stdchainwidthid
      )
      batch(() => {
        dispatch(
          updateConveyorChain({
            conveyorId: conveyor.id,
            chain: stdchaindescriptions,
            versionId,
          })
        )
        validateProjectAndUpdateTabs(versionId)
        dispatch(setLoading(false))
      })
    })
    .catch((e) => {
      debugger
      dispatch(setLoading(false))
    })
}

export const addConveyorToVersion = (versionId) => async (dispatch) => {
  window.addEventListener('beforeunload', onUnload)
  dispatch(setLoading({ loading: true, loadingMessage: 'Adding new conveyor...' }))
  const conveyors = { ...store.getState().ConveyorReducer.conveyors }
  const conveyor = await ConveyorService.addConveyorToVersion(versionId, conveyorDefaultValues)

  dispatch(getChainSerieInfo(versionId, conveyor.id, conveyor.chain))

  batch(async () => {
    const formattedConveyors = formatConveyorsAndConveyorSections({
      ...conveyors,
      [conveyor.id]: conveyor,
    })
    await dispatch(createProduct(conveyor.id))
    await dispatch(saveVersionConveyors(formattedConveyors))
    await dispatch(setAllMetaDataForVersionsConveyors(formattedConveyors, versionId))
    await dispatch(
      collapseConveyor({
        conveyorId: conveyor.id,
        versionId,
        collapsed: false,
      })
    )
    await dispatch(setLoadingOverride({ loadingOverride: false }))
  })
  window.removeEventListener('beforeunload', onUnload)
}

export const deleteConveyor = (conveyorId) => (dispatch) => {
  const noOfConveyors = Object.keys(store.getState().ConveyorReducer.conveyors).length
  if (noOfConveyors === 1) {
    dispatch(
      openConfirmModal({
        bodyText: 'Unable to delete. You must have at least one conveyor.',
        cancelButton: false,
        confirmButtonText: 'OK',
      })
    )
  } else {
    dispatch(
      openConfirmModal({
        bodyText: 'Deleting a conveyor is irreversible. Are you sure ?',
        onConfirm: async () => {
          const conveyors = { ...store.getState().ConveyorReducer.conveyors }
          const { versionId } = store.getState().VersionReducer
          const conveyorMetadata = store.getState().ConveyorMetaReducer[versionId][conveyorId]
          const oldConveyor = conveyors[conveyorId]
          delete conveyors[conveyorId]
          dispatch(saveVersionConveyors(conveyors))
          dispatch(deleteConveyorMetadata({ conveyorId, versionId }))
          await ConveyorService.deleteConveyor(conveyorId)
            .then((res) => dispatch(setLoading(false)))
            .catch((e) => {
              dispatch(saveConveyorToConveyors(oldConveyor))
              dispatch(updateConveyorMetadata({ conveyorId, conveyorMetadata, versionId }))
              dispatch(setLoading(false))
              debugger
            })
        },
      })
    )
  }
}

export const downloadConveyorFile = (conveyorId, downloadType) => async (dispatch) => {
  dispatch(setLoading({ loading: true, loadingMessage: 'Downloading...' }))
  await ConveyorService.downloadConveyorFile(conveyorId, downloadType)
    .then((res) => dispatch(setLoading(false)))
    .catch((e) => {
      dispatch(setLoading(false))
      debugger
    })
}

export const debouncedUpdateConveyor = ADP(
  (dispatch, versionId, conveyorId, payload, loading, dirtyChainPatterns = []) => {
    return new Promise(async (resolve, reject) => {
      dispatch(
        setLoading({
          loading,
          loadingMessage: 'Updating Conveyor...',
        })
      )
      await ConveyorService.updateConveyor(versionId, conveyorId, payload)
        .then((data) => {
          const { products, ...conveyor } = data[conveyorId]
          dispatch(getProductsForConveyor(conveyorId, products))
          dispatch(
            saveConveyorToConveyors({
              ...conveyor,
              chain: {
                ...conveyor.chain,
                chainpatterns: [
                  ...conveyor.chain.chainpatterns.filter(
                    (cp) => !dirtyChainPatterns.map((dcp) => dcp.id).includes(cp.id)
                  ),
                  ...dirtyChainPatterns,
                ],
              },
            })
          )
          const versionData = {
            ...store.getState().ProjectReducer.versions[versionId],
            validated: false,
          }
          dispatch(updateProjectReducerVersion(versionData))
          dispatch(setLoading(false))
          resolve(data)
        })
        .catch((e) => {
          dispatch(setLoading(false))
          reject(e)
          debugger
        })
    })
  },
  1000,
  {
    key: (...args) => Object.keys(args[3])[0],
  }
)

export const updateConveyor = (conveyorId, payload, debounce = false, loading = true) => (
  dispatch
) => {
  const { versionId } = store.getState().VersionReducer
  const { chainpatterns } = store.getState().ConveyorReducer.conveyors[conveyorId].chain
  const dirtyChainPatternsKeys = Object.entries({
    ...(store.getState().ChainReducer[versionId][conveyorId]?.nonStandardRowMeta || {}),
  })
    .filter(([_, value]) => value.dirty)
    .map(([key]) => key)
  const dirtyChainPatterns = chainpatterns.filter((chainpattern) =>
    dirtyChainPatternsKeys.includes(chainpattern.id.toString())
  )

  if (debounce) {
    return debouncedUpdateConveyor(
      dispatch,
      versionId,
      conveyorId,
      payload,
      loading,
      dirtyChainPatterns
    )
  } else {
    dispatch(
      setLoading({
        loading,
        loadingMessage: 'Updating Conveyor...',
      })
    )
    return new Promise(async (resolve, reject) => {
      await ConveyorService.updateConveyor(versionId, conveyorId, payload)
        .then((data) => {
          batch(async () => {
            const { products, ...conveyor } = data[conveyorId]
            const firstKey = Object.keys(payload)[0]
            if (firstKey === 'materialid' || firstKey === 'unit') {
              // If core spec material has changed, get materials and accessories again
              dispatch(fetchDefaultMaterialsForConveyor(conveyorId))
              dispatch(fetchDefaultAccessoriesForConveyor(conveyorId))
              if (firstKey === 'unit') {
                await dispatch(getGearmotorForConveyor(conveyorId))
              }
            }
            dispatch(getProductsForConveyor(conveyorId, products))
            dispatch(
              saveConveyorToConveyors({
                ...conveyor,
                chain: {
                  ...conveyor.chain,
                  chainpatterns: [
                    ...conveyor.chain.chainpatterns.filter(
                      (cp) => !dirtyChainPatterns.map((dcp) => dcp.id).includes(cp.id)
                    ),
                    ...dirtyChainPatterns,
                  ],
                },
              })
            )
            const versionData = {
              ...store.getState().ProjectReducer.versions[versionId],
              validated: false,
            }
            dispatch(updateProjectReducerVersion(versionData))
            dispatch(setLoading(false))
          })
          resolve(true)
        })
        .catch((e) => {
          dispatch(setLoading(false))
          reject(e)
          debugger
        })
    })
  }
}

export const debouncedUpdateConveyorOptimistic = ADP(
  (dispatch, versionId, conveyorId, oldConveyor, payload) => {
    return new Promise(async (resolve, reject) => {
      dispatch(
        saveConveyorToConveyors({
          ...oldConveyor,
          ...payload,
        })
      )
      await ConveyorService.updateConveyor(versionId, conveyorId, payload)
        .then((res) => resolve(res))
        .catch((e) => {
          dispatch(saveConveyorToConveyors(oldConveyor))
          reject(e)
          debugger
        })
    })
  },
  1000,
  {
    key: (...args) => {
      return Object.keys(args[4])[0]
    },
  }
)

export const updateConveyorOptimistic = (conveyorId, payload, debounce = false) => async (
  dispatch
) => {
  const oldConveyor = store.getState().ConveyorReducer.conveyors[conveyorId]
  const { versionId } = store.getState().VersionReducer

  if (debounce) {
    return debouncedUpdateConveyorOptimistic(dispatch, versionId, conveyorId, oldConveyor, payload)
  } else {
    dispatch(
      saveConveyorToConveyors({
        ...oldConveyor,
        ...payload,
      })
    )
    await ConveyorService.updateConveyor(versionId, conveyorId, payload).catch((e) => {
      dispatch(saveConveyorToConveyors(oldConveyor))
      debugger
    })
  }
}

export const updateConveyorsOrder = (versionId, payload: Array<IConveyorOrderRequest>) => async (
  dispatch
) => {
  const oldConveyorsOrders = getConveyorsOrders(store.getState().ConveyorReducer.conveyors)

  payload.forEach(({ conveyorid, order }) => {
    dispatch(
      saveConveyorOrder({
        id: conveyorid.toString(),
        order,
      })
    )
  })

  try {
    await ConveyorService.updateConveyorsOrder(versionId, payload)
  } catch (error) {
    oldConveyorsOrders.forEach(({ conveyorid, order }) => {
      dispatch(
        saveConveyorOrder({
          id: conveyorid.toString(),
          order,
        })
      )
    })
  }
}

// Version Options that apply to all conveyors
export const saveOption = (optionId, payload) => (dispatch) => {
  const { versionId } = store.getState().VersionReducer
  const projectVersion = store.getState().ProjectReducer.versions[versionId]
  return new Promise(async (resolve, reject) => {
    const { versionId } = store.getState().VersionReducer
    let data: IVersion
    dispatch(
      setLoading({
        loading: true,
        loadingMessage: 'Updating Options...',
      })
    )

    try {
      if (optionId) {
        data = await ConveyorService.updateOption(versionId, optionId, payload)
      } else {
        data = await ConveyorService.addOption(versionId, payload)
      }
      if (data) {
        const { pricevalidations, ...priceinfo } = data
        const versionData = {
          ...projectVersion,
          ...priceinfo,
        }
        batch(async () => {
          dispatch(saveOptionToOptions(pricevalidations[0]))
          dispatch(updateProjectReducerVersion(versionData))
          dispatch(setLoading(false))
        })
        resolve(data)
      } else {
        dispatch(setLoading(false))
        debugger
      }
    } catch (error) {
      dispatch(setLoading(false))
      debugger
    }
  })
}

export const saveConveyorOption = (conveyorId, optionId, payload) => (dispatch) => {
  const { versionId } = store.getState().VersionReducer
  const projectVersion = { ...store.getState().ProjectReducer.versions[versionId] }
  const conveyors = { ...store.getState().ConveyorReducer.conveyors }

  return new Promise(async (resolve, reject) => {
    let data: IPriceValidationVersionAndConveyorsResponse
    dispatch(
      setLoading({
        loading: true,
        loadingMessage: 'Updating Conveyor Options...',
      })
    )

    try {
      if (optionId) {
        data = await ConveyorService.updateConveyorOption(conveyorId, optionId, payload)
      } else {
        data = await ConveyorService.addConveyorOption(conveyorId, payload)
      }

      if (data) {
        const { conveyors: updatedConveyors, ...priceinfo } = data

        // Update Conveyor Option with new price/muf/etc
        const updatedPricevalidations = updatedConveyors[0]?.pricevalidations?.[0]
        let optionType = 'nonmandatory'
        if (updatedPricevalidations.sharedmuf) {
          optionType = 'sharedmuf'
        } else if (updatedPricevalidations.mandatory) {
          optionType = 'mandatory'
        }
        // const optionType = updatedPricevalidations.sharedmuf ? 'sharedmuf' : updatedPricevalidations?.mandatory ? 'mandatory' : 'nonmandatory'
        dispatch(
          saveOptionToConveyorOptions({
            conveyorId,
            optionType,
            data: {
              [updatedPricevalidations.id]: updatedPricevalidations,
            },
          })
        )

        // Update Version Conveyors with new returned pricevalidation
        const conveyor = conveyors[conveyorId]
        const formattedConveyor = formatConveyorsAndConveyorSections(updatedConveyors)[conveyorId]
        const { pricevalidations: oldPV } = conveyor
        const { pricevalidations: newPV } = formattedConveyor
        dispatch(
          saveVersionConveyors({
            ...conveyors,
            [conveyorId]: {
              ...conveyor,
              ...formattedConveyor,
              pricevalidations: {
                mandatory: { ...oldPV.mandatory, ...newPV.mandatory },
                nonmandatory: { ...oldPV.nonmandatory, ...newPV.nonmandatory },
                sharedmuf: { ...oldPV.sharedmuf, ...newPV.sharedmuf },
              },
            },
          })
        )

        // Update Project price info
        dispatch(
          updateProjectReducerVersion({
            ...projectVersion,
            ...priceinfo,
          })
        )

        dispatch(setLoading(false))
        resolve(data)
      } else {
        dispatch(setLoading(false))
        debugger
      }
    } catch (error) {
      dispatch(setLoading(false))
      debugger
    } 
  })
}

export const updateAllSharedConveyorOptions = (conveyorId, payload) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    dispatch(
      setLoading({
        loading: true,
        loadingMessage: 'Updating Conveyor Options...',
      })
    )
    await ConveyorService.updateAllConveyorOptions(conveyorId, payload)
      .then((data) => {
        const { versionId } = store.getState().VersionReducer
        dispatch(
          saveOptionToConveyorOptions({
            conveyorId,
            optionType: 'sharedmuf',
            data: formatIdAsKey(data),
          })
        )
        dispatch(selectVersion(versionId, true))
        dispatch(setLoading(false))
        resolve(data)
      })
      .catch((e) => {
        dispatch(setLoading(false))
        debugger
      })
  })
}

export const deleteOption = (optionId) => async (dispatch) => {
  const { versionId } = store.getState().VersionReducer
  const options = { ...store.getState().ConveyorReducer.options }
  const optionToDelete = store.getState().ConveyorReducer.options[optionId]
  delete options[optionId]
  batch(() => {
    dispatch(updateOptions(options))
    dispatch(deleteOneFromVersionOptionsOrder(optionToDelete.id))
  })

  await ConveyorService.deleteOption(versionId, optionId)
    .then(() => dispatch(selectVersion(versionId, true)))
    .catch((e) => {
      debugger
      dispatch(saveOptionToOptions(optionToDelete))
    })
}

export const deleteConveyorOption = (conveyorId, optionId) => async (dispatch) => {
  const conveyor = _.cloneDeep(store.getState().ConveyorReducer.conveyors[conveyorId])
  const { versionId } = store.getState().VersionReducer
  const conveyorOptionToDelete = _.cloneDeep(
    store.getState().ConveyorReducer.conveyors[conveyorId].pricevalidations.nonmandatory[optionId]
  )
  delete conveyor.pricevalidations.nonmandatory[optionId]
  dispatch(saveConveyorToConveyors(conveyor))

  await ConveyorService.deleteConveyorOption(conveyorId, optionId)
    .then(() => {
      dispatch(getConveyor(conveyorId))
      dispatch(selectVersion(versionId, true))
    })
    .catch((e) => {
      dispatch(
        saveConveyorToConveyors({
          ...conveyor,
          pricevalidations: {
            ...conveyor.pricevalidations,
            nonmandatory: {
              ...conveyor.pricevalidations.nonmandatory,
              [optionId]: conveyorOptionToDelete,
            },
          },
        })
      )
    })
}