import { createReducer } from '@reduxjs/toolkit'
import { PURGE } from 'redux-persist'

/**
 * @typedef {Object} CopiedProductPayload
 * @property {number} productlength,
 * @property {number} productwidth,
 * @property {number} productheight,
 * @property {number} productweight,
 * @property {number} productrate,
 * @property {number} productmaterialtypeid,
 * @property {number} speed,
 * @property {number} temperature,
 * @property {number} frictiontop,
 * @property {number} frictionbottom,
 * @property {number} frictionload
 * 
 * @typedef {Object} CopiedProduct
 * @property {CopiedProductPayload?} payload
 * @property {number?} conveyorId 
          
 * 
 * @typedef {Object} VersionState
 * @property {CopiedProduct?} copiedProduct
 * @property {{versioncostsvalid: boolean}} validatePrice
 * @property {number?} versionId
 * @property {Array<import('utils/api/list/types').IOptionalPriceValidationByVersion>} optionalpricevalidationbyversions
 */

/** @type {VersionState} */
const INITIAL_STATE = {
  copiedProduct: null,
  validatePrice: {
    versioncostsvalid: false,
  },
  versionId: null,
  priceValidationOptions: [],
}

export default createReducer(INITIAL_STATE, {
  RESET_VERSION_STORE: (state, action) => {
    state = INITIAL_STATE
  },
  SET_COPIED_PRODUCT: (state, action) => {
    if (action.payload) {
      const { conveyorId, payload } = /** @type {CopiedProduct}*/ (action.payload)
      state.copiedProduct = {
        conveyorId,
        payload,
      }
    } else {
      state.copiedProduct = null
    }
  },
  SET_VERSION_ID: (state, action) => {
    state.versionId = action.payload
  },
  UPDATE_VERSION_COSTS_VALID: (state, action) => {
    state.validatePrice.versioncostsvalid = action.payload
  },
  SET_OPTIONAL_PV: (state, action) => {
    state.optionalpricevalidationbyversions = action.payload
  },
  [PURGE]: () => INITIAL_STATE,
})
