import React, { Component } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import _ from 'lodash'
import { Collapse } from 'react-collapse'
import { Checkbox, Form, Input, Radio, Tooltip } from 'antd'
import { Icon, TMIcon, Select } from 'shared/components'
import Colors from 'shared/constants/colors'
import {
  collapseSection,
  selectSection,
  validateSection,
} from '../../../redux/ConveyorBuilderActions'
import {
  createTransfer,
  deleteTransfer,
  getInfeedDischargeTransfer,
  updateInfeedDischarge,
  updateTransfer,
} from '../../../redux/ConveyorSectionActions'
import builderInfeedDischargeConfig from './builderInfeedDischargeConfig'
import radioStyle from './builderInfeedDischargeConfig/radioStyle'
import './BuilderTile.scss'
import cn from 'classnames'
import BuilderCollapseToggle from './BuilderTile/components/BuilderCollapseToggle'
import { captureSentryError } from 'utils/helpers'
import { Store } from 'reduxStore'
import { WrappedFormUtils } from 'antd/lib/form/Form'
import { IInfeedDischargeConfigData } from '../types'
import { ITransfersListMicrospan } from 'utils/api/list/types/transfers'
import { IChainStyle } from 'features/Chain/shared/types'
import getTransferTypeKey from '../../../helpers/getTransferTypeKey'
import { getCautionsWarnings } from '../helpers'

const FormItem = Form.Item

export const TRANSFER_TYPE_MAP = {
  gravity: { key: 'rollermaterialid', label: 'Roller Material' },
  microspan: { key: 'transfermaterialid', label: 'Transfer Material' },
}

interface IDefaultProps {
  conveyorId: number;
  type: 'infeed' | 'discharge';
}

export type IBuilderInfeedDischargeProps = IDefaultProps &
  PropsFromRedux & { form: WrappedFormUtils }

class BuilderInfeedDischarge extends Component<IBuilderInfeedDischargeProps> {
  state = {
    noneSelected: false,
  }

  componentDidMount() {
    const { conveyorId, getInfeedDischargeTransfer, item, type, infeedDischargeList } = this.props
    if (item.hastransfer) {
      getInfeedDischargeTransfer(conveyorId, type).then(() => {
        this.customValidateFields()
      })
    } else {
      this.customValidateFields()
    }
    const noneId = _.find(infeedDischargeList?.types, { title: 'None' })?.id
    if (item.feedtypeid === noneId) {
      this.setState({ noneSelected: true })
    }
  }

  componentDidUpdate(prevProps: IBuilderInfeedDischargeProps) {
    const { collapsed, item } = this.props
    const { transfer } = item
    if (!collapsed && prevProps.collapsed !== collapsed) {
      this.customValidateFields()
    }
    if (transfer && prevProps.item.transfer !== transfer) {
      this.customValidateFields()
    }
  }

  collapseSection = async (bool?: string) => {
    const { collapsed, collapseSection, conveyorId, item, type } = this.props
    const value = !bool ? !collapsed : bool === 'true'
    collapseSection({
      collapsed: value,
      conveyorId: conveyorId,
      sectionId: `${type}-${item.id}`,
    })
  }

  customValidateFields = () => {
    const { conveyorId, form, item, sectionFirstValidation, type, validateSection } = this.props
    return new Promise(async (resolve, reject) => {
      let noErrors = true
      form.validateFields((errors, values) => {
        if (errors) {
          validateSection({
            validated: false,
            conveyorId: conveyorId,
            sectionId: `${type}-${item.id}`,
          })
          noErrors = false
        } else {
          validateSection({
            validated: true,
            conveyorId: conveyorId,
            sectionId: `${type}-${item.id}`,
          })
        }
      })
      if (!sectionFirstValidation || !noErrors) {
        this.collapseSection('false')
      }
      resolve(noErrors)
    })
  }

  heading = () => {
    const { collapsed, item } = this.props
    return (
      <React.Fragment>
        <BuilderCollapseToggle collapsed={collapsed} onClick={() => this.collapseSection()} />
        <div className="collapsed-main__title">{item.type}</div>
      </React.Fragment>
    )
  }

  renderContent = () => {
    const { isactive, islocked, item, form, infeedDischargeList } = this.props
    const { noneSelected } = this.state
    const { transfer } = item
    const { getFieldDecorator, setFieldsValue } = form
    const disabled = !isactive || islocked

    const transfersList = infeedDischargeList.transfers
    const transfermaterialid = form.getFieldValue('transfermaterialid')
    const transfertypeid = form.getFieldValue('transfertypeid')
    // slugify the titles: e.g. Micro Span -> microspan. See TRANSFER_TYPE_MAP
    const transferTypeKey =
      transfertypeid && transfer?.transfertypeid
        ? getTransferTypeKey(transfersList, transfer)
        : null
    const transferMaterial: { key?: string; label: string } = TRANSFER_TYPE_MAP[
      transferTypeKey
    ] || {
      key: null,
      label: 'Transfer Material',
    }
    const transferTypeOptions = transfersList[transferTypeKey]
    const wireMeshSelected =
      transferTypeKey === 'microspan'
        ? (transferTypeOptions as ITransfersListMicrospan)?.materials?.[transfermaterialid]
            ?.title === 'Wire Mesh'
        : null
    const microspan6mmSelected =
        transferTypeKey === 'microspan'
          ? (transferTypeOptions as ITransfersListMicrospan)?.materials?.[transfermaterialid]
              ?.title.startsWith('Microspan 6mm')
          : null
  
    const formConfigVars: IInfeedDischargeConfigData = {
      ...this.props,
      transfersList,
      transferType: transferTypeKey,
      wireMeshSelected,
      microspan6mmSelected,
      transferMaterial,
      isDrive: infeedDischargeList.types[item.feedtypeid].title === 'Drive',
    }

    return builderInfeedDischargeConfig(formConfigVars, noneSelected).map((field) => {
      if (field.key === null) return null

      switch (field.type) {
        case 'select': {
          return (
            <div
              className="builder-tile__expanded__content__wrapper"
              key={`form-item-${field.key}-${item.id}`}
            >
              <div className="option-label">{field.prettyName}</div>
              <div
                className={`option-content option-content__${field.prettyName}`}
                style={{ width: '100%' }}
              >
                <FormItem label={field.label}>
                  {getFieldDecorator(field.key, {
                    initialValue: field.isTransfer
                      ? field.value || transfer[field.key]
                      : item[field.key],
                  })(
                    <Select
                      disabled={disabled}
                      placeholder={field.placeholder}
                      width={field.width}
                      onChange={(value, option) => {
                        setFieldsValue({ [field.key]: value })
                        if (field.key === 'feedtypeid') {
                          this.setState({
                            noneSelected:
                              'props' in option &&
                              option?.props?.children?.props?.children === 'None',
                          })
                        }
                        if (field.isTransfer) {
                          this.updateTransfer(field.key, value)
                        } else {
                          this.updateInfeedDischarge(field.key, value)
                        }
                      }}
                      options={field.options}
                    />
                  )}
                </FormItem>
              </div>
            </div>
          )
        }
        case 'input': {
          return (
            <div
              className="builder-tile__expanded__content__wrapper"
              key={`form-item-${field.key}-${item.id}`}
              style={{ position: 'relative' }}
            >
              <FormItem
                label={field.prettyName}
                className={`option-label option-label__${field.key}`}
              >
                {getFieldDecorator(field.key, {
                  initialValue: field.isTransfer ? transfer[field.key] : field.value,
                  rules: field.rules,
                })(
                  <Input
                    className="option-input"
                    style={{width:'200px'}}
                    disabled={disabled}
                    addonAfter={field.chipText}
                    onChange={(e) => {
                      // setFieldsValue({ [field.key]: e.target.value })
                      if (field.isTransfer) {
                        this.updateTransfer(field.key, e.currentTarget.value)
                      } else {
                        form.validateFields([field.key], (errors) => {
                          if (errors && field.key in errors) return
                          this.updateInfeedDischarge(field.key, e.currentTarget.value)
                        })
                      }
                    }}
                  />
                )}
              </FormItem>
            </div>
          )
        }
        case 'checkbox': {
          return (
            <div
              className="builder-tile__expanded__content__wrapper"
              key={`form-item-${field.key}-${item.id}`}
            >
              <div className="radio-title">{field.prettyName}</div>
              <FormItem>
                {getFieldDecorator(field.key, {
                  rules: field.rules,
                })(
                  <Checkbox
                    disabled={disabled}
                    defaultChecked={Boolean(field.value)}
                    style={{ width: '230px' }}
                    onChange={(e) => {
                      setFieldsValue({ [field.key]: e.target.checked })
                      if (field.isTransfer) {
                        this.updateTransfer(field.key, e.target.checked)
                      } else {
                        this.updateInfeedDischarge(field.key, e.target.checked)
                      }
                    }}
                  >
                    {field.label}
                  </Checkbox>
                )}
              </FormItem>
            </div>
          )
        }
        case 'radio': {
          const { key, isTransfer, rules } = field
          const selectedOptionValue = isTransfer ? transfer[key] : item[key]

          return (
            <div
              className="builder-tile__expanded__content__wrapper"
              key={`form-item-${key}-${item.id}`}
            >
              <div className="radio-title">{field.prettyName}</div>
              <FormItem>
                {getFieldDecorator(key, { initialValue: selectedOptionValue, rules })(
                  <Radio.Group
                    disabled={disabled}
                    onChange={(e) => {
                      const { idler, transfers } = this.props.infeedDischargeList
                      setFieldsValue({ [key]: e.target.value })

                      if (isTransfer) {
                        this.updateTransfer(key, e.target.value)
                      } else {
                        this.updateInfeedDischarge(key, e.target.value)

                        if (key === 'feedidlertypeid') {
                          // Check if Low Profile Idler was selected
                          const isLowProfileIdler = idler[e.target.value].textvalue === 'LP'
                          // If LP Idler, update transfer type to LP Transfer
                          if (isLowProfileIdler) {
                            const lowProfileTransferId = _.find(
                              transfers.types,
                              (o) => o.title === 'Low Profile'
                            ).id
                            this.updateTransfer('transfertypeid', lowProfileTransferId)
                            setFieldsValue({ transfertypeid: lowProfileTransferId })
                          } else {
                            setFieldsValue({ transfertypeid: null })
                          }
                        }
                      }
                    }}
                    className="builder-radiogroup"
                    style={{ width: '450px' }}
                  >
                    {field.options.map(({ image, value, label }, i) => (
                      <div
                        className="builder-radio"
                        key={`option-${i}-${value}`}
                        style={isTransfer ? {} : { marginRight: '3px' }}
                      >
                        <Radio style={radioStyle} value={value}>
                          <div className="builder-radio__label">{label}</div>
                          {image ? (
                            <div
                              className={cn('builder-radio__image', {
                                selected: value === selectedOptionValue,
                              })}
                              style={isTransfer ? { width: '110px' } : {}}
                            >
                              <img src={image.src} alt={image.alt} />
                            </div>
                          ) : null}
                        </Radio>
                      </div>
                    ))}
                  </Radio.Group>
                )}
              </FormItem>
            </div>
          )
        }
        default:
          return null
      }
    })
  }

  subHeading = () => {
    const { item, validated, unit} = this.props
    const { warnings, cautions } = getCautionsWarnings(item)
    const sectionMessage = !validated ? `${item.type} Incomplete` : `${item.type} Complete`
    const icon = (() => {
      if (!validated) return <Icon icon="circle" size={20} color={Colors.ash} />
      if (warnings.length > 0) return TMIcon.Warning
      if (cautions.length > 0) return TMIcon.Caution
      return TMIcon.OK
    })()
  
    const showToolTip = warnings.length > 0 || cautions.length > 0
  
    return (
      <div className="collapsed-sub">
        <div className="collapsed-sub__status">
        {showToolTip 
          ? (<Tooltip overlayStyle={{ maxWidth: '850px' }} title={<div>
                {cautions.map((caution) => (<div className="caution-sections">{caution}</div>))}
                {warnings.map((warning) => (<div className="warning-sections">{warning}</div>))}
               </div>}>
               <div className="collapsed-sub__status_icon">{icon}</div>
             </Tooltip>)
          : (<div className="collapsed-sub__status_icon">{icon}</div>) }
          <div className="collapsed-sub__status_text">
            <span className={`section ${!validated ? 'errors' : ''}`}>{sectionMessage}</span> | 
            <span className=""> {unit === 'Metric' ? item.lengthmm+'mm' : item.lengthinches+'in'} </span> | 
            <span className="">
              {' '}
              {this.subStatusText()} {item.hastransfer ? '+ Transfer' : ''}
            </span>
          </div>
        </div>
      </div>
    )
  }

  subStatusText = () => {
    const { infeedDischargeList, item } = this.props
    const infeedDischargeType = infeedDischargeList.types[item.feedtypeid].title
    let typeText = ''
    if (infeedDischargeType === 'Idler') {
      typeText = _.find(infeedDischargeList.idler, (t) => t.id === item.feedidlertypeid).title
    } else {
      const option = _.filter(
        infeedDischargeList.drive,
        (t) =>
          t.textvalue ===
          infeedDischargeList.driveidlertypes[item.feeddriveandidlertypeid].textvalue
      ).find((v) => v.id === item.feeddriveorientationid)
      if (option) {
        typeText = option.title
      }
    }

    return typeText
  }

  updateInfeedDischarge = _.debounce(async (key: string, value: unknown) => {
    const { conveyorId, createTransfer, deleteTransfer, updateInfeedDischarge, type } = this.props

    this.customValidateFields()
    if (key === 'hastransfer') {
      if (value) {
        createTransfer(conveyorId, type)
      } else {
        deleteTransfer(conveyorId, type)
      }
    } else {
      const payload = { [key]: value }
      await updateInfeedDischarge(conveyorId, type, payload).then(() => this.customValidateFields())
    }
  }, 300)

  updateTransfer = (key: string, value: unknown) => {
    const { conveyorId, updateTransfer, type } = this.props

    this.customValidateFields()
    const payload = { [key]: value }
    updateTransfer(conveyorId, type, payload)
  }

  render() {
    const { collapsed, conveyorId, isactive, islocked, type, item } = this.props
    const { warnings, cautions } = getCautionsWarnings(item)

    return (
      <div
        id={`${conveyorId}-${type}`}
        className={`builder-tile ${isactive && !islocked ? '' : 'disabled'} `}
      >
        <div className="builder-tile__collapsed">
          <div className="collapsed-main">{this.heading()}</div>
          {collapsed ? this.subHeading() : <div>
            {cautions.map((caution) => (<div className="caution-sections">{caution}</div>))}
            {warnings.map((warning) => (<div className="warning-sections">{warning}</div>))}
          </div>}
        </div>
        {collapsed ? null : (
          <Collapse
            theme={{
              collapse: 'builder-tile__expanded',
              content: 'builder-tile__expanded__content',
            }}
            isOpened={!collapsed}
          >
            {this.renderContent()}
          </Collapse>
        )}
      </div>
    )
  }
}

export const mapStateToProps = (state: Store, props: IDefaultProps) => {
  try {
    const { conveyorId, type } = props
    const { currentlistsettings } = state.ProjectReducer
    const {
      ConveyorReducer,
      EstimatorMetaReducer,
      ProjectReducer,
      VersionReducer: { versionId },
    } = state

    const conveyor = ConveyorReducer.conveyors?.[conveyorId]
    const item = conveyor[type]
    const displayBasedOnChainStyle: Partial<IChainStyle> = conveyor.chain.standardchainstyle
      ? state.ChainReducer?.[versionId]?.[conveyorId]?.chainStyles?.[
          conveyor?.chain?.chainstyleid
        ] || {}
      : {}

    const { isactive, islocked } = ProjectReducer.versions[versionId]
    const sectionMetadata =
      EstimatorMetaReducer?.[conveyorId].conveyorbuilder.sectionsMetadata[`${type}-${item.id}`]

    const infeedDischargeList =
      type === 'infeed'
        ? {
            ...currentlistsettings.sections.infeed,
            ...currentlistsettings.sections.feeds,
            transfers:currentlistsettings.transfers,
          }
        : {
            ...currentlistsettings.sections.discharge,
            ...currentlistsettings.sections.feeds,
            transfers:currentlistsettings.transfers,
          }
    const isStandard = conveyor.chain.standardchainstyle
    const hasIntermediateDrive = Object.values(conveyor?.conveyorsections || {}).some(
      (section) => section.type === 'IntermediateDrive'
    )
    const unit = conveyor.unit

    return {
      isactive,
      islocked,
      item,
      collapsed: sectionMetadata ? sectionMetadata.collapsed : false,
      displayLowProfileTransferOption:
        infeedDischargeList?.idler?.[item.feedidlertypeid]?.textvalue === 'LP',
      isStandard,
      displayLowProfileIdler: isStandard ? displayBasedOnChainStyle?.lowprofileidler : true,
      displayTransfers: isStandard ? displayBasedOnChainStyle?.transfers : true,
      sectionFirstValidation: sectionMetadata ? sectionMetadata.sectionFirstValidation : false,
      infeedDischargeList,
      selectedSectionId: state.EstimatorMetaReducer[conveyorId].conveyorbuilder.selectedSectionId,
      validated: sectionMetadata ? sectionMetadata.validated : false,
      versionId,
      hasIntermediateDrive,
      unit,
    }
  } catch (error) {
    captureSentryError(error, state)
  }
}

const mapDispatchToProps = {
  collapseSection,
  createTransfer,
  deleteTransfer,
  getInfeedDischargeTransfer,
  selectSection,
  updateInfeedDischarge,
  updateTransfer,
  validateSection,
}

const connector = connect(mapStateToProps, mapDispatchToProps)
type PropsFromRedux = ConnectedProps<typeof connector>

export default connector(Form.create({ name: 'infeed-discharge' })(BuilderInfeedDischarge))
