import { getGuideRailsOptionsByTextvalue, getTextValue, mapDataToOption, FIELD_TYPES } from './helpers'

/**
 * @typedef {import('antd/lib/form').ValidationRule} ValidationRule
 * @typedef {import('shared/types/ListElement').ListElement} ListElement
 * @typedef {Pick<ListElement, 'image' | 'title'> & {id: number | boolean, isdefault?: boolean}} ListElementWithBooleanId
 * @typedef {{value: string | number, label: string}} SelectOption
 * 
 * @typedef {Object} GRFormConfig
 * @property {string} [dataKey]
 * @property {boolean} [required=false]
 * @property {string} [prettyName]
 * @property {string} type
 * @property {string} [label]
 * @property {boolean} [vertical]
 * @property {string} [className]
 * @property {string} [shortname]
 * @property {ValidationRule[]} [rules]
 * @property {Array<SelectOption> | Array<ListElementWithBooleanId> | Record<string, ListElementWithBooleanId>} [options]
 * @property {GRFormConfig} [railspreferredchoices]
 */

/**
 * @param {import('utils/api/list/types').AccessoriesList['guiderails']} guiderailsOptions
 * @param {import('shared/types/GuideRailsSideResponse').GuideRailsSideResponse} side
 * @param {boolean} isLAProject
 * 
 * @returns {Record<string, GRFormConfig>}
 */
export default (guiderailsOptions, side, isLAProject, sectionOverride) => {
  const bracketSelection = guiderailsOptions.brackets?.[side?.bracketsid]

  const railSelection = guiderailsOptions.railspreferredchoices?.[side?.railschoiceid]
  const textvalueSearch = getTextValue(railSelection, bracketSelection)

  const isMoldedChannelBracket = bracketSelection?.title === 'Molded Channel Bracket' || false
  const distancebetweenrailsidTitle =
    guiderailsOptions.distancebetweenrails?.[side?.distancebetweenrailsid]?.title
  const is218445 = distancebetweenrailsidTitle?.includes?.('+ 2.18') || false
  const is25625 = distancebetweenrailsidTitle?.includes?.('+ 2.5') || false
  const is3into5in =
    guiderailsOptions.distancetocs?.[side?.distancetocid]?.title?.includes?.('3in to 5in') || false

  const distancebetweenrailslistOptions = getGuideRailsOptionsByTextvalue(
    textvalueSearch,
    guiderailsOptions?.distancebetweenrails
  )
  const distancetoclistOptions = getGuideRailsOptionsByTextvalue(
    textvalueSearch,
    guiderailsOptions?.distancetocs
  )

  const NonLaProjectContent = isLAProject ? null : {
    doublelevel: {
      dataKey: 'doublelevel',
      required: true,
      prettyName: 'Single or Double?',
      type: FIELD_TYPES.RADIO,
      className: 'guiderails-inline',
      rules: [
        {
          required: true,
          message: 'A selection is required.',
        },
      ],
      options: [
        { id: true, title: 'Double' },
        { id: false, title: 'Single' },
      ],
    },
    paintedbrackets: {
      dataKey: 'painted',
      required: true,
      // vertical: true,
      className: 'guiderails-inline',
      prettyName: 'Painted Brackets?',
      type: FIELD_TYPES.RADIO,
      rules: [
        {
          required: true,
          message: 'A selection is required.',
        },
      ],
      options: [
        { id: true, title: 'Yes' },
        { id: false, title: 'No' },
      ],
    },
    ezguide: {
      type: FIELD_TYPES.SELECT,
      prettyName: 'EZ Guide',
      dataKey: 'ezguideid',
      options: Object.values(guiderailsOptions?.ezguide || {}).map((listElement) => ({
        value: listElement.id,
        label: listElement.title
      }))
    },
    backing: {
      dataKey: 'backingid',
      className: 'guiderails-inline',
      // required: true,
      prettyName: 'Backing',
      type: FIELD_TYPES.DISPLAY,
      vertical: true,
    },
    railschoiceid: {
      type: FIELD_TYPES.RAILS,
      label: 'Rails',
      className: 'sectionoverride',
      railspreferredchoices: {
        shortname: 'preferred',
        dataKey: 'railschoiceid',
        required: true,
        prettyName: 'Preferred Choices - In-Stock Items',
        type: FIELD_TYPES.RADIO,
        rules: [
          {
            required: true,
            message: 'A rails selection is required.',
          },
        ],
        options: guiderailsOptions.railspreferredchoices,
      },
    }
  }

  return {
    ...NonLaProjectContent,
    bracket: {
      dataKey: 'bracketsid',
      required: true,
      prettyName: 'Bracket',
      className: `guiderails-bracket ${sectionOverride ? 'sectionoverride' : ''}`,
      type: FIELD_TYPES.RADIO,
      rules: [
        {
          required: true,
          message: 'A bracket selection is required.',
        },
      ],
      options: guiderailsOptions.brackets,
    },
    distancebetweenrailslist: {
      type: 'distancebetweenrailsid',
      required: true,
      prettyName: 'Distance Between Rails',
      dataKey: 'distancebetweenrailsid',
      rules: [],
      options:
        isMoldedChannelBracket && is3into5in
          ? distancebetweenrailslistOptions.map((opt) =>
              opt.label.includes('+ 2.') ? { ...opt, disabled: true } : opt
            )
          : distancebetweenrailslistOptions,
    },
    distancetoclist: {
      type: 'distancetocid',
      required: true,
      prettyName: 'Distance TOC to Centerline of T Rail',
      dataKey: 'distancetocid',
      rules: [],
      options:
        isMoldedChannelBracket && (is218445 || is25625)
          ? distancetoclistOptions.map((opt) =>
              opt.label.includes('3in to 5in') ? { ...opt, disabled: true } : opt
            )
          : distancetoclistOptions,
    },
    rods: {
      type: 'rodid',
      required: true,
      prettyName: 'Length of Rods',
      dataKey: 'rodid',
      rules: [],
      options: (() => {
        if (bracketSelection?.title !== 'VG Adjustable') return []

        return Object.values(guiderailsOptions?.rods || {}).map(mapDataToOption)
      })(),
    },
  }
}
