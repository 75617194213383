import React from 'react'
import { useLocation } from 'react-router-dom'
import queryString from 'query-string'
import { Button, Input } from 'shared/components'
import { useAppDispatch } from 'shared/hooks/app'
import { codeValidation } from 'features/User/redux/UserActions'
import { setLoading, openConfirmModal } from 'shared/redux/ScreenActions'
import '../LoginScreen/LoginScreen.scss'
import { typedUseSelector } from 'utils/helpers'

function CodeValidationScreen() {
  const [codeValidated, setCodeValidated] = React.useState<boolean>(false)
  const dispatch = useAppDispatch()
  const { search } = useLocation()
  const values = queryString.parse(search)
  const { user_code } = values
  const firstName = typedUseSelector((state) => state.UserReducer.firstName)
  const lastName = typedUseSelector((state) => state.UserReducer.lastName)
  const userName = typedUseSelector((state) => state.UserReducer.username)

  React.useEffect(() => {
    dispatch(setLoading(false))
  }, []) 

  async function handleCodeValidation(e) {
      e.preventDefault()
      try {
        await dispatch(codeValidation({ user_code }))
        setCodeValidated(true)
      } catch (e) {
        dispatch(
          openConfirmModal({
            headerText: 'Error',
            bodyText: e.error,
            cancelButton: false,
            confirmButtonText: 'OK',
          })
        )
      }
    }
  
  return (
    <div className="login-container">
      <div className="login-left">
        <img
          className="login-left__background"
          alt="Artistic blueprint designs of spantech's conveyors and parts"
          src={`${process.env.PUBLIC_URL}/img/login_background.jpg`}
        />
      </div>
      <main className="login-right">
        <div className="login-right__container">
          <img
            className="login-right__logo"
            src={`${process.env.PUBLIC_URL}/img/ez_suite_logo.png`}
            alt="EZ Suite logo showing the words EZ Suite inside a gear"
          />
          <h1 className="login-right__heading">CODE VALIDATION</h1>
          <h2 className="login-right__subheading">Outlast. Outperform. Outdeliver.</h2>
          {!codeValidated ? (<div><p>
            You are about to grant access to EZ-Suite as {firstName} {lastName} ({userName}) for an external application with code below. If you did not request such access, please close this screen without validating and contact Spantech Administrator.
          </p>
          <form className="login-right__form" onSubmit={handleCodeValidation}>
            <label htmlFor="code">CODE</label>
            <Input
              id="code"
              name="code"
              large
              type="text"
              value={user_code}
              disabled={true}
            />
            <section className="login-right__form-bottom">
              <Button
                text="GRANT ACCESS"
                aria-label="Click to grant access"
                type="submit"
                className="login-right__submit"
              />
            </section>
          </form></div>)
          : (<div>
            <p>
            The code has been validated. You can return to the calling application to complete the process. You can close this window.
           </p>
          </div>)}
        </div>
      </main>
    </div>
  )    
}

export default CodeValidationScreen