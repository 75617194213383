/**
 * @typedef {import('react').CSSProperties} CSSProperties
 *
 * @typedef {Object} StyleOptions
 * @property {boolean?} [isDragging]
 * @property {CSSProperties?} [draggableStyle]
 */

/**
 * @param {StyleOptions} [styleOptions]
 * @returns {CSSProperties}
 */
export default function getItemStyle(styleOptions = {}) {
  const { isDragging = false, draggableStyle = {} } = styleOptions

  return {
    padding: '1px',
    margin: '4px 3px',
    background: isDragging ? '#C0C5CF' : '#FFF',
    border: 'none',
    ...draggableStyle,
  }
}
