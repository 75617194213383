import { useEffect, useState } from 'react'
import { IGeneralSettingsForm, IGeneralSettingsRequest } from 'shared/types/swagger'
import ProjectService from 'utils/api/project/ProjectService'
import { useDispatch } from 'react-redux'
import { setLoading } from '../../../../../shared/redux/ScreenActions'

export default function useProjectSettings() {
  const dispatch = useDispatch()
  const [loadingForScreen, setLoadingForScreen] = useState<boolean>(true)
  const [projectSettings, setProjectSettings] = useState<IGeneralSettingsForm>()
  const [error, setError] = useState<string>()

  async function handleSave(values: IGeneralSettingsForm) {
    try {
      setLoadingForScreen(true)
      dispatch(setLoading(({
        loading: true,
        loadingMessage: 'Saving general settings...',
      })))

      var payload:IGeneralSettingsRequest=
      {
        ...values,
        documenttemplates : {
          settingsneedsupdate:values.documenttemplatessettingsneedsupdate,
          settingsurl:'',
          settingsdiscrepancies:''
        }
      }

      const response = await ProjectService.updateGeneralSettings(payload)
      var responseForForm:IGeneralSettingsForm= 
      { 
        ...response,
        documenttemplatessettingsneedsupdate:response.documenttemplates.settingsneedsupdate,
        documenttemplatessettingsurl:response.documenttemplates.settingsurl,
        documenttemplatessettingsdiscrepancies:response.documenttemplates.settingsdiscrepancies
      };
      setProjectSettings(responseForForm)
      setLoadingForScreen(false)
      dispatch(setLoading(false))
      return true
    } catch (error) {
      setError(error)
      setLoadingForScreen(false)
      dispatch(setLoading(false))
      return false
    }
  }

  useEffect(() => {
    (async () => {
      try {
        setLoadingForScreen(true)
        dispatch(setLoading(({
          loading: true,
          loadingMessage: 'Fetching general settings...',
        })))

        const _projectSettings = await ProjectService.getGeneralSettings()
        var responseForForm:IGeneralSettingsForm= 
        { 
          ..._projectSettings,
          documenttemplatessettingsneedsupdate:_projectSettings.documenttemplates.settingsneedsupdate,
          documenttemplatessettingsurl:_projectSettings.documenttemplates.settingsurl,
          documenttemplatessettingsdiscrepancies:_projectSettings.documenttemplates.settingsdiscrepancies
        };
        setProjectSettings(responseForForm)
        setLoadingForScreen(false)
        dispatch(setLoading(false))
      } catch (error) {
        setError(error)
        setLoadingForScreen(false)
        dispatch(setLoading(false))
      }
    })()
  }, [])

  return { loading:loadingForScreen, error, projectSettings, handleSave }
}
