import * as React from 'react'
import { useDispatch } from 'react-redux'
import { Form, Input, Radio } from 'antd'
import { Button, Select } from 'shared/components'
import userDetailConfig from './userDetailConfig'
import { updateUser } from 'shared/redux/ListActions'
import './UserDetail.scss'
import AuthenticationService from 'utils/api/authentication/AuthenticationService'
import _ from 'lodash'

/**
 * @typedef {import('antd/lib/form').FormComponentProps} FormComponentProps
 *
 * @typedef {Object} Props
 * @property {import('../UsersTable').UserData} user
 * @property {boolean} [external=false]
 * @property {FormComponentProps['form']} [form]
 */

/** @param {Props} props */
function UserDetail({ user, external = false, form }) {
  const dispatch = useDispatch()
  const [password, setPassword] = React.useState('')
  
  const errors = Object.values(form.getFieldsError()).some(Boolean)

  async function submitUpdateUserDetail() {
    const { key: userId } = user
    const payload = form.getFieldsValue()

    if (!payload.password || payload.password === '') {
      delete payload.password
    }
    delete payload.passwordConfirm

    await dispatch(updateUser(userId, payload))
    setPassword('')
    form.resetFields()
  }

  function renderUserDetailFields() {
    const { getFieldDecorator } = form
    return userDetailConfig(user, password).map((field, i) => {
      switch (field.type) {
        case 'input': {
          return (
            <Form.Item key={i} label={field.label} style={{ width: field.width }}>
              {getFieldDecorator(field.key, {
                initialValue: field.value,
                rules: field.rules,
              })(
                <Input
                  autoComplete={field.key === 'password' ? 'new-password' : 'off'}
                  type={field.key.includes('password') ? 'password' : 'text'}
                  placeholder={field.placeholder}
                  onChange={(e) => {
                    if (field.key === 'password') {
                      setPassword(e.target.value)
                    }
                    if (field.key.includes('password')) {
                      form.validateFields(['password', 'passwordConfirm'])
                    }
                  }}
                />
              )}
            </Form.Item>
          )
        }
        case 'select': {
          return (
            <Form.Item key={i} label={field.label} style={{ width: field.width }}>
              {getFieldDecorator(field.key, {
                initialValue: field.value,
                rules: field.rules,
              })(
                <Select
                  mode="multiple"
                  placeholder={field.placeholder}
                  data-testid={`select${field.key}`}
                  width={field.width}
                  options={field.options}
                  wrap={false}
                />
              )}
            </Form.Item>
          )
        }
        case 'radio': {
          return (
            <Form.Item key={i} label={field.label} style={{ width: field.width }}>
              {getFieldDecorator(field.key, { initialValue: field.value })(
                <Radio.Group name={field.key} options={field.options} />
              )}
            </Form.Item>
          )
        }
        default:
          return null
      }
    })
  }

  return (
    <div>
      <Form className="user-detail__form" data-testid="userdetailform" name="userdetailform">
        {renderUserDetailFields()}
      </Form>
      <div className="user-detail__buttons">
        <Button
          small
          disabled={errors || !form.isFieldsTouched()}
          text="Save"
          onClick={submitUpdateUserDetail}
          margin="0 7px 0 0"
          data-testid="submituserupdatebtn"
        />
        <Button small secondary text="Cancel" onClick={() => form.resetFields()} />
      </div>
    </div>
  )
}

/** @type {import('antd/lib/form/interface').FormWrappedProps<FormComponentProps & Props>} */
const formInstance = Form.create({ name: 'user-detail' })

export default formInstance(UserDetail)
