import http from 'utils/api/Client'
import _ from 'lodash'
import { formatIdAsKey } from 'utils/helpers'
import {
  IConveyor,
  IConveyorOrderRequest,
  IConveyorResponse,
  IGearmotorSpecsAggregateResponse,
  ILegendFileField,
  IMaterialsDetailedResponse,
  IPriceValidation,
  IVersion,
  IVFDSelection,
} from 'shared/types/swagger'
import { AxiosResponse } from 'axios'
import { IFileUpload } from 'shared/types/FileUpload'
import { updateConveyorTorquemasterValues, formatConveyorsAndConveyorSections } from './helpers'
import { IConveyorWithKeyedSections } from 'shared/types/Conveyor'
import arrangeDesiredUnitOptions from './helpers/arrangeDesiredUnitOptions'
import IJSONPatchOperation from '../types'
import { IPriceValidationVersionAndConveyorsResponse } from 'shared/types/PriceValidationVersionAndConveyorsResponse'

const ConveyorService = {
  async getConveyor(conveyorId) {
    const { data } = await http.get<IConveyor>(`/Conveyors/${conveyorId}`)
    return formatConveyorsAndConveyorSections([data])
  },
  async cloneConveyor(conveyorId) {
    const { data } = await http.post<unknown, AxiosResponse<IConveyor>>(
      `/Conveyors/${conveyorId}/Clone`,
      {}
    )
    return formatConveyorsAndConveyorSections([data])
  },
  async deleteConveyor(conveyorId) {
    const { data } = await http.delete<null, AxiosResponse<unknown>>(`/Conveyors/${conveyorId}`)
    return data
  },
  async addConveyorToVersion(versionId: number | string, payload) {
    const { data } = await http.post<Partial<IConveyor>, AxiosResponse<IConveyor>>(
      `/Versions/${versionId}/Conveyors`,
      payload
    )
    return data
  },
  async updateConveyorsOrder(versionId: number | string, payload: Array<IConveyorOrderRequest>) {
    const { data } = await http.post<Array<IConveyorOrderRequest>, AxiosResponse<unknown>>(
      `/Versions/${versionId}/SetConveyorsOrder`,
      payload
    )
    return data
  },
  async resetOverridesForConveyor({ conveyorId, type, fieldKey }) {
    const { data } = await http.post<unknown, AxiosResponse<unknown>>(
      `/Conveyors/${conveyorId}/${type}/${fieldKey}/ResetOverrides`,
      {}
    )
    return data
  },
  async downloadConveyorFile(conveyorId: string | number, downloadType: 'MasterBOM' | 'PrelimBOM') {
    const { data } = await http.get<IFileUpload>(
      `/Conveyors/${conveyorId}/Documents/${downloadType}`
    )
    const link = document.createElement('a')
    link.download = data.filename
    link.href = data.filecontent
    link.click()
  },
  async getVersionConveyors(versionId: string | number) {
    const { data } = await http.get<Array<IConveyor>>(`/Versions/${versionId}/Conveyors`)
    return formatConveyorsAndConveyorSections(data)
  },
  async getVersionOptionsRaw(versionId: string | number) {
    const { data } = await http.get<Array<IPriceValidation>>(
      `/Versions/${versionId}/ValidationPrices`
    )
    return data
  },
  async getVersionOptions(versionId: string | number) {
    const data = await this.getVersionOptionsRaw(versionId)
    return formatIdAsKey(data)
  },
  async getTorquemasterResults(
    conveyorId: number | string,
    conveyorData: IConveyorWithKeyedSections
  ) {
    const { data } = await http.get<IConveyorResponse>(
      `/Conveyors/torquemastervalues/${conveyorId}`
    )
    return updateConveyorTorquemasterValues(conveyorData, data)
  },
  async updateConveyor(
    versionId: string | number,
    conveyorId: string | number,
    payload: IConveyor
  ) {
    const { data } = await http.put<IConveyor, AxiosResponse<IConveyor>>(
      `/Versions/${versionId}/Conveyors/${conveyorId}`,
      payload
    )
    return formatConveyorsAndConveyorSections([data])
  },
  async uploadConveyorBuilderImage(
    conveyorId: string | number,
    file: FormData
  ) {
    const { data } = await http.post<FormData, { data: unknown }>(
      `/Conveyors/${conveyorId}/UploadConveyorBuilderImage`,
      file,
      { headers: { 'Content-Type': 'multipart/form-data' } }
    )
    return data
  },
  async addOption(versionId: string | number, payload: IPriceValidation) {
    const { data } = await http.post<IPriceValidation, AxiosResponse<IVersion>>(
      `/Versions/${versionId}/ValidationPrices`,
      payload
    )
    return data
  },
  async deleteOption(versionId: string | number, optionId: number | string) {
    const { data } = await http.delete<unknown, AxiosResponse<unknown>>(
      `/Versions/${versionId}/ValidationPrices/${optionId}`
    )
    return data
  },
  async updateOption(versionId: string | number, optionId: number | string, payload) {
    const { data } = await http.put<
      IPriceValidation,
      AxiosResponse<IVersion & { conveyors: unknown }>
    >(`/Versions/${versionId}/ValidationPrices/${optionId}`, payload)
    return data
  },
  async updateAllGlobalOptions(versionId: string | number, payload: Array<IPriceValidation>) {
    const { data } = await http.put<Array<IPriceValidation>, AxiosResponse<IPriceValidationVersionAndConveyorsResponse>>(
      `/Versions/${versionId}/ValidationPrices`,
      payload
    )
    return data
  },
  async addConveyorOption(conveyorId: number | string, payload: IPriceValidation) {
    const { data } = await http.post<
      IPriceValidation,
      AxiosResponse<IPriceValidationVersionAndConveyorsResponse>
    >(`/Conveyors/${conveyorId}/ValidationPrices`, payload)
    return data
  },
  async deleteConveyorOption(conveyorId: number | string, optionId: number | string) {
    const { data } = await http.delete<unknown, AxiosResponse<unknown>>(
      `/Conveyors/${conveyorId}/ValidationPrices/${optionId}`
    )
    return data
  },
  async updateConveyorOption(
    conveyorId: number | string,
    optionId: number | string,
    payload: IPriceValidation
  ) {
    const { data } = await http.put<
      IPriceValidation,
      AxiosResponse<IPriceValidationVersionAndConveyorsResponse>
    >(`/Conveyors/${conveyorId}/ValidationPrices/${optionId}`, payload)
    return data
  },
  async updateAllConveyorOptions(conveyorId: number | string, payload: Array<IPriceValidation>) {
    const { data } = await http.put<
      Array<IPriceValidation>,
      AxiosResponse<Array<IPriceValidation>>
    >(`/Conveyors/${conveyorId}/ValidationPrices`, payload)
    return data
  },
  async getConveyorLegendFields(conveyorId) {
    const { data } = await http.get<Array<ILegendFileField>>(
      `/Conveyors/${conveyorId}/LegendFields`
    )
    return data
  },
  async updateConveyorLegendFields(conveyorId: number | string, payload: Record<string, string>) {
    const patchPayload = Object.entries(payload).map(([key, value]) => ({
      op: 'replace',
      path: `/${key}`,
      value,
    }))
    const { data } = await http.patch<Array<IJSONPatchOperation>, AxiosResponse<ILegendFileField>>(
      `/Conveyors/${conveyorId}/LegendFields`,
      patchPayload
    )
    return data
  },
  //  __  __          _       __    _
  // |  \/  |  __ _  | |_    / /   /_\    __   __
  // | |\/| | / _` | |  _|  / /   / _ \  / _| / _|
  // |_|  |_| \__,_|  \__| /_/   /_/ \_\ \__| \__|

  //      _          __                 _   _
  //   __| |  ___   / _|  __ _   _  _  | | | |_   ___
  //  / _` | / -_) |  _| / _` | | || | | | |  _| (_-<
  //  \__,_| \___| |_|   \__,_|  \_,_| |_|  \__| /__/

  async getDefaultMaterialsForConveyor(conveyorId) {
    const { data } = await http.get<IMaterialsDetailedResponse>(
      `/Conveyors/${conveyorId}/Materials`
    )
    return data
  },
  async getDefaultMaterialForConveyor<T extends keyof IMaterialsDetailedResponse>(
    conveyorId: number | string,
    type: T
  ) {
    const { data } = await http.get<IMaterialsDetailedResponse[T]>(
      `/Conveyors/${conveyorId}/Materials/${type}`
    )
    return data
  },
  // type is 'materials' or 'accessories'
  async updateDefaultMaterialOrAccessoryForConveyor(
    conveyorId,
    key,
    updatedFields,
    type,
    sideId = ''
  ) {
    const patchPayload = []
    _.forEach(updatedFields, (value, key) => {
      patchPayload.push({ op: 'replace', path: `/${key}`, value })
    })
    const { data } = await http.patch(
      `/Conveyors/${conveyorId}/${type}/${key}/${sideId ? sideId : ''}`,
      patchPayload
    )
    return data
  },
  async getDefaultAccessoriesForConveyor(conveyorId) {
    const { data } = await http.get(`/Conveyors/${conveyorId}/Accessories`)
    return data
  },
  async getDefaultAccessoryForConveyor(conveyorId: number | string, type) {
    const { data } = await http.get(`/Conveyors/${conveyorId}/Accessories/${type}`)
    return data
  },
  async copySideItemDefaultsForConveyor(conveyorId: number | string, key, fromSideId, toSideId) {
    const { data } = await http.post(
      `/conveyors/${conveyorId}/Accessories/${key}/Clone/${fromSideId}/${toSideId}`,
      {}
    )
    return data
  },

  //   ___              _     _                 ___
  //  / __|  ___   __  | |_  (_)  ___   _ _    / _ \  __ __  _ _
  //  \__ \ / -_) / _| |  _| | | / _ \ | ' \  | (_) | \ V / | '_|
  //  |___/ \___| \__|  \__| |_| \___/ |_||_|  \___/   \_/  |_|

  async getMaterialsForConveyorSection(sectionId) {
    const { data } = await http.get(`/ConveyorSections/${sectionId}/Materials`)
    return data
  },
  // type is 'materials' or 'accessories'
  async updateMaterialOrAccessoryForConveyorSection(
    sectionId,
    key,
    updatedFields,
    type,
    sideId = ''
  ) {
    const patchPayload = []
    _.forEach(updatedFields, (value, key) => {
      patchPayload.push({ op: 'replace', path: `/${key}`, value })
    })
    const { data } = await http.patch(
      `/ConveyorSections/${sectionId}/${type}/${key}/${sideId ? sideId : ''}`,
      patchPayload
    )
    return data
  },
  async getAccessoriesForConveyorSection(sectionId) {
    const { data } = await http.get(`/ConveyorSections/${sectionId}/Accessories`)
    return data
  },
  //   ____                                 _
  //  / ___| ___  __ _ _ __ _ __ ___   ___ | |_ ___  _ __
  // | |  _ / _ \/ _` | '__| '_ ` _ \ / _ \| __/ _ \| '__|
  // | |_| |  __/ (_| | |  | | | | | | (_) | || (_) | |
  //  \____|\___|\__,_|_|  |_| |_| |_|\___/ \__\___/|_|
  async addGearmotor(conveyorId) {
    const { data } = await http.post(`/Conveyors/${conveyorId}/Gearmotor`, {})
    return data
  },
  async getGearmotor(conveyorId) {
    const { data } = await http.get(`/Conveyors/${conveyorId}/Gearmotor`)
    return data
  },
  async getGearmotorDesiredUnitOptions(conveyorId) {
    const { data } = await http.get<Array<IGearmotorSpecsAggregateResponse>>(
      `/Conveyors/${conveyorId}/Gearmotors`
    )
    return arrangeDesiredUnitOptions(data)
  },
  async updateGearmotor(conveyorId: number | string, payload) {
    const { data } = await http.patch(`/Conveyors/${conveyorId}/Gearmotor`, payload)
    return data
  },
  async getVFDSelection(conveyorId: number | string) {
    const { data } = await http.get<Array<IVFDSelection>>(`/Conveyors/${conveyorId}/VFDSelection`)
    return data
  },
  //  ______ _                    _______     _ _ _                _____                              _
  // |  ____| |                  / / ____|   (_) (_)              / ____|                            | |
  // | |__  | | ___   ___  _ __ / / |     ___ _| |_ _ __   __ _  | (___  _   _ _ __  _ __   ___  _ __| |_ ___
  // |  __| | |/ _ \ / _ \| '__/ /| |    / _ \ | | | '_ \ / _` |  \___ \| | | | '_ \| '_ \ / _ \| '__| __/ __|
  // | |    | | (_) | (_) | | / / | |___|  __/ | | | | | | (_| |  ____) | |_| | |_) | |_) | (_) | |  | |_\__ \
  // |_|    |_|\___/ \___/|_|/_/   \_____\___|_|_|_|_| |_|\__, | |_____/ \__,_| .__/| .__/ \___/|_|   \__|___/
  //                                                       __/ |              | |   | |
  //                                                      |___/               |_|   |_|
  async getFloorSupportsForConveyor(conveyorId) {
    const { data } = await http.get(`/Conveyors/${conveyorId}/Accessories/floorsupports`)
    return data
  },
  async getFloorSupportsDetailsForConveyor(conveyorId) {
    const { data } = await http.get(`/Conveyors/${conveyorId}/Accessories/floorsupports/details`)
    return data
  },
  async postFloorSupportsForConveyor(conveyorId: number | string, payload) {
    const { data } = await http.post(
      `/Conveyors/${conveyorId}/Accessories/floorsupports/details`,
      payload
    )
    return data
  },
  async updateFloorSupportsForConveyor(conveyorId: number | string, payload, floorsupportId) {
    const { data } = await http.patch(
      `/Conveyors/${conveyorId}/Accessories/floorsupports/details/${floorsupportId}`,
      payload
    )
    return data
  },
  async deleteFloorSupportsForConveyor(conveyorId: number | string, floorsupportId) {
    const { data } = await http.delete(
      `/Conveyors/${conveyorId}/Accessories/floorsupports/details/${floorsupportId}`
    )
    return data
  },
  async getCeilingSupportsForConveyor(conveyorId) {
    const { data } = await http.get(`/Conveyors/${conveyorId}/Accessories/ceilingsupports`)
    return data
  },
  async getCeilingSupportsDetailsForConveyor(conveyorId) {
    const { data } = await http.get(`/Conveyors/${conveyorId}/Accessories/ceilingsupports/details`)
    return data
  },
  async postCeilingSupportsForConveyor(conveyorId: number | string, payload) {
    const { data } = await http.post(
      `/Conveyors/${conveyorId}/Accessories/ceilingsupports/details`,
      payload
    )
    return data
  },
  async updateCeilingSupportsForConveyor(conveyorId: number | string, payload, floorsupportId) {
    const { data } = await http.patch(
      `/Conveyors/${conveyorId}/Accessories/ceilingsupports/details/${floorsupportId}`,
      payload
    )
    return data
  },
  async deleteCeilingSupportsForConveyor(conveyorId: number | string, floorsupportId) {
    const { data } = await http.delete(
      `/Conveyors/${conveyorId}/Accessories/ceilingsupports/details/${floorsupportId}`
    )
    return data
  },
}

export default ConveyorService
