import { Col, Row } from 'antd'
import { FormComponentProps } from 'antd/lib/form/Form'
import React, { useRef, useState } from 'react'
import { Alert, Button, Form, Input, Checkbox } from 'shared/components'
import { IGeneralSettingsForm } from 'shared/types/swagger'
import formConfig from './ProjectSettingsFormConfig'
import './ProjectSettingsForm.scss'

const ALERT_TIMEOUT_MS = 8000

interface Props extends FormComponentProps {
  values: IGeneralSettingsForm
  loading: boolean
  handleSave: (values: IGeneralSettingsForm) => Promise<boolean>
}

function ProjectSettingsForm(props: Props) {
  const { form, values, loading, handleSave } = props
  const [feedback, setFeedback] = useState<{ type: 'success' | 'error'; message: string }>(null)

  const timeout = useRef<NodeJS.Timeout>()

  function handleSubmit(e: React.FormEvent) {
    e.preventDefault()

    if (timeout.current) {
      clearTimeout(timeout.current)
      setFeedback(null)
    }

    form.validateFields(async (error, vals: typeof values) => {
      if (error) {
        console.log('error')
        setFeedback({
          type: 'error',
          message: 'Error saving prefixes. See validation errors below.',
        })
        timeout.current = setTimeout(() => {
          setFeedback(null)
        }, ALERT_TIMEOUT_MS)
        return
      }

      const successful = await handleSave(vals)
      setFeedback(
        successful
          ? { type: 'success', message: 'Successfully saved prefixes' }
          : { type: 'error', message: 'Error saving prefixes' }
      )
      timeout.current = setTimeout(() => {
        setFeedback(null)
      }, ALERT_TIMEOUT_MS)
    })
  }

  function setValue(value,key) {
    const { values } = this.props
    var targetFiled = key.split('.').reduce((acc,current) => acc?.[current],values)
    targetFiled = value
  }

  return (
    <Form onSubmit={handleSubmit}>
      {feedback ? (
        <Alert
          {...feedback}
          showIcon
          closable
          className="prefixes-alert"
          onClose={() => {
            if (timeout.current) clearTimeout(timeout.current)
          }}
        />
      ) : null}
      <div className="pcf__section">
        <Row type="flex" gutter={16} className="ant-row-relative">
          {formConfig.map((field) => {
            const { key, label, cols = 12, inputProps = {}, type } = field
            let { rules } = field
            const formValue: string = form.getFieldValue(key)
            const isTouched: boolean = form.isFieldTouched(key)
            const storedValue: string = values?.[key]
            const hasNewValue: boolean = formValue !== storedValue && isTouched
            const hasError = Boolean(form.getFieldError(key))
            if (rules == undefined)
            {
              rules = []
            }

            if (type == 'openlink')
            {
              return (
                        <Button small text={label} onClick={() => window.open(storedValue)}/>
                      )              
            }

            const formItemProps = {
              className: 'pcf-input',
              extra: (
                <span>
                  {hasNewValue && storedValue && formValue && !hasError
                    ? `Current Value: ${storedValue}`
                    : ' '}
                </span>
              ),
            }

            if (type == 'textblockmultiline')
            {      
              if (storedValue != undefined && storedValue !== '')
              {
                return (
                  <div style={{ whiteSpace: 'pre-line' }}>
                    <label htmlFor={key} className="tm-input__label">
                      {label}
                    </label><br/>
                  {storedValue}
                  </div>
                )
              }
            }
            else if (type == 'checkbox' && storedValue != undefined)
            {
              return (
                <Col sm={24} className="tm-input tm-input--double">
                  <div className="pcf-container">
                    <Form.Item  {...formItemProps}>
                      {form.getFieldDecorator(key, {
                          initialValue: storedValue,
                          valuePropName: 'checked',
                        })(<Checkbox
                      dataKey={key}
                      label={label}
                      />)}
                    </Form.Item>
                  </div>
                </Col>
              )         
            }
            else
            {
              return (
                <Col sm={cols} className="tm-input tm-input--double" key={key}>
                  <label htmlFor={key} className="tm-input__label">
                    {label}
                  </label>
                  <div className="pcf-container">
                    <Form.Item {...formItemProps}>
                      {form.getFieldDecorator(key, {
                        initialValue: storedValue,
                        rules: rules.concat([
                          { required: true, message: `${label} is required.` },
                          {
                            pattern: /^[A-Z\d]*$/g,
                            message: 'Prefix must be uppercase alphanumeric.',
                          },
                        ]),
                      })(
                        <Input disabled={loading} small placeholder={storedValue} {...inputProps} />
                      )}
                    </Form.Item>
                  </div>
                </Col>
              )
            }
          })}
        </Row>
      </div>
      <div className="pcf__actions">
        <Button disabled={loading} small secondary text="Cancel" className="pcf__action-btn" />
        <Button disabled={loading} small type="submit" text="Save Changes" />
      </div>
    </Form>
  )
}

export default Form.create<Props>()(ProjectSettingsForm)
