import React, { Component } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { Input, Form, Checkbox, Radio } from 'antd'
import { Select } from 'shared/components'
import ADP from 'awesome-debounce-promise'
import './ProjectForm.scss'
import { getContacts, searchCustomers, getCustomerUsers } from 'shared/redux/ListActions'
import _ from 'lodash'
import { captureSentryError } from 'utils/helpers'
import { FormConfigItem } from 'shared/types/formConfig'
import { Store } from 'reduxStore'
import { FormItemOption } from 'shared/components/Select'

type FormProps = Form['props']['form']

export interface ProjectFormProps extends PropsFromRedux {
  form: FormProps
  className?: string
  config: (props: ProjectFormProps, state: ProjectForm['state']) => Array<FormConfigItem>
  resetFields: (key: string) => null
  type?: 'project' | 'user'
  fetchingProject: boolean,
  hint:string
}
class ProjectForm extends Component<ProjectFormProps> {
  state = {
    customers: this.props.customers,
    contacts: this.props.contacts,
    loading: false,
    project: this.props.project,
    user: this.props.user
  }

  componentDidUpdate(prevProps) {
    const { project, type = 'project' } = this.props
    if (prevProps.customers !== this.props.customers) {
      this.setState({
        customers: this.props.customers,
      })
    }
    if (prevProps.contacts !== this.props.contacts) {
      this.setState({
        contacts: this.props.contacts,
      })
    }
    if (type === 'project') {
      if (JSON.stringify(prevProps?.project || {}) !== JSON.stringify(project || {})) {
        this.setState({ project })
      }
      if (
        JSON.stringify(prevProps?.project?.customer || {}) !==
          JSON.stringify(project?.customer || {}) &&
        Boolean(project?.customer)
      ) {
        this.setState({
          customers: { [project.customer.id]: project.customer },
        })
      }
    }
  }

  componentDidMount() {
    const { project } = this.props
    if (project?.customer) {
      this.setState({ customers: { [project.customer.id]: project.customer } })
    }
  }

  renderForm = () => {
    const { getFieldDecorator } = this.props.form
    const { config, getContacts, resetFields, fetchingProject, getCustomerUsers } = this.props
    const { loading } = this.state
    const marginbottom = '3px'

    const formContents = config(this.props, this.state).map((field, i) => {
      if (!field.key) return null

      switch (field.type) {
        case 'input':
          return (
            <Form.Item
              key={i}
              label={field.prettyName}
              style={{ width: field.width, marginBottom: marginbottom }}
            >
              {getFieldDecorator(field.key, {
                initialValue: field.value,
                rules: field.rules,
              })(
                <Input
                  disabled={field.disabled || fetchingProject}
                  placeholder={field.placeholder}
                  name={field.key.toString()}
                />
              )}
            </Form.Item>
          )
        case 'select':
          return (
            <Form.Item
              key={i}
              label={field.prettyName}
              style={{ width: field.width, marginBottom: marginbottom }}
            >
              {getFieldDecorator(field.key, {
                initialValue: field.value,
                rules: field.rules,
              })(
                <Select
                  allowClear={field.allowClear}
                  placeholder={field.placeholder}
                  width={field.width}
                  disabled={field.disabled || fetchingProject}
                  onChange={(value) => {
                    if (!value || field.key === 'customerid') {
                      resetFields(field.key)
                      if (field.key === 'customerid') {
                        resetFields('contactid')
                        resetFields('purchasingcontactid')
                        resetFields('engineeringcontactid')
                      }
                    }
                  }}
                  onSelect={async (value, option) => {
                    if (field.onSelect) {
                      const result = field.onSelect(value) as { value: string } | string
                      this.props.form.setFieldsValue(
                        typeof result === 'string' ? result : result.value
                      )
                    }
                  }}
                  options={field.options}
                />
              )}
            </Form.Item>
          )
        case 'select_search':
          return (
            <Form.Item
              key={`form-item-search-${field.key}-${i}`}
              label={field.prettyName}
              style={{ width: field.width, marginBottom: marginbottom }}
            >
              {getFieldDecorator(field.key, {
                initialValue: field.value,
                rules: field.rules,
              })(
                <Select
                  disabled={field.disabled || fetchingProject}
                  loading={loading}
                  showSearch
                  data-testid={`select${field.key}`}
                  defaultActiveFirstOption={false}
                  filterOption={false}
                  placeholder={field.placeholder}
                  width={field.width}
                  onChange={(value) => {
                    if (typeof value === 'string' && _.trim(value)) {
                      resetFields(field.key)
                      if (field.key === 'customerid') {
                        resetFields('contactid')
                        resetFields('purchasingcontactid')
                        resetFields('engineeringcontactid')
                      }
                    }
                  }}
                  onSelect={async (value, option) => {
                    if (field.onSelect && field.key === 'customerid') {
                      const result = field.onSelect(value, option) as
                        | { value: string }
                        | { value: { id: number } }
                        | string
                      if (typeof result === 'string') {
                        this.props.form.setFieldsValue(result)
                      } else if (typeof result.value === 'string') {
                        this.props.form.setFieldsValue(result.value)
                      } else {
                        this.props.form.setFieldsValue(result.value)
                        await getContacts(result.value.id)
                        await getCustomerUsers(result.value.id)
                      }
                      resetFields('contact')
                      resetFields('purchasingcontact')
                      resetFields('engineeringcontact')
                    }
                  }}
                  onSearch={(value) => {
                    if (_.trim(value)) {
                      if (field.key === 'customerid') {
                        this.setState(
                          {
                            [`search${field.key}`]: value,
                          },
                          () => this.debouncedSearchCustomers(value)
                        )
                      }
                    }
                  }}
                  options={field.options}
                />
              )}
            </Form.Item>
          )
        case 'text': {
          return (
            <Form.Item
              key={i}
              label={field.prettyName}
              style={{ width: field.width, marginBottom: marginbottom }}
            >
              {getFieldDecorator(field.key, {
                initialValue: field.value,
                rules: field.rules,
              })(
                <Input
                  className="display-text"
                  disabled
                  placeholder="N/A"
                  name={field.key.toString()}
                />
              )}
            </Form.Item>
          )
        }
        case 'checkbox': {
          return (
            <Form.Item key={i} label=" " style={{ width: field.width, marginBottom: marginbottom, display: 'flex' }}>
              {getFieldDecorator(field.key, {
                initialValue: field.value,
                rules: field.rules,
                valuePropName: 'checked',
              })(
                <Checkbox disabled={field.disabled || fetchingProject}>{field.prettyName}</Checkbox>
              )}
            </Form.Item>
          )
        }
        case 'radio': {
          return (
            <Form.Item key={i} label={field.prettyName} style={{ width: field.width, marginBottom: marginbottom }}>
              {getFieldDecorator(field.key, {
                initialValue: field.value,
                rules: field.rules,
              })(
                <Radio.Group disabled={field.disabled || fetchingProject} options={field.options as FormItemOption[]} />
              )}
            </Form.Item>
          )
        }
        case 'textarea': {
          return (
            <Form.Item
              key={i}
              label={field.prettyName}
              style={{ width: field.width, marginBottom: marginbottom }}
            >
              {getFieldDecorator(field.key, {
                initialValue: field.value,
                rules: field.rules,
              })(
                <Input.TextArea
                  disabled={field.disabled || fetchingProject}
                  placeholder={field.placeholder}
                  name={field.key.toString()}
                  autoSize={{ minRows: 3 }}
                />
              )}
            </Form.Item>
          )
        }
        default:
          return null
      }
    })
    return formContents
  }

  searchCustomers = (value) => {
    const { searchCustomers } = this.props
    this.setState({ loading: true }, async () => {
      await searchCustomers(value)
      this.setState({ loading: false })
    })
  }

  debouncedSearchCustomers = ADP(this.searchCustomers, 1000)

  render() {
    return <Form className="project-form">{this.renderForm()}</Form>
  }
}

const mapStateToProps = (state: Store, props) => {
  try {
    const { selectedProject } = state.ProjectReducer
    return {
      accountManagers: state.ListReducer.accountManagers,
      estimators: state.ListReducer.estimators,
      designers: state.ListReducer.designers,
      customers: state.ListReducer.customers,
      customerUsers: state.ListReducer.customerUsers,
      version: state.ProjectReducer?.versions[state.VersionReducer?.versionId],
      contacts: state.ListReducer.contacts,
      project:
        props.project ||
        (selectedProject && props.type !== 'user' ? selectedProject : {}),
      user: props.user,
      permissions: state.UserReducer.permissions,
      roles: state.UserReducer.roles,
      authorizedexternalprojecttypes: state.UserReducer.authorizedexternalprojecttypes,
    }
  } catch (error) {
    captureSentryError(error, state)
  }
}

const mapDispatchToProps = { getContacts, searchCustomers, getCustomerUsers }
const connector = connect(mapStateToProps, mapDispatchToProps)
type PropsFromRedux = ConnectedProps<typeof connector>
const connectedProjectForm = connector(Form.create({ name: 'project' })(ProjectForm))

export default connectedProjectForm
