import _, { debounce } from 'lodash'
import * as React from 'react'
import { Radio, Form } from 'antd'
import { Icon, Select, Input } from 'shared/components'
import { formatCurrency, ifDecimalRoundToNum } from 'utils/helpers'
import ChainBuilder from './components/chainBuilder'
import chainConfig from './components/chainConfig'
import PartsTable from './components/PartsTable'
import colors from 'shared/constants/colors'
import { ValidationError } from 'features/Project/components/ProjectSummary/utils/validateProject'
import store from 'reduxStore'

function countValidChainPatterns(chain) {
  return (chain?.chainpatterns || []).filter((pattern) => {
    const centerElements = (pattern.centerelements || []).filter((ce) => !('tempId' in ce))
    return pattern.leftelementid && pattern.rightelementid && centerElements.length
  }).length
}

/**
 * @typedef {import('./index').ChainConnectProps} ChainConnectProps
 * @typedef {import('antd/lib/form').FormProps['form']} FormComponentProps
 *
 * @typedef {Object} Props
 * @prop {FormComponentProps} form
 * @prop {number} conveyorId
 * @prop {import('react').CSSProperties} style
 */

/** @extends {React.Component<ChainConnectProps & Props>} */
export default class Chain extends React.Component {
  state = {
    showparts: false,
    requestQueue: [],
  }

  async componentDidMount() {
    const { conveyor, versionId } = this.props
    if (!conveyor.chain.standardchainstyle) {
      this.props.initializeAllChainNonStandardRowsMeta(conveyor.id, versionId)
    }
    this.validateChain()
  }

  componentDidUpdate(prevProps) {
    // TODO: Should move to the update conveyor action.. stuff like this in components
    // that then triggers redux waterflow gets confusing.
    const { conveyor, getChainSerieInfo, versionId, pendingApiResponse } = this.props
    const { chain } = conveyor
    const { requestQueue } = this.state
    const hasPendingChainUpdate = `chain#${conveyor.id}` in pendingApiResponse
    const hadPendingChainUpdate = `chain#${conveyor.id}` in prevProps.pendingApiResponse
    const prevChain = prevProps.conveyor.chain
    const isStandard = chain.standardchainstyle
    const wasStandard = prevChain.standardchainstyle
    const chainStyleChanged = (isStandard && wasStandard !== isStandard)
    const chainSerieChanged = prevChain.chainserieid !== chain.chainserieid
    const chainWidthChanged = prevChain.stdchainwidthid !== chain.stdchainwidthid
    const shouldResetFields = (
      (chainStyleChanged || chainSerieChanged || chainWidthChanged) ||
      (!isStandard && countValidChainPatterns(prevChain) !== countValidChainPatterns(chain)) ||
      (prevProps.conveyor.unit !== conveyor.unit) ||
      (prevProps.conveyor.materialid !== conveyor.materialid)
    )

    // Fetch or initialize Chain Serie info if needed
    if (chainStyleChanged || chainSerieChanged || chainWidthChanged) {
      getChainSerieInfo(versionId, conveyor.id, chain)
    } else if ((!isStandard && wasStandard !== isStandard) || chainSerieChanged) {
      this.props.initializeAllChainNonStandardRowsMeta(conveyor.id, versionId)
    }

    // validate chain the conveyor changes
    if (prevProps.conveyor !== conveyor) {
      this.validateChain()
    }

    // reset form fields when various fields change. This allows the initialValue to 
    // override the state from any antd `form` item's current value
    if (shouldResetFields) {
      this.props.form.resetFields()
    }

    // handle queue of requests
    if (hadPendingChainUpdate && !hasPendingChainUpdate && requestQueue.length) {
      const [nextRequest, ...rest] = requestQueue
      nextRequest()
      this.setState({ requestQueue: rest || [] })
    }
  }

  deleteAllHorizontalCurves = () => {
    const { conveyor, deleteConveyorSection, setLoading } = this.props
    const { conveyorsections } = conveyor
    const horizontalCurves = _.filter(conveyorsections, (s) => s.type === 'HorizontalCurve')
    let horizontalCurvesCount = horizontalCurves.length
    return new Promise(async (resolve, reject) => {
      if (horizontalCurvesCount === 0) {
        resolve(true)
      } else {
        setLoading({
          loading: true,
          loadingMessage: `Deleting All Horizontal Curves from ${conveyor.name}...`,
        })
        for (let i = 0; i < horizontalCurves.length; i++) {
          await deleteConveyorSection(conveyor.id, horizontalCurves[i].id)
          horizontalCurvesCount -= 1
          if (horizontalCurvesCount === 0) {
            resolve(true)
          }
        }
        setLoading(false)
      }
    })
  }

  validateChain = () => {
    const {
      conveyor,
      conveyorId,
      form,
      metadata,
      updateCompletedTabs,
      versionId,
      updateChainValidationErrors,
    } = this.props
    const {
      chain: { chainpatterns, standardchainstyle, widthenglish, widthmetric },
    } = conveyor

    return new Promise((resolve, reject) => {
      form.validateFields((errors, values) => {
        if (errors) {
          updateChainValidationErrors({
            versionId,
            conveyorId,
            errors: Object.keys(errors).map(
              (field) => new ValidationError({ tab: 'Chain', field, conveyor })
            ),
          })
        } else {
          updateChainValidationErrors({
            versionId,
            conveyorId,
            errors: [],
          })
        }

        if (
          errors ||
          !widthenglish ||
          !widthmetric ||
          (!standardchainstyle && !chainpatterns.length)
        ) {
          updateCompletedTabs({
            conveyorId,
            versionId,
            completedTabs: {
              ...metadata.completedTabs,
              chain: false,
            },
          })
          resolve('Chain and/or Product failed validation.')
        } else {
          updateCompletedTabs({
            conveyorId,
            versionId,
            completedTabs: {
              ...metadata.completedTabs,
              chain: true,
            },
          })
          resolve(true)
        }
      })
    })
  }

  handleInputChange = debounce((inputValue, field, conveyorId) => {
    const { form } = this.props
    if (!inputValue) return
    form.setFieldsValue({ [field.key]: inputValue })
    form.validateFields([field.key], (errors, values) => {
      if (!errors) {
        if (field.key === 'speed') {
          this.props.updateConveyor(conveyorId, { [field.key]: inputValue }, true, false)
        } else {
          const payload = {
            [field.key]: inputValue,
          }
          this.updateChain(field.optimistic, payload, true)
        }
      }
      this.validateChain()
    })
  }, 200)

  renderForm = () => {
    const {
      ProjectReducer
    } = store.getState()
    const {
      chainList,
      conveyor,
      conveyorId,
      isactive,
      islocked,
      openConfirmModal,
      form,
    } = this.props
    const { getFieldDecorator, resetFields } = form
    const isMultispan =
      chainList?.[form.getFieldValue('chainserieid')?.toString?.()]?.name === 'MultiSpan'
    const isLAProject = ProjectReducer.currentexternalprojecttype == 'la'
    const config = chainConfig(this.props, isMultispan, isLAProject)
    const disabled = !isactive || islocked

    const formContents = config.map((field, i) => {
      if (field.visible) {
        switch (field.type) {
          case 'input':
            return (
              <Form.Item key={i} label={field.prettyName}>
                {getFieldDecorator(field.key, {
                  initialValue: field.value,
                  rules: field.rules,
                })(
                  <Input
                    data-testid={field.key}
                    addonAfter={field.addonAfter}
                    disabled={disabled}
                    autoFocus={field.key === 'speed'}
                    placeholder={field.placeholder}
                    name={field.key.toString()}
                    onChange={(value) => this.handleInputChange(value, field, conveyorId)}
                  />
                )}
              </Form.Item>
            )
          case 'select':
            return (
              <Form.Item key={field.key} label={field.prettyName}>
                {getFieldDecorator(field.key, {
                  initialValue: field.value,
                  rules: field.rules,
                })(
                  <Select
                    disabled={disabled}
                    placeholder={field.placeholder}
                    onChange={(value) => {
                      if (!field.onSelect) {
                        const payload = {
                          [field.key]: value,
                        }
                        if (
                          field.key === 'chainserieid' &&
                          chainList[value.toString()].name === 'MultiSpan Closed-Top'
                        ) {
                          openConfirmModal({
                            bodyText:
                              'All Horizontal Curve sections will be DELETED from this conveyor and is IRREVERSIBLE. Are you sure?',
                            onConfirm: () => {
                              this.deleteAllHorizontalCurves()
                                .then(() => this.updateChain(field.optimistic, payload))
                                .catch((e) => console.log(e))
                            },
                            onCancel: () => resetFields([field.key]),
                          })
                        } else if (
                          field.key === 'chainserieid' &&
                          conveyor.chain.chainpatterns.length
                        ) {
                          openConfirmModal({
                            bodyText:
                              'This action will clear out all chain patterns. Are you sure you want to change Chain Series?',
                            onConfirm: () => this.updateChain(false, payload),
                            onCancel: () => resetFields([field.key]),
                          })
                        } else {
                          this.updateChain(field.optimistic, payload)
                        }
                      }
                    }}
                    onSelect={async (value, option) => {
                      if (field.onSelect) {
                        const payload = field.onSelect(field.key, value, option)
                        this.updateChain(field.optimistic, payload)
                      }
                    }}
                    data-testid={field.key}
                    options={field.options}
                  />
                )}
              </Form.Item>
            )
          case 'radio':
            return (
              <Form.Item key={i} label={field.prettyName}>
                {getFieldDecorator(field.key, {
                  initialValue: field.value,
                  rules: field.rules,
                })(
                  <Radio.Group
                    disabled={disabled}
                    name={field.key.toString()}
                    onChange={(e) => {
                      const payload = {
                        [field.key]: e.target.value,
                      }
                      if (field.key === 'bluelinks' && e.target.value) {
                        openConfirmModal({
                          bodyText:
                            'Selecting blue links for this chain style will impact delivery time and cost. Are you sure?',
                          cancelButtonText: 'Cancel',
                          confirmButtonText: 'Continue',
                          onConfirm: () => this.updateChain(field.optimistic, payload),
                          onCancel: () => resetFields([field.key]),
                        })
                      } else {
                        this.updateChain(false, payload, true)
                      }
                    }}
                    options={field.options}
                  />
                )}
              </Form.Item>
            )
          default:
            return null
        }
      } else {
        return null
      }
    })
    return formContents
  }

  updateChain = (optimistic, payload, debounce = false) => {
    const {
      conveyor,
      conveyorId,
      pendingApiResponse,
      updateChain,
      updateChainOptimistic,
    } = this.props
    const chainId = conveyor.chain.id
    const action = optimistic ? updateChainOptimistic : updateChain
    if (pendingApiResponse?.[`chain#${conveyorId}`]) {
      this.setState(({ requestQueue }) => ({
        requestQueue: [...requestQueue, () => action(conveyorId, chainId, payload, debounce)],
      }))
    } else {
      action(conveyorId, chainId, payload, debounce)
    }
  }

  render() {
    const { showparts } = this.state
    const {
      conveyor,
      isactive,
      islocked,
      setChainPreviewConveyorId,
      setModalContent,
      permissions,
      style,
    } = this.props // if rest of conveyor data not needed, only pass in chain inside ConveyorCard
    return (
      <div className="chain-container" data-testid="chaincontainer" style={style}>
        <div className="standard-settings">
          <Form className={`project-form ${isactive && !islocked ? '' : 'version-inactive'}`}>
            {this.renderForm()}
            {permissions.includes('view_chain_cost_per_foot') ? (
              <div className="chain-cost">
                <div className="label">Chain cost per foot</div>
                <div className="value">{formatCurrency(conveyor.chain.costperfoot, 2)}</div>
              </div>
            ) : null}
            {permissions.includes('view_chain_cost_per_foot') ? (
              <div className="chain-cost">
                <div className="label">Chain labor per foot</div>
                <div className="value">{ifDecimalRoundToNum(conveyor.chain.chainparts.reduce((laborsum,part) => {return laborsum+(part.totallinklabor ?? 0)},0), 2)}</div>
              </div>
            ) : null}
          </Form>
        </div>
        {conveyor.chain.standardchainstyle ? null : (
          <ChainBuilder
            saveChain={this.updateChain}
            conveyorId={conveyor.id}
            unitSuffix={this.props.conveyor.unit === 'English' ? 'inches' : 'mm'}
          />
        )}
        {/* TODO: make this an Button */}
        <div className="chain-buttons-container">
          <button
            className="show-parts-button"
            style={conveyor.chain.standardchainstyle ? { borderRight: 'none' } : {}}
            onClick={() => this.setState((state) => ({ showparts: !state.showparts }))}
          >
            <Icon icon="add" size={11} color={colors.novaBlue} />
            <span className="button-text">{showparts ? 'Hide Parts' : 'Show Parts'}</span>
          </button>
          {conveyor.chain.standardchainstyle ? null : (
            <div className="preview-chain-button">
              <Icon icon="zoomIn" size={16} color={colors.novaBlue} />
              <div
                className="button-text"
                onClick={() => {
                  setChainPreviewConveyorId(conveyor.id)
                  setModalContent('chainPreview')
                }}
              >
                Preview Chain
              </div>
            </div>
          )}
        </div>
        <div aria-controls="parts-table" aria-expanded={showparts}>
          <PartsTable
            chainparts={conveyor.chain.chainparts}
            style={{ display: showparts ? 'block' : 'none' }}
          />
        </div>
      </div>
    )
  }
}
