import React, { Component, ReactNode } from 'react'
import { connect, useDispatch } from 'react-redux'
import { Form, Input, Radio } from 'antd'
import { Button, Select } from 'shared/components'
import externalUserDetailConfig from './externalUserDetailConfig'
import { updateUser } from 'shared/redux/ListActions'
import './ExternalUserDetail.scss'
import ADP from 'awesome-debounce-promise'
import AuthenticationService from 'utils/api/authentication/AuthenticationService'
import ListService from 'utils/api/list/ListService'
import _ from 'lodash'
import { IExternalUserReduced } from 'shared/types/user'
import { DerivedUser, getDerivedUser } from './helpers/getDerivedUser'
import { FormItemOption } from 'shared/components/Select'

interface Props {
  user: IExternalUserReduced
  form: Form['props']['form']
}

function ExternalUserDetail({ user, form }: Props) {
  const [_user, setUser] = React.useState<DerivedUser>(getDerivedUser(user))
  const [unsavedChanges, setUnsavedChanges] = React.useState<boolean>(false)
  const [errors, setErrors] = React.useState<boolean>(false)
  const [loading, setLoading] = React.useState<boolean>(false)
  const dispatch = useDispatch()

  React.useEffect(() => {
    // if there is a difference in local state and redux state, use local state
    if (_user?.customerid && _user.customerid !== user.customerid) {
      searchContacts(_user.customerid)
    // using redux state
    } else if (user?.customerid) {
      searchContacts(user.customerid)
    }
  }, [user.customerid, _user.customerid])

  async function submitUpdateUserDetail() {
    const { key: userId } = user
    const payload = form.getFieldsValue()

    if (!payload.password || payload.password === '') {
      delete payload.password
    }
    delete payload.password_confirm

    if (payload.contactid && Number(payload.contactid) !== Number(user.contactid)) {
      const contact = _user.contacts[payload.contactid]
      payload.firstname = contact.firstname
      payload.lastname = contact.lastname
      payload.emailaddress = contact.emailaddress
    }

    payload.regularproject=payload.projecttype == 'regular' || payload.projecttype == 'all'
    payload.laproject=payload.projecttype == 'la' || payload.projecttype == 'all'

    delete payload.projecttype

    await dispatch(updateUser(userId, payload, true))
    setUnsavedChanges(false)
    form.setFieldsValue({ password: undefined, password_confirm: undefined })
  }

  async function searchCustomers(value) {
    setLoading(true)
    try {
      const data = await ListService.searchCustomers(value)
      setUser((u) => ({ ...u, customers: data }))
      setLoading(false)
    } catch (error) {
      console.log('err', error)
      setLoading(false)
    }
  }

  async function searchContacts(customerid) {
    setLoading(true)
    try {
      const data = await ListService.contacts(customerid)
      setUser((u) => ({ ...u, contacts: data }))
      setLoading(false)
    } catch (error) {
      console.log('err', error)
      setLoading(false)
    }
  }

  const debouncedSearchCustomers = ADP(searchCustomers, 1000)

  function renderUserDetailFields(): Array<ReactNode> {
    const { getFieldDecorator, setFieldsValue, resetFields } = form
    const config = externalUserDetailConfig(user, _user, _user.password)

    return config.map((field, i) => {
      switch (field.type) {
        case 'input': {
          return (
            <Form.Item key={i} label={field.label} style={{ width: field.width }}>
              {getFieldDecorator(field.key, {
                initialValue: field.value,
                rules: field.rules,
              })(
                <Input
                  autoComplete={field.key === 'password' ? 'new-password' : 'off'}
                  type={field.key.includes('password') ? 'password' : 'text'}
                  placeholder={field.placeholder}
                  onChange={async (e) => {
                    e.persist()
                    setUser((u) => ({ ...u, [field.key]: e.target.value }))
                    setUnsavedChanges(true)
                    await setFieldsValue({ [field.key]: e.target.value })
                    validateFields()
                  }}
                />
              )}
            </Form.Item>
          )
        }
        case 'select': {
          return (
            <Form.Item key={i} label={field.label} style={{ width: field.width }}>
              {getFieldDecorator(field.key, {
                initialValue: field.value,
                rules: field.rules,
              })(
                <Select
                  placeholder={field.placeholder}
                  onChange={(value) => {
                    setUser((u) => ({ ...u, [field.key]: value }))
                    setUnsavedChanges(true)
                    if (value && field.key === 'contactid') form.setFieldsValue({ contactid: value })
                    validateFields()
                  }}
                  width={field.width}
                  options={field.options}
                  wrap={false}
                />
              )}
            </Form.Item>
          )
        }
        case 'select_search': {
          return (
            <Form.Item
              key={`form-item-search-${field.key}-${i}`}
              label={field.label}
              style={{ width: field.width, marginBottom: '7px' }}
            >
              {getFieldDecorator(field.key, {
                initialValue: field.value,
                rules: field.rules,
              })(
                <Select
                  disabled={field.disabled}
                  showSearch
                  defaultActiveFirstOption={false}
                  filterOption={false}
                  // placeholder={field.placeholder}
                  onChange={(value) => {
                    if (_.trim(value.toString())) {
                      resetFields([field.key])
                    }
                  }}
                  width={field.width}
                  data-testid={`select${field.key}`}
                  onSelect={async (value, option) => {
                    if (field.onSelect && field.key === 'customerid') {
                      const result: { value?: { id: string } } = field.onSelect(value, option)
                      form.setFieldsValue(result.value)
                      await searchContacts(result.value.id)
                      if (Number(user.customerid) !== Number(value)) {
                        form.setFieldsValue({ contactid: undefined })
                        setUser((u) => ({ ...u, contacts: {} }))
                      }
                    }
                    setUser((u) => ({ ...u, [field.key]: value }))
                    setUnsavedChanges(true)
                    validateFields()
                  }}
                  onSearch={(value) => {
                    if (_.trim(value)) {
                      if (field.key === 'customerid') {
                        debouncedSearchCustomers(value)
                      }
                    }
                  }}
                  options={field.options}
                />
              )}
            </Form.Item>
          )
        }
        case 'radio': {
          return (
            <Form.Item key={i} label={field.label} style={{ width: field.width }}>
              {getFieldDecorator(field.key, { initialValue: field.value })(
                <Radio.Group
                  name={field.key.toString()}
                  onChange={({ target: { value } }) => {
                    const optionValues = field.options.map((opt) => opt.value)
                    const isStringBool =
                      optionValues.includes('true') && optionValues.includes('false')
                    setUser((u) => ({ ...u, [field.key]: isStringBool ? value === 'true' : value }))
                    setUnsavedChanges(true)
                  }}
                  options={field.options as FormItemOption[]}
                />
              )}
            </Form.Item>
          )
        }
        default:
          return null
      }
    })
  }

  const validateFields = () =>
    new Promise((resolve, reject) => {
      form.validateFields((errors, values) => {
        if (errors) {
          setErrors(true)
          reject()
        } else {
          setErrors(false)
          resolve(true)
        }
      })
    })

  return (
    <div>
      <Form className="user-detail__form" data-testid="externaluserdetailform">
        {renderUserDetailFields()}
      </Form>
      <div className="user-detail__buttons">
        <Button
          small
          disabled={errors || !unsavedChanges}
          text="Save"
          onClick={submitUpdateUserDetail}
          margin="0 7px 0 0"
          data-testid="submitexternaluserupdatebtn"
        />
        <Button small secondary text="Cancel" onClick={() => form.resetFields()} />
      </div>
    </div>
  )
}

export default Form.create({ name: 'user-detail' })(ExternalUserDetail)
