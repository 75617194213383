import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { SectionOverrideHeader } from '../../../shared/Headers'
import { updateSideframes } from '../../redux/sectionOverrides/SideframeActions'
import {
  updateSectionOverrideMaterialAcessoriesMetaData,
  addMaterialOrAccessoryToConveyorSection,
} from '../../../../redux/SectionOverrideActions'
import sideframesConfig from '../SideframesConfig'
import { Radio, InputWithValidation } from 'shared/components'
import { captureSentryError } from 'utils/helpers'

class Sideframes extends PureComponent {
  renderSideframesMaterialCoreSpecsField = (config) => {
    const { dataKey, prettyName, options, rules } = config.material
    const {
      updateSideframes,
      conveyorId,
      sideframesData,
      sectionId,
      isactive,
      islocked,
    } = this.props
    return (
      <Radio
        key="default-materials-sideframes-material"
        disabled={!isactive || islocked}
        dataKey={dataKey}
        prettyName={prettyName}
        margin="0 150px 0px 0"
        options={options}
        onChange={(val) =>
          updateSideframes({ sectionId, conveyorId, updatedSideframesFields: { materialid: val } })
        }
        rules={rules}
        initialValue={sideframesData.materialid}
        vertical
      />
    )
  }

  renderSideframesCleanoutTypeField = (config) => {
    const { dataKey, prettyName, options, rules, isactive, islocked } = config.cleanouttype
    const { updateSideframes, conveyorId, sideframesData, sectionId } = this.props
    return (
      <Radio
        key="default-materials-sideframes-cleanouttype"
        disabled={!isactive || islocked}
        dataKey={dataKey}
        prettyName={prettyName}
        margin="0 0 10px 0"
        options={options}
        onChange={(val) =>
          updateSideframes({
            sectionId,
            conveyorId,
            updatedSideframesFields: { cleanouttypeid: val },
          })
        }
        rules={rules}
        initialValue={sideframesData.cleanouttypeid}
        vertical
      />
    )
  }

  renderSideframesPaintedSideframeColorField = (config) => {
    const { dataKey, prettyName, options, rules } = config.standardcolors
    const {
      updateSideframes,
      conveyorId,
      sideframesData: { manualcolor },
      sideframesData,
      sectionId,
      isactive,
      islocked,
    } = this.props

    return (
      <>
        <Radio
          key="default-materials-sideframes-standardcolor"
          disabled={!isactive || islocked}
          dataKey={dataKey}
          prettyName={prettyName}
          margin="0 0 10px 0"
          options={options}
          onChange={(val) => {
            updateSideframes({
              sectionId,
              conveyorId,
              updatedSideframesFields: {
                [val === '' ? 'manualcolor' : 'standardcolorid']: val,
              },
            })
          }}
          rules={rules}
          initialValue={manualcolor !== undefined ? '' : sideframesData.standardcolorid}
          tinyImages
        />
        {manualcolor !== undefined ? this.renderSideframesRALColorField() : null}
      </>
    )
  }

  renderSideframesRALColorField = () => {
    const {
      sideframesData,
      conveyorId,
      updateSideframes,
      sectionId,
      isactive,
      islocked,
    } = this.props
    return (
      <span>
        <InputWithValidation
          label="Enter Manual Color"
          placeholder="Enter Color"
          disabled={!isactive || islocked}
          id="manualcolor"
          name="manualcolor"
          small
          width="150px"
          type="text"
          defaultValue={sideframesData.manualcolor}
          onChange={(value) => {
            updateSideframes({
              sectionId,
              conveyorId,
              updatedSideframesFields: { manualcolor: value },
            })
          }}
        />
      </span>
    )
  }

  renderSideframesPreviewImage = (config) => {
    const previewImage = config.getPreviewImage()
    return previewImage ? <img src={previewImage} alt="side frame preview" /> : 'no image'
  }

  render() {
    if (!this.props.sideframesData) {
      return (
        <SectionOverrideHeader
          checked={false}
          open={false}
          onClickCheck={() => {
            this.props.addMaterialOrAccessoryToConveyorSection({
              materialsOrAccessories: 'materials',
              sectionId: this.props.sectionId,
              conveyorId: this.props.conveyorId,
              itemName: 'sideframes',
            })
          }}
          title="Sideframes"
          type="materials"
          conveyorId={this.props.conveyorId}
          fieldKey="sideframes"
        />
      )
    }

    const {
      sideframesData: { parameteractive },
      sideframesMeta: { open },
      updateSideframes,
      conveyorId,
      sectionId,
      updateSectionOverrideMaterialAcessoriesMetaData,
    } = this.props

    const config = sideframesConfig(this.props)

    return (
      <div className="material-accessory-item">
        <SectionOverrideHeader
          checked={parameteractive}
          open={parameteractive && open}
          onClickCheck={() => {
            const checked = !parameteractive
            updateSideframes({
              sectionId,
              conveyorId,
              updatedSideframesFields: { parameteractive: checked, removedforsection: checked },
            })
          }}
          onClickOpen={() => {
            updateSectionOverrideMaterialAcessoriesMetaData({
              conveyorId,
              sectionId,
              type: 'materials',
              name: 'sideframes',
              updatedFields: {
                open: !open,
              },
            })
          }}
          title="Sideframes"
          type="materials"
          conveyorId={this.props.conveyorId}
          fieldKey="sideframes"
        >
          <div className="builder-tile__expanded__content__wrapper">
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
              {this.renderSideframesMaterialCoreSpecsField(config)}
              {this.renderSideframesCleanoutTypeField(config)}
              {this.renderSideframesPreviewImage(config)}
            </div>
            {this.renderSideframesPaintedSideframeColorField(config)}
          </div>
        </SectionOverrideHeader>
      </div>
    )
  }
}

/** @param {import('srcReducer').Store} state */
const mapStateToProps = (state, props) => {
  try {
    const { EstimatorReducer, EstimatorMetaReducer, ProjectReducer } = state
    const { conveyorId, sectionId, match } = props
    const {
      params: { versionId },
    } = match

    const sectionMetadata =
      EstimatorMetaReducer[conveyorId].conveyorbuilder.sectionsMetadata[sectionId]
    const sideframesData = EstimatorReducer?.sections?.[sectionId]?.materials?.sideframes
    const sideframesMeta =
      sectionMetadata?.materialsAccessoriesMetadata?.materials?.sideframes || {}

    const { isactive, islocked } = ProjectReducer.versions[versionId]

    if (!sideframesData) {
      return { loading: true }
    }

    return {
      versionId,
      conveyorId,
      sectionId,
      sideframesMeta,
      sideframesData,
      sideframesOptions: ProjectReducer.currentlistsettings.materials.sideframes,
      isactive,
      islocked,
    }
  } catch (error) {
    captureSentryError(error, state)
  }
}

export default withRouter(
  connect(mapStateToProps, {
    updateSideframes,
    updateSectionOverrideMaterialAcessoriesMetaData,
    addMaterialOrAccessoryToConveyorSection,
  })(Sideframes)
)
