import React from 'react'
import { purgeAndRefresh } from 'shared/redux/ScreenActions'
import { useDispatch } from 'react-redux'
import { setLoading, closeConfirmModal } from 'shared/redux/ScreenActions'
import { history } from '../../App'

function LogoutScreen() {
    const dispatch = useDispatch()

    React.useEffect(() => {
        if (process.env.NODE_ENV !== 'test') {
          dispatch(purgeAndRefresh())
          history.push('/')
        }
        dispatch(closeConfirmModal())
        dispatch(setLoading(false))
      }, [])     
  
    return (
      <div>

      </div>
    )
}

export default LogoutScreen