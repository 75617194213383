import * as React from 'react'
import { Button } from 'shared/components'
import { Icon } from 'antd'
import { useDispatch } from 'react-redux'
import { getPriceConfiguration, updatePriceConfiguration, getMiscellanousPartSelection, saveMiscellanousPartSelection } from 'shared/redux/ListActions'
import './PriceConfigurationScreen.scss'
import PriceConfigurationForm from 'features/Admin/components/PriceCofiguration/components/PriceConfigurationForm'
import formatPayload from 'features/Admin/components/PriceCofiguration/helpers/formatPayload'
import { 
  engineeringAssemblyLMSectionsConfig,
  costImportSectionsConfig,
  miscSectionsConfig,
} from 'features/Admin/components/PriceCofiguration/components/priceConfigSectionsConfig'
import { 
  assemblyEngineeringLMConfig,
  constImportConfig,
  miscpartsConfig,
} from 'features/Admin/components/PriceCofiguration/components/priceConfigFormConfig'

import ListService from 'utils/api/list/ListService'
import { typedUseSelector } from 'utils/helpers'

function PriceConfigurationScreen(props) {
  const dispatch = useDispatch()
  const [downloading, setDownloading] = React.useState(false)
  const mufupdates = typedUseSelector((store) => store.ListReducer.mufupdates)
  const generalcost = typedUseSelector((store) => store.ListReducer.generalcost)
  const miscparts = typedUseSelector((store) => store.ListReducer.miscparts)
  const externalmandatorymufs = typedUseSelector((store) => store.ListReducer.externalmandatorymufs)
  const externaloptionalmufs = typedUseSelector((store) => store.ListReducer.externaloptionalmufs)

  React.useEffect(() => {
    dispatch(getPriceConfiguration())
    dispatch(getMiscellanousPartSelection())
  }, [dispatch])

  async function handleDownload() {
    setDownloading(true)
    await ListService.downloadPriceHistory()
    setDownloading(false)
  }

  async function handleMUFandCostSave(payload) {
    const mergedPayload = {
      externalmandatorymufs: {
        settingsneedsupdate:payload?.mandatorysettingsneedsupdate
      },
      externaloptionalmufs: {
        settingsneedsupdate:payload?.optionalsettingsneedsupdate
      },
      mufupdates: payload.mufupdates.length ? payload.mufupdates : Object.values(mufupdates),
      generalcost: {
        ...generalcost,
        ...(payload?.generalcost || {}),
        laborcostsettings: payload?.generalcost?.laborcostsettings || Object.values(generalcost.laborcostsettings)
      }
    }
    await dispatch(updatePriceConfiguration(mergedPayload))
  }

  async function handMiscPartsSave(payload) {
    await dispatch(saveMiscellanousPartSelection(payload))
  }

  const lastModifiedDate = mufupdates?.['0']?.updateddate
  const lastModifiedDateDisplay = new Date(lastModifiedDate).toLocaleDateString()

  return (
    <main id="price-configuration">
      <section className="price-configuration__title">
        <h1>Price Configuration</h1>
        <Button
          disabled={downloading}
          small
          data-testid="downloadph"
          text={
            downloading ? (
              <>
                <Icon type="loading" /> Download Price History
              </>
            ) : (
              'Download Price History'
            )
          }
          icon={downloading ? undefined : 'download'}
          onClick={handleDownload}
        />
      </section>
      <section className="price-configuration__subtitle">
        {lastModifiedDate ? (
          <h5>
            MUF Last Modified:{' '}
            <span className="price-configuration__subtitle-date">{lastModifiedDateDisplay}</span>
          </h5>
        ) : (
          <h5>&nbsp;</h5>
        )}
      </section>
      <section className="pcf">
        <PriceConfigurationForm
          values={{ 
            ...mufupdates, 
            mandatorysettingsdiscrepancies:externalmandatorymufs?.settingsdiscrepancies,
            mandatorysettingsneedsupdate:externalmandatorymufs?.settingsneedsupdate,
            mandatorysettingsurl:externalmandatorymufs?.settingsurl,
            optionalsettingsdiscrepancies:externaloptionalmufs?.settingsdiscrepancies,
            optionalsettingsneedsupdate:externaloptionalmufs?.settingsneedsupdate,
            optionalsettingsurl:externaloptionalmufs?.settingsurl,
            lcs: generalcost.laborcostsettings,
            assemblycost: generalcost.assemblycost,
            engineeringcost: generalcost.engineeringcost,
            lamuf: generalcost.lamuf,
          }}
          handleSave={handleMUFandCostSave}
          downloading={downloading}
          sectionsConfig={engineeringAssemblyLMSectionsConfig}
          formConfig={assemblyEngineeringLMConfig}
          transformPayload={formatPayload}
        />
      </section>
      <section className="pcf">
        <PriceConfigurationForm
          values={{ 
            freightpercentage: generalcost.freightpercentage,
            dutypercentage: generalcost.dutypercentage,
          }}
          handleSave={handleMUFandCostSave}
          downloading={downloading}
          sectionsConfig={costImportSectionsConfig}
          formConfig={constImportConfig}
          transformPayload={formatPayload}
        />
      </section>
      <section className="pcf">
        <PriceConfigurationForm
          values={miscparts}
          handleSave={handMiscPartsSave}
          downloading={downloading}
          sectionsConfig={miscSectionsConfig}
          formConfig={miscpartsConfig}
        />
      </section>
    </main>
  )
}

export default PriceConfigurationScreen
