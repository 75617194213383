import _ from 'lodash'

/** 
 * @typedef {import('shared/types/formConfig.d').FormConfigItem} FormConfigItem 
 * @typedef {import('../Chain').ChainConnectProps & import('../Chain').Props} ChainProps
 * @typedef {Object} ChainRodCofigItem
 * @property {string} label
 * @property {string} value
 * @property {number} valueId
 * @property {number} position
 * @property {boolean} coated
 */

/** 
 * @param {import('shared/types/ChainRodDefinition').ChainRodDefinition} crd 
 * @param {boolean} coated
 * @returns {ChainRodCofigItem}
 */
function getChainRodConfigItem(crd, coated) {
  if (!crd) return null
  return {
    label: coated ? crd['coatedname'] : crd['uncoatedname'],
    value: coated ? `${crd.id}-coated` : `${crd.id}-uncoated`,
    valueId: crd.id,
    position: crd.position,
    coated: coated,
  }
}

/** 
 * @param {import('shared/types/ChainSerie').ChainSerie} chainSerie 
 * @returns {Array<ChainRodCofigItem>}
 */
const getChainRodOptions = (chainSerie, isExternalUser) => {
  if (!chainSerie) return []
  if (isExternalUser) {
    return [
      getChainRodConfigItem(
        chainSerie.coatedchainrods.find((serie) => serie.coatedname === '303 SS Standard - Coated'),
        true
      )
    ].filter(Boolean)
  }

  const uncoatedrods = chainSerie.uncoatedchainrods.map((r) => getChainRodConfigItem(r, false))
  const coatedrods = chainSerie.coatedchainrods.map((r) => getChainRodConfigItem(r, true))

  return _.orderBy(uncoatedrods.concat(coatedrods), 'position')
}

const standardWidthOptions = (conveyor, chainserie) => {
  let arr = []
  const ids = {}
  const { unit } = conveyor
  const useEnglish = unit === 'English'
  if (chainserie && chainserie.suggestedwidths && chainserie.suggestedwidths.length) {
    arr.push(...chainserie.suggestedwidths)
  }
  if (chainserie && chainserie.nonstandardwidths && chainserie.nonstandardwidths.length) {
    arr.push(...chainserie.nonstandardwidths)
  }
  if (chainserie && chainserie.additonalwidths && chainserie.additonalwidths.length) {
    arr.push(...chainserie.additonalwidths)
  }
  arr = _.sortBy(arr, ['valuemm'])
  arr = arr.map((w) => {
    if (ids[w.id]) {
      return null
    } else {
      ids[w.id] = 1
      return {
        label: `${useEnglish ? w.valueinches.toFixed(2) : w.valuemm} ${useEnglish ? 'in' : 'mm'}`,
        value: w.id,
      }
    }
  })

  _.remove(arr, (opt) => opt === null)

  return arr
}

/** 
 * @param {Partial<ChainProps>} props
 * @param {boolean} isMultispan
 * @returns {FormConfigItem[]} 
 */
export default (props, isMultispan, isLAProject) => {
  const { conveyor, chainList, chainSerieData, permissions, roles } = props
  const { chain } = conveyor
  const isNonStandard = !chain.standardchainstyle || chain.standardchainstyle === 'false'
  const filteredChainList = _.filter(chainList, (chain) => !isLAProject || !chain.hideforlaproject)
  const sortedChainList = _.sortBy(filteredChainList, (chain) => chain.index)
  const chainStylesAvailable = _.hasIn(
    chainSerieData,
    `[${conveyor.id}].chainStyles.[${chain.chainstyleid}]`
  )
  const showCleated =
    chainSerieData &&
    chainStylesAvailable &&
    (chainSerieData?.[conveyor.id]?.chainStyles?.[chain.chainstyleid]?.cleated || false)

  /** @type {FormConfigItem[]} */
  const standardChainConfig = [
    {
      key: 'standardchainstyle',
      prettyName: 'Chain Pattern Type',
      type: 'select',
      width: '250px',
      value: chain.standardchainstyle.toString(),
      visible: true,
      optimistic: true,
      rules: [
        {
          required: true,
          message: 'Chain Pattern Type is required.',
        },
      ],
      options: [
        {
          label: 'Standard',
          value: 'true',
        },
        ...(permissions.includes('non_standard_chains')
          ? [
              {
                label: 'Non standard',
                value: 'false',
              },
            ]
          : []),
      ],
      onSelect: (key, value, option) => {
        return {
          [key]: value == 'true'
        }
      },
    },
    {
      key: 'chainserieid',
      prettyName: 'Chain Series',
      type: 'select',
      width: '250px',
      value: chain.chainserieid,
      visible: true,
      rules: [
        {
          required: true,
          message: 'Chain Series is required.',
        },
      ],
      options: _.map(
        sortedChainList.filter((chainserie) => {
          if (isNonStandard) {
            return chainserie.nonstandardtype === 'RowPattern'
          }
          return true
        }),
        (option, i) => ({
          label: option.name,
          value: option.id,
        })
      ),
    },
    {
      key: 'stdchainwidthid',
      prettyName: 'Chain Width',
      type: 'select',
      width: '250px',
      value: chain.stdchainwidthid || null,
      placeholder: 'Select Chain Width',
      visible: !isNonStandard,
      rules: [
        {
          required: true,
          message: 'Chain Width is required.',
        },
      ],
      options: standardWidthOptions(conveyor, chainList[chain.chainserieid]),
    },
    {
      key: 'chainstyleid',
      prettyName: 'Chain Style',
      type: 'select',
      width: '250px',
      value: chain?.chainstyleid,
      placeholder: 'Select Chain Style',
      visible: !isNonStandard,
      rules: [
        {
          required: true,
          message: 'Chain Style is required.',
        },
      ],
      options:
        chainSerieData && chainSerieData[conveyor.id] && chainSerieData[conveyor.id].chainStyles
          ? _.map(chainSerieData[conveyor.id].chainStyles, (option) => ({
              label: option.name,
              value: option.id,
            }))
          : [],
    },
    {
      key: 'chainroddefinitionid',
      prettyName: 'Chain Rods',
      type: 'select',
      width: '250px',
      value: `${chain.chainroddefinitionid}-${chain.coated ? 'coated' : 'uncoated'}`,
      visible: !chainList?.[chain.chainserieid]?.uniquechainroddefinitionid,
      rules: [
        {
          required: true,
          message: 'Chain Rods are required.',
        },
      ],
      options: getChainRodOptions(chainList[chain.chainserieid], (roles || []).includes('ExternalUser')).map((option) => ({
        label: option.label,
        value: option.value,
        coated: option.coated,
        valueId: option.valueId,
      })),
      onSelect: (key, _, option) => {
        const { coated, valueId } = option.props
        return {
          [key]: valueId,
          coated,
        }
      },
    },
    {
      key: 'movingsideguides',
      prettyName: 'Side(s) with guides',
      type: 'select',
      width: '250px',
      value: chain.movingsideguides,
      optimistic: true,
      placeholder: 'Select Side(s)',
      visible:
        chainSerieData &&
        chainStylesAvailable &&
        chainSerieData[conveyor.id].chainStyles[chain.chainstyleid].movingsideguides,
      rules: [
        {
          required: true,
          message: 'Side(s) with guides are required.',
        },
      ],
      options: [
        { label: 'Left', value: 'Left' },
        { label: 'Right', value: 'Right' },
        { label: 'Both', value: 'Both' },
      ],
    },
    {
      key: 'movingsideguideheight',
      prettyName: 'Guide Height',
      type: 'radio',
      width: '250px',
      value: chain.movingsideguideheight,
      optimistic: true,
      visible:
        chainSerieData &&
        chainStylesAvailable &&
        chainSerieData[conveyor.id].chainStyles[chain.chainstyleid].movingsideguides &&
        !chainSerieData[conveyor.id].chainStyles[chain.chainstyleid].height,
      rules: [
        {
          required: true,
          message: 'Guide Height is required.',
        },
      ],
      options: [
        {
          label: '6 mm',
          value: 6,
        },
        {
          label: '30 mm',
          value: 30,
        },
        {
          label: '75 mm',
          value: 75,
        },
      ],
    },
    {
      key: 'cleatspacing',
      prettyName: 'Rows Between Cleats',
      type: 'input',
      width: '250px',
      value: chain.cleatspacing,
      placeholder: 'Enter Rows Between Cleats',
      visible: showCleated,
      optimistic: true,
      rules: [
        {
          required: true,
          validator: (rule, value, cb) => {
            try {
              if (isNaN(Number(value))) {
                throw new Error('Value must be a number.')
              } else if (value === '') {
                throw new Error('Number of Rows Between Cleats is required.')
              } else if (Number(value) < 0) {
                throw new Error('Please enter a positive value.')
              }
              cb()
            } catch (error) {
              cb(error)
            }
          },
        },
      ],
    },
    {
      key: 'cleatheight',
      prettyName: 'Cleat Height',
      type: 'radio',
      width: '250px',
      value: chain.cleatheight,
      visible: showCleated && isMultispan,
      rules: [
        {
          required: true,
          message: 'Guide Height is required.',
        },
      ],
      options: [
        {
          label: '30 mm',
          value: 30,
        },
        {
          label: '75 mm',
          value: 75,
        },
      ],
    },
    {
      key: 'bluelinks',
      prettyName: 'Link Color',
      type: 'radio',
      width: '250px',
      value: chain.bluelinks,
      visible: true,
      optimistic: true,
      rules: [
        {
          required: true,
          message: 'Link Color is required.',
        },
      ],
      options: [
        {
          label: 'Standard',
          value: false,
        },
        {
          label: 'Blue',
          value: true,
        },
      ],
    },
    {
      key: 'speed',
      prettyName: 'Speed',
      type: 'input',
      width: '250px',
      value: conveyor.speed,
      placeholder: 'Enter Conveyor Speed',
      addonAfter: conveyor.unit === 'Metric' ? 'M/Min' : 'Ft/min',
      visible: true,
      rules: [
        {
          required: true,
          transform: (value) => Number(value),
          validator: (rule, value, cb) => {
            try {
              if (isNaN(value)) {
                throw new Error('Value must be a number.')
              } else if (value === 0) {
                throw new Error('Speed is required.')
              } else if (value < 0) {
                throw new Error('Please enter a positive value.')
              }
              cb()
            } catch (error) {
              cb(error)
            }
          },
        },
      ],
    },
    {
      key: 'alternaterow',
      prettyName: 'High Friction Link Frequency',
      type: 'radio',
      width: '250px',
      value: chain.alternaterow,
      optimistic: true,
      visible:
        chainSerieData &&
        chainStylesAvailable &&
        chainSerieData[conveyor.id].chainStyles[chain.chainstyleid].alternaterowchoice,
      rules: [
        {
          required: true,
          message: 'High Friction Link Frequency is required.',
        },
      ],
      options: [
        {
          label: 'Every Other Row',
          value: true,
        },
        {
          label: 'Every Row',
          value: false,
        },
      ],
    },
  ]

  /** @type {FormConfigItem[]} */
  const nonStandardChainConfig = [
    {
      key: 'sprocketscount',
      prettyName: 'Sprockets Count',
      type: 'input',
      width: '250px',
      value: chain.sprocketscount,
      placeholder: 'Enter Number of Sprockets',
      visible: true,
      rules: [
        {
          required: true,
          validator: (rule, value, cb) => {
            try {
              if (isNaN(Number(value))) {
                throw new Error('Value must be a number.')
              } else if (value === '') {
                throw new Error('Number of Sprockets is required.')
              } else if (Number(value) < 0) {
                throw new Error('Please enter a positive value.')
              }
              cb()
            } catch (error) {
              cb(error)
            }
          },
        },
      ],
    },
    {
      key: 'centersupportrails',
      prettyName: 'Center Support Rails',
      type: 'input',
      width: '250px',
      value: chain.centersupportrails,
      placeholder: 'Enter Number of Center Support Rails',
      visible: true,
      rules: [
        {
          required: true,
          validator: (rule, value, cb) => {
            try {
              if (isNaN(Number(value))) {
                throw new Error('Value must be a number.')
              } else if (value === '') {
                throw new Error('Number of Center Support Rails is required.')
              } else if (Number(value) < 0) {
                throw new Error('Please enter a positive value.')
              }
              cb()
            } catch (error) {
              cb(error)
            }
          },
        },
      ],
    },
    {
      key: 'returnchainsupportrails',
      prettyName: 'Return Chain Support Rails',
      type: 'input',
      width: '250px',
      value: chain.returnchainsupportrails,
      placeholder: 'Enter Number of Return Chain Support Rails',
      visible: true,
      rules: [
        {
          required: true,
          validator: (rule, value, cb) => {
            try {
              if (isNaN(Number(value))) {
                throw new Error('Value must be a number.')
              } else if (value === '') {
                throw new Error('Number of Return Chain Support Rails is required.')
              } else if (Number(value) < 0) {
                throw new Error('Please enter a positive value.')
              }
              cb()
            } catch (error) {
              cb(error)
            }
          },
        },
      ],
    },
  ]

  return isNonStandard ? [...standardChainConfig, ...nonStandardChainConfig] : standardChainConfig
}
