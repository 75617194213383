import { IConveyorWithModifiedInfeedDischarge } from 'features/Conveyor/shared/types'
import { FormConfigItem } from 'shared/types/formConfig'
import { IGearmotor, IVFDSelection } from 'shared/types/swagger'
import { IGearMotorList } from 'utils/api/list/types'
import { DSSA47_VALID_TITLES, NORD_VALID_TITLES, SEW_VALID_TITLES, SEW_VALID_TITLES_SHAFT, TAEKDS_VALID_TITLES, TAEKDS_VALID_TITLES_EXTERNAL } from './constants'
import filterListByTitles from './helpers/filterListByTitles'
import getRadioOptions from './helpers/getRadioOptions'
import getSelectOptions from './helpers/getSelectOptions'
import isDSSA47 from './helpers/isDSSA47'
import { IDesiredUnitOptionsOption } from './types'

export type ConfigProps = {
  conveyor: IConveyorWithModifiedInfeedDischarge;
  displayBrandOnly?: boolean;
  gearmotor: IGearmotor;
  gearmotorList: Partial<IGearMotorList>;
  desiredUnitOptions: Array<IDesiredUnitOptionsOption>;
  isExternal: boolean;
  VFDOptions?: Array<IVFDSelection>;
}

export const gearmotorConfig = (props: ConfigProps): FormConfigItem[] => {
  const { conveyor, gearmotor, gearmotorList, displayBrandOnly, isExternal, VFDOptions } = props
  const brandListElement = gearmotorList.drivetypes[conveyor.gearmotortypeid]
  const brandIsDSSA47 = isDSSA47(brandListElement)
  const brandIsSEW = brandListElement.title === 'SEW EuroDrive'
  const brandIsNord = brandListElement.title === 'Nord'
  const brandIsntGTAEKDSO = brandListElement.title !== 'Get Torque Arm/Extr. Kit/Drive Shaft Only'
  const brandIsGTAEKDSO = brandListElement.title === 'Get Torque Arm/Extr. Kit/Drive Shaft Only'
  const VFDIsNone =
    gearmotorList.eurodrive.variablespeed.options?.[gearmotor.variablespeedoptionsid]?.title === 'None'
  const mountingTypeIsRemote =
    gearmotorList.eurodrive.selection.mountingtypes?.[gearmotor?.mountingtypeid]?.textvalue === 'R'

  const brandSelection: FormConfigItem = {
    key: 'gearmotortypeid',
    type: 'select',
    prettyName: 'Gearmotor Brand',
    placeholder: 'Select Brand',
    width: '350px',
    rules: [
      {
        required: true,
        message: 'Gearmotor Brand is required.',
      },
    ],
    visible: true,
    value: conveyor.gearmotortypeid,
    options: getSelectOptions(gearmotorList.drivetypes, isExternal),
  }

  // VFDOptions is a response from a GET request and isn't required.
  let vfdselection: FormConfigItem
  if (VFDOptions && Array.isArray(VFDOptions)) {
    vfdselection = {
      key: 'vfdselectionid',
      type: 'select',
      width: '350px',
      prettyName: 'VFD Selection',
      visible: brandIsntGTAEKDSO && !VFDIsNone,
      value: gearmotor.vfdselectionid,
      rules: [
        {
          required: true,
          message: 'VFD Selection is required.',
        }
      ],
      options: props.VFDOptions.map((opt) => ({
        value: opt.id,
        label: opt.name,
      })),
    }
  }

  if (displayBrandOnly) {
    return [
      brandSelection,
      {
        type: 'inputs',
        visible: displayBrandOnly,
        inputs: [
          {
            key: 'gearmotorbrand',
            prettyName: 'Gearmotor Brand',
            type: 'input',
            value: gearmotor.gearmotorbrand,
          },
        ],
      },
    ]
  }

  const gearmotorConfig: FormConfigItem[] = [
    brandSelection,
    {
      key: 'motoroutputvoltageid',
      visible: brandIsntGTAEKDSO,
      prettyName: 'Motor Voltage',
      type: 'select',
      rules: [
        {
          required: true,
          message: 'Motor Voltage is required.',
        },
      ],
      width: '350px',
      value: gearmotor.motoroutputvoltageid,
      options: getSelectOptions(
        brandIsDSSA47
          ? filterListByTitles(
              gearmotorList.motoroutputvoltage,
              DSSA47_VALID_TITLES.motoroutputvoltageid
            )
          : gearmotorList.motoroutputvoltage,
        isExternal
      ),
    },
    {
      key: 'mountingtypeid',
      visible: brandIsntGTAEKDSO,
      prettyName: 'Mounting Type',
      type: 'radio',
      rules: [
        {
          required: true,
          message: 'Mounting Type is required.',
        },
      ],
      options: brandIsDSSA47
        ? filterListByTitles(
            gearmotorList.eurodrive.selection.mountingtypes,
            DSSA47_VALID_TITLES.mountingtypeid
          )
        : gearmotorList.eurodrive.selection.mountingtypes,
      value: gearmotor.mountingtypeid,
    },
    {
      key: 'zfanonmotor',
      visible: gearmotor.zfanonmotor && brandIsntGTAEKDSO,
      prettyName: 'Z Fan',
      value: `Automatically included for conveyors due to conveyor speed: <b>${conveyor.speed} ${
        conveyor.unit === 'Metric' ? 'M/Min' : 'Ft/min'
      }</b>`,
      type: 'info',
    },
    {
      key: 'gearboxid',
      visible: isExternal ? brandIsNord || brandIsGTAEKDSO || brandIsSEW  : true,
      prettyName: 'Case Size',
      type: 'select',
      width: '350px',
      placeholder: 'Select a Case Size',
      rules: [
        {
          required: true,
          message: 'Case Size is required.',
        },
      ],
      options: getSelectOptions(
        filterListByTitles(
          gearmotorList.gearboxes,
          (() => {
            if (brandIsSEW) {
              if (!mountingTypeIsRemote)
                return SEW_VALID_TITLES_SHAFT.gearboxid
              else 
                return SEW_VALID_TITLES.gearboxid
            }
            if (brandIsGTAEKDSO) {
                return TAEKDS_VALID_TITLES.gearboxid
            }
            if (brandIsDSSA47) return DSSA47_VALID_TITLES.gearboxid
            if (brandIsNord) return NORD_VALID_TITLES.gearboxid
            return Object.values(gearmotorList.gearboxes).map((li) => li.gear)
          })()
        ),
        isExternal
      ),
      value: brandIsNord ? Object.values(gearmotorList?.gearboxes).find((li) => li.gear === NORD_VALID_TITLES.gearboxid[0])?.id : conveyor.gearboxid,
    },
    {
      key: 'vaconxseriesinverterid',
      type: 'select',
      prettyName: 'Horsepower',
      placeholder: 'Select Horsepower',
      width: '350px',
      rules: [
        {
          required: true,
          message: 'Horsepower is required.',
        },
      ],
      visible: brandIsntGTAEKDSO,
      value: gearmotor.vaconxseriesinverterid,
      options: getSelectOptions(
        brandIsDSSA47
          ? filterListByTitles(
              gearmotorList.eurodrive.variablespeed.Inverter,
              DSSA47_VALID_TITLES.vaconxseriesinverterid
            )
          : gearmotorList.eurodrive.variablespeed.Inverter,
        isExternal,
        'numericvalue'
      ),
    },
    {
      key: 'variablespeedoptionsid',
      visible: brandIsntGTAEKDSO,
      prettyName: 'Variable Frequency Drive',
      type: 'radio',
      options: getRadioOptions(gearmotorList.eurodrive.variablespeed.options, isExternal),
      value: gearmotor.variablespeedoptionsid,
    },
    {
      key: 'inputvoltage',
      visible: !VFDIsNone && brandIsntGTAEKDSO,
      prettyName: 'VFD Input Voltage',
      type: 'radio',
      rules: [
        {
          required: true,
          message: 'VFD Input Voltage is required.',
        },
      ],
      options: getRadioOptions(gearmotorList.eurodrive.variablespeed.InputVoltage, isExternal),
      value: gearmotor.inputvoltage,
    },
  ]

  if (vfdselection) gearmotorConfig.push(vfdselection)

  return gearmotorConfig
}
