import store, { AppThunk } from 'utils/../reduxStore'
import _ from 'lodash'
import { AsyncThunk, createAction } from '@reduxjs/toolkit'
import { batch } from 'react-redux'
import ListService from 'utils/api/list/ListService'
import { setLoading, setModalContent, openConfirmModal } from './ScreenActions'
import { formatIdAsKey } from 'utils/helpers'
import {
  ISytelineResponse,
  IPriceConfigrationResponse,
  ICustomer,
  IContact,
  IUserDetail,
} from 'shared/types/swagger'
import { IListState } from './ListReducer'
import { saveListsSettings } from '../../features/Project/redux/ProjectActions'

export const saveLists = createAction<Partial<Record<keyof IListState, unknown>>>('SAVE_LISTS')
export const setUnsavedCostUpdate = createAction<{ string: ISytelineResponse }>(
  'SET_UNSAVED_COST_UPDATE'
)

export const createUser = (payload) => async (dispatch) => {
  const users = _.cloneDeep(store.getState().ListReducer.users)

  return new Promise((resolve, reject) => {
    return ListService.createUser(payload)
      .then((user) => {
        const {
          password,
          passwordhash,
          normalizedusername,
          securitystamp,
          ...informationToKeep
        } = user

        dispatch(
          saveLists({
            users: {
              [user.id]: informationToKeep,
              ...users,
            },
          })
        )
        dispatch(setModalContent(null))
        resolve(user.id)
      })
      .catch((e) => {
        console.log(e)
        reject(e?.data?.errorlist?.[0]?.message)
        throw e
      })
  })
}

export const deleteUser = (userId, external = false) => async (dispatch) => {
  const listName = external ? 'externalUsers' : 'users'
  return ListService.deleteUser(userId)
    .then((res) => {
      const users = _.cloneDeep(store.getState().ListReducer[listName])
      delete users[userId]
      dispatch(saveLists({ [listName]: users }))
    })
    .catch((e) => {
      console.log(e)
    })
}

export const updateUser = (userId, payload, external = false) => async (dispatch) => {
  return new Promise((resolve, reject) => {
    const listName = external ? 'externalUsers' : 'users'
    const users = _.cloneDeep(store.getState().ListReducer[listName])
    const usersWithUpdatedUser = {
      ...users,
      [userId]: {
        ...users[userId],
        ...payload,
      },
    }
    dispatch(saveLists({ [listName]: usersWithUpdatedUser }))
    const internalOrExternalUser = _.cloneDeep(payload)
    if (!external && !internalOrExternalUser.roles.includes('InternalUser')) {
      internalOrExternalUser.roles.push('InternalUser')
    }
    return ListService.updateUser(userId, internalOrExternalUser)
      .then((res) => {
        dispatch(
          openConfirmModal({
            bodyText: 'Updated Successfully!',
            cancelButton: false,
            confirmButtonText: 'OK',
          })
        )
        resolve(true)
      })
      .catch((e) => {
        dispatch(saveLists({ [listName]: users }))
        dispatch(
          openConfirmModal({
            bodyText: 'Could not update user due to server error',
            cancelButton: false,
            confirmButtonText: 'OK',
          })
        )
        debugger
        throw e
      })
  })
}

export const getUsersList = (searchTermInternalUsers = "") => async (dispatch) => {
  return new Promise((resolve, reject) => {
    return ListService.internalUsers(searchTermInternalUsers)
      .then((users) => {
        batch(() => {
          dispatch(saveLists({ users, searchTermInternalUsers }))
          dispatch(setLoading(false))
        })
        resolve(users)
      })
      .catch((e) => {
        reject(e)
        debugger
        throw e
      })
  })
}

export const getExternalUsersList = (searchTermExternalUsers = "") => async (dispatch) => {
  return new Promise((resolve, reject) => {
    return ListService.externalUsers(searchTermExternalUsers)
      .then((externalUsers) => {
        batch(() => {
          dispatch(saveLists({ externalUsers, searchTermExternalUsers }))
          dispatch(setLoading(false))
        })
        resolve(externalUsers)
      })
      .catch((e) => {
        reject(e)
        debugger
        throw e
      })
  })
}

export const getPeopleLists = (): AppThunk => async (dispatch) => {
  const accountManagers = ListService.accountManagers()
  const estimators = ListService.estimators()
  const designers = ListService.designers()
  return Promise.all([accountManagers, estimators, designers]).then((data) => {
    const payload = {
      accountManagers: data[0],
      estimators: data[1],
      designers: data[2],
    }
    dispatch(saveLists(payload))
  })
}

export const getGearBoxes = (): AppThunk => async (dispatch) => {
  const gearboxes = await ListService.gearboxes()
  dispatch(saveLists({ gearmotor: { gearboxes: formatIdAsKey(gearboxes) } }))
}

export const getStaticLists = () => async (dispatch) => {
  const chainsPromise = ListService.chains()
  const statusesPromise = ListService.statuses()
  Promise.all([
    chainsPromise,
    statusesPromise,
  ]).then((data) => {
    const [chains, statuses] = data
    const payload = {
      chains,
      statuses,
    }
    dispatch(saveLists(payload))
  })
}

export const getSettingsLists = () => async (dispatch) => {
  const conveyorSettingsPromiseRegular = ListService.conveyorSettingsRegular()
  const conveyorSettingsPromiseLA = ListService.conveyorSettingsLA()
  const gearboxesPromise = ListService.gearboxes()
  Promise.all([
    conveyorSettingsPromiseRegular,
    conveyorSettingsPromiseLA,
    gearboxesPromise,
  ]).then((data) => {
    const [conveyorSettingsRegular, conveyorSettingsLA, gearboxes] = data
    const payload = {
      regularProjectSettings:conveyorSettingsRegular,
      laProjectSettings:conveyorSettingsLA,
    } 
    payload.regularProjectSettings.gearmotor.gearboxes = formatIdAsKey(gearboxes)
    payload.laProjectSettings.gearmotor.gearboxes = formatIdAsKey(gearboxes)
    dispatch(saveListsSettings(payload))
  })
}

export const getContacts = (customerId) => async (dispatch): Promise<Record<string, IContact>> => {
  try {
    const data = await ListService.contacts(customerId)
    const payload = {
      contacts: data,
    }
    dispatch(saveLists(payload))
    return data
  } catch (error) {
    debugger
    return error
  }
}

export const getCustomerUsers = (customerId) => async (dispatch): Promise<Array<Partial<IUserDetail>>> => {
  try {
    const data = await ListService.customerUsers(customerId)
    const payload = {
      customerUsers: data
    }
    dispatch(saveLists(payload))
    return data
  } catch (error) {
    debugger
    return error
  }
}

export const getCustomer = (customerId) => (dispatch): Promise<ICustomer> => {
  const customers = _.cloneDeep(store.getState().ListReducer.customers)
  return new Promise(async (resolve, reject) => {
    return await ListService.customer(customerId)
      .then((data) => {
        const payload = {
          customers: {
            ...customers,
            [data.id]: data,
          },
        }
        dispatch(saveLists(payload))
        resolve(data)
      })
      .catch((e) => {
        reject(e)
        debugger
      })
  })
}

export const searchCustomers = (searchTerm) => async (dispatch) => {
  return new Promise(async (resolve, reject) => {
    return await ListService.searchCustomers(searchTerm)
      .then((data) => {
        const payload = {
          customers: data,
        }
        dispatch(saveLists(payload))
        resolve(data)
      })
      .catch((e) => {
        reject(e)
        debugger
      })
  })
}

function formatPriceConfigurationPayload(pcr: IPriceConfigrationResponse) {
  const { mufupdates, generalcost, externalmandatorymufs, externaloptionalmufs } = pcr
  const keyedMufupdates = _.keyBy(
    mufupdates.map((d) => ({ ...d, value: d.value.toString() })),
    (mufcost) => mufcost.muftype
  )
  const keyedLCS = _.keyBy(
    generalcost.laborcostsettings.map((lcs) => ({
      ...lcs,
      laborperhr: lcs.laborperhr.toString(),
      machineperhr: lcs.machineperhr.toString(),
      setupperhr: lcs.setupperhr.toString(),
    })),
    (lcs) => lcs.id
  )
  return {
    mufupdates: keyedMufupdates,
    generalcost: {
      ...generalcost,
      laborcostsettings: keyedLCS,
    },
    externalmandatorymufs: { ...externalmandatorymufs },
    externaloptionalmufs: { ...externaloptionalmufs }
  }
}

export const getPriceConfiguration = () => async (dispatch) => {
  dispatch(
    setLoading({
      loading: true,
      loadingMessage: 'Fetching price configuration settings...',
    })
  )
  return new Promise(async (resolve, reject) => {
    try {
      const pcr = await ListService.getPriceConfiguration()
      const formattedPCR = formatPriceConfigurationPayload(pcr)
      dispatch(saveLists(formattedPCR))
      dispatch(setLoading(false))
      resolve(formattedPCR)
    } catch (error) {
      console.log(error)
      dispatch(setLoading(false))
      reject(error)
      debugger
    }
  })
}

export const updatePriceConfiguration = (payload) => async (dispatch) => {
  dispatch(
    setLoading({
      loading: true,
      loadingMessage: 'Saving price configuration settings...',
    })
  )
  return new Promise(async (resolve, reject) => {
    try {
      const pcr = await ListService.updatePriceConfiguration(payload)
      const formattedPCR = formatPriceConfigurationPayload(pcr)
      dispatch(saveLists(formattedPCR))
      dispatch(setLoading(false))
      resolve(formattedPCR)
    } catch (error) {
      console.log(error)
      dispatch(setLoading(false))
      reject(error)
      debugger
    }
  })
}

export const getMiscellanousPartSelection = () => async (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      const data = await ListService.getMiscellanousPartSelection()
      dispatch(saveLists({ miscparts: data }))
      resolve(data)
    } catch (error) {
      console.log(error)
      reject(error)
      debugger
    }
  })
}

export const saveMiscellanousPartSelection = (payload) => async (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      const data = await ListService.saveMiscellanousPartSelection(payload)
      dispatch(saveLists({ miscparts: data }))
      resolve(data)
    } catch (error) {
      console.log(error)
      reject(error)
      debugger
    }
  })
}
