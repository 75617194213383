import _ from 'lodash'
import { connect } from 'react-redux'
import ADP from 'awesome-debounce-promise'
import React, { PureComponent } from 'react'
import { withRouter } from 'react-router-dom'
import { ListManager } from 'react-beautiful-dnd-grid'
import SimpleLink from 'features/Chain/components/chainBuilder/SimpleLink'
import {
  saveCurrentNonStandardRowState,
  updateNonStandardChainRow,
  updateNonStandardRowMeta,
} from 'features/Chain/redux/ChainActions'
import { findLinkFromElementId } from 'features/Chain/redux/helperFunctions'
import { Input, Button } from 'shared/components'
import AvailableLinks from './AvailableLinks'
import Header from './Header'
import RowSteps from './Steps'
import { setModalContent, setModalData } from 'shared/redux/ScreenActions'
import { captureSentryError } from 'utils/helpers'

class ChainBuilderRow extends PureComponent {
  componentDidMount() {
    const {
      conveyorId,
      leftLink,
      pattern: { centerelements, id },
      rightLink,
      updateNonStandardRowMeta,
      versionId,
    } = this.props
    let adjustedStepIndex

    if (!leftLink) {
      adjustedStepIndex = 0
    } else if (!centerelements.length) {
      adjustedStepIndex = 1
    } else if (!rightLink) {
      adjustedStepIndex = 2
    }

    if (adjustedStepIndex !== undefined) {
      updateNonStandardRowMeta({
        conveyorId,
        metaInformation: {
          currentStepIndex: adjustedStepIndex,
        },
        versionId,
        nonStdRowId: id,
      })
    }
  }

  repeatRowRequest = (repeatNum) => {
    if (repeatNum !== 0) {
      const { conveyorId, pattern, updateNonStandardChainRow } = this.props
      updateNonStandardChainRow(conveyorId, pattern.id, {
        ...pattern,
        repeat: repeatNum,
      })
    }
  }

  debouncedRepeatRowRequest = ADP(this.repeatRowRequest, 700)

  changeLinkTab = (idx) => {
    const { conveyorId, versionId, updateNonStandardRowMeta } = this.props

    updateNonStandardRowMeta({
      conveyorId,
      metaInformation: {
        currentStepIndex: idx,
      },
      versionId,
      nonStdRowId: this.props.pattern.id,
    })
  }

  renderNavigationButtons = () => {
    const {
      availableSteps,
      conveyorId,
      currentStep,
      currentStepIndex = 0,
      isactive,
      islocked,
      pattern,
      leftLink,
      rightLink,
      saveCurrentNonStandardRowState,
      updateNonStandardRowMeta,
      versionId,
    } = this.props

    const isFirstStep = currentStepIndex === 0
    const isLastStep = currentStepIndex === availableSteps.length - 1

    return (
      <div className="chainbuilder-navigation">
        {isFirstStep || isLastStep ? (
          <div></div>
        ) : (
          <Button
            className="nsc-nav-previous"
            small
            secondary
            text={`Back: ${_.capitalize(availableSteps[currentStepIndex - 1])} Links`}
            onClick={() => this.changeLinkTab(currentStepIndex - 1)}
          />
        )}
        <div className="bottom-builderbox">
          <Input
            defaultValue={pattern.repeat}
            label="Number Per Pattern Repeat"
            small
            type="number"
            width="55px"
            onChange={(e) => this.debouncedRepeatRowRequest(Number(e))}
          />
        </div>
        <Button
          className="nsc-nav-next"
          small
          disabled={
            (currentStep === 'left' && !leftLink) ||
            (currentStep === 'center' && !pattern.centerelements.length) ||
            (currentStep === 'right' && (!leftLink || !pattern.centerelements.length || !rightLink))
          }
          text={
            isLastStep
              ? 'Finish Row'
              : `Next: ${_.capitalize(availableSteps[currentStepIndex + 1])} Links`
          }
          onClick={() => {
            if (isactive && !islocked) {
              if (isLastStep) {
                saveCurrentNonStandardRowState(conveyorId, pattern.id)
                updateNonStandardRowMeta({
                  conveyorId,
                  metaInformation: {
                    currentStepIndex: 0,
                    open: false,
                  },
                  versionId,
                  nonStdRowId: pattern.id,
                })
              } else {
                this.changeLinkTab(currentStepIndex + 1)
              }
            }
          }}
        />
      </div>
    )
  }

  render() {
    if (this.props.loading) {
      return <div>bleeeeh</div>
    }
    const {
      addLinkFromClick,
      availableSteps,
      chainId,
      chainMetaInformation,
      currentStep,
      setModalData,
      setModalContent,
      conveyorId,
      deleteLink,
      firstRowWidth,
      isactive,
      islocked,
      onDragEnd,
      pattern,
      leftLink,
      rightLink,
      rowIndex,
      selectedChainSeries,
      totalWidth,
      unitSuffix,
      widthDifference,
    } = this.props

    return (
      <div className="chainbuilder-row">
        <Header
          chainId={chainId}
          chainRowMetaInformation={chainMetaInformation}
          conveyorId={conveyorId}
          firstRowWidth={firstRowWidth}
          pattern={pattern}
          rowIndex={rowIndex}
          totalWidth={totalWidth}
          unitSuffix={unitSuffix}
          widthDifference={widthDifference}
        />
        <div
          className="chainbuilder-inner-row"
          style={{ display: chainMetaInformation.open ? 'flex' : 'none' }}
        >
          <div className="top-builderbox">
            <RowSteps
              conveyorId={conveyorId}
              availableSteps={availableSteps}
              currentStep={currentStep}
              pattern={pattern}
            />
            <div title="Chain Preview">
              {/* The left link spot */}
              <SimpleLink
                disabled={availableSteps.indexOf('left') === -1}
                active={currentStep === 'left'}
                linkTitle="Left" // this is for the message when it is empty
                className={`leftlink ${
                  currentStep !== 'left' || pattern.centerelements.length > 0 ? 'show-border' : ''
                }`}
                topLink
                selectedChainSeries={selectedChainSeries}
                currentStep={currentStep}
                link={leftLink}
                onClick={() => {
                  deleteLink('', 'left', pattern)
                }}
              />
              {/* The drag and drop section */}
              <div className="horizontal-list">
                <ListManager
                  items={pattern.centerelements}
                  direction="horizontal"
                  maxItems={19}
                  render={(item) => (
                    <SimpleLink
                      className="centerlink"
                      active={currentStep === 'center'}
                      topLink
                      selectedChainSeries={selectedChainSeries}
                      currentStep={currentStep}
                      item={item}
                      link={findLinkFromElementId(selectedChainSeries, item.elementid)}
                      onClickDelete={() => {
                        deleteLink(item, 'center', pattern)
                      }}
                    />
                  )}
                  onDragEnd={(oldIndex, newIndex) => {
                    onDragEnd(oldIndex, newIndex, pattern)
                  }}
                />
              </div>
              {/* The right link spot */}
              <SimpleLink
                disabled={availableSteps.indexOf('right') === -1}
                active={currentStep === 'right'}
                linkTitle="Right" // this is for the message when it is empty
                className={`rightlink ${currentStep === 'right' || rightLink ? 'show-border' : ''}`}
                topLink
                selectedChainSeries={selectedChainSeries}
                currentStep={currentStep}
                link={rightLink}
                onClick={() => {
                  deleteLink('', 'right', pattern)
                }}
              />
            </div>
            {/* The section for clicking links to add */}
            {isactive && !islocked ? (
              <AvailableLinks
                conveyorId={conveyorId}
                availableSteps={availableSteps}
                selectedChainSeries={selectedChainSeries}
                currentStep={currentStep}
                render={(item, i) => (
                  <SimpleLink
                    key={i}
                    bottomlink
                    originalPattern={pattern}
                    onClick={async (link, spot, chainPattern) => {
                      if (link?.id === 72) {
                        setModalData({ link, spot, chainPattern, addLinkFromClick })
                        setModalContent('tubeWidth')
                      } else {
                        addLinkFromClick(link, spot, chainPattern)
                      }
                    }}
                    currentStep={currentStep}
                    link={item}
                    selectedChainSeries={selectedChainSeries}
                  />
                )}
              />
            ) : null}

            {this.renderNavigationButtons()}
          </div>
        </div>
      </div>
    )
  }
}

/** @param {import('srcReducer').Store} state */
const mapStateToProps = (state, props) => {
  try {
    const { ConveyorReducer, ChainReducer, ListReducer, ProjectReducer } = state
    const {
      rowIndex,
      availableSteps,
      conveyorId,
      chainId,
      addLinkFromClick,
      onDragEnd,
      deleteLink,
      unitSuffix,
      match,
    } = props
    const {
      params: { versionId },
    } = match

    if (!ChainReducer?.[versionId]?.[conveyorId]?.nonStandardRowMeta) {
      return {
        loading: true,
      }
    }

    const conveyor = ConveyorReducer.conveyors[conveyorId]
    const pattern = conveyor.chain.chainpatterns[rowIndex]
    const metaInformation = ChainReducer[versionId][conveyorId].nonStandardRowMeta[pattern.id]
    const selectedChainSeries = ListReducer.chains[conveyor.chain.chainserieid]
    const { isactive, islocked } = ProjectReducer.versions[versionId]
    const leftLink = findLinkFromElementId(selectedChainSeries, pattern.leftelementid)
    const rightLink = findLinkFromElementId(selectedChainSeries, pattern.rightelementid)
    const { currentStepIndex } = metaInformation

    return {
      unitSuffix,
      rowIndex,
      totalWidth: metaInformation.totalWidth,
      widthDifference: metaInformation.widthDifference,
      pattern,
      chainId,
      onDragEnd,
      deleteLink,
      addLinkFromClick,
      currentStep: availableSteps[currentStepIndex] || 'left',
      currentStepIndex,
      firstRowWidth: metaInformation.firstRowWidth,
      selectedChainSeries,
      leftLink,
      rightLink,
      chainMetaInformation: ChainReducer[versionId][conveyorId].nonStandardRowMeta[pattern.id],
      isactive,
      islocked,
      versionId,
    }
  } catch (error) {
    captureSentryError(error, state)
  }
}
const mapDispatchToProps = {
  saveCurrentNonStandardRowState,
  updateNonStandardChainRow,
  updateNonStandardRowMeta,
  setModalData,
  setModalContent,
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ChainBuilderRow))
