import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Button } from 'shared/components'
import ExternalUsersTable from 'features/Admin/components/ExternalUsersTable'
import './ExternalUserManagementScreen.scss'
import { setModalContent } from 'shared/redux/ScreenActions'
import { captureSentryError } from 'utils/helpers'
import queryString from 'query-string'

class ExternalUserManagementScreen extends Component {
  componentDidMount() {
    const { admin, history, setModalContent } = this.props
    if (!admin) {
      history.push('/')
    }
    else
    {
      const search = sessionStorage.getItem('queryStringCreateAction')
      const values = queryString.parse(search)
      const { action } = values

      if (action === 'create')
      {
        setModalContent('addExternalUser')
      }
    }
  }

  render() {
    const { setModalContent } = this.props

    return (
      <div id="ext-user-management">
        <div className="ext-user-management__title">
          <h1>External User Management</h1>
          <Button
            small
            text="Add a New External User"
            onClick={() => setModalContent('addExternalUser')}
            data-testid="openeummodal"
          />
        </div>
        <ExternalUsersTable />
      </div>
    )
  }
}

/** @param {import('srcReducer').Store} state */
const mapStateToProps = (state) => {
  try {
    return {
      admin: state.UserReducer.roles.includes('Admin'),
    }
  } catch (error) {
    captureSentryError(error, state)
  }
}

const mapDispatchToProps = { setModalContent }

export default connect(mapStateToProps, mapDispatchToProps)(ExternalUserManagementScreen)
