import _ from 'lodash'
import { Tabs } from 'antd'
import { connect } from 'react-redux'
import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import {
  updateDefaultMaterialAcessoriesMetaData,
  cloneMaterialOrAccessoryWithSidesDefaults,
} from 'features/Estimator/redux/EstimatorActions'
import { updateSidewalls } from 'features/Estimator/components/Accessories/redux/default/SideWallsActions'
import { DefaultHeader } from 'features/Estimator/components/shared/Headers'
import { Radio, InputWithValidation, Icon } from 'shared/components'
import colors from 'shared/constants/colors'
import { setLoading } from 'shared/redux/ScreenActions'
import SidewallsConfig from './SideWallsConfig'
import './GuideRails/GuideRails.scss'
import './SideWalls.scss'
import { captureSentryError } from 'utils/helpers'

const { TabPane } = Tabs

/**
 * @typedef {import('react-redux').ConnectedProps<connector>} SidewallsConnectProps
 *
 * @typedef {Object} Props
 */

/** @extends {Component<Props & SidewallsConnectProps>} */
class Sidewalls extends Component {
  state = {
    copiedFromSide: null,
  }

  renderFields = (sideId, sideName) => {
    const { isactive, islocked } = this.props
    const { copiedFromSide } = this.state
    const config = SidewallsConfig(this.props, sideId, sideName)
    const otherSide = sideName === 'left' ? 'right' : 'left'

    return (
      <>
        {(() => {
          if (copiedFromSide === sideName)
            return (
              <div className="values-copied-to">
                <Icon icon="check" color={colors.navy} size={14} />
                <div>Values applied to {otherSide} side</div>
              </div>
            )
          if (copiedFromSide)
            return (
              <div className="values-copied-from">
                <Icon icon="infoOutline" color={colors.novaBlue} size={16} />
                <div className="text">
                  <strong>These values were copied from the {otherSide} side.</strong> Editing the
                  values below will means they will no longer be identical to the {otherSide} side.
                </div>
              </div>
            )
          return null
        })()}
        {isactive && !islocked ? this.renderCopyLeftRightButton(sideId, sideName) : null}
        {_.map(config, (fieldConfig, i) => {
          const { dataKey, prettyName, options, rules, vertical, type } = fieldConfig
          const {
            sidewallsData,
            updateSidewalls,
            conveyorId,
            sidewallsOptions: { materials },
          } = this.props
          if (type === 'radio') {
            const { image } = materials[Object.keys(materials)[0]]
            return (
              <div key={i} style={{ display: 'flex', alignItems: 'center' }}>
                <Radio
                  key={'default-materials-sidewalls-' + sideName + this.props.conveyorId}
                  disabled={!isactive || islocked}
                  dataKey={dataKey}
                  prettyName={prettyName}
                  margin="0 0 10px 0"
                  options={options}
                  onChange={(val) => {
                    this.setState({ copiedFromSide: null })
                    updateSidewalls({
                      conveyorId,
                      sideId,
                      sideName,
                      updatedSidewallsFields: { [dataKey]: val },
                    })
                  }}
                  rules={rules}
                  vertical={vertical}
                  initialValue={_.get(sidewallsData[sideName], dataKey)}
                  doNotShowImages
                />
                <img style={{ marginLeft: '40px' }} src={image} alt="mounting location" />
              </div>
            )
          }
          if (type === 'distancetoc') {
            return this.renderSidewallDistanceTOC(sideName, sideId)
          }
          return null
        })}
      </>
    )
  }

  renderSidewallDistanceTOC = (sideName, sideId) => {
    const { sidewallsData, conveyorId, updateSidewalls, isactive, islocked, isMetric } = this.props
    return (
      <span key={sideId}>
        <InputWithValidation
          label="Distance TOC to Top of Side Wall"
          placeholder="Enter Distance TOC"
          disabled={!isactive || islocked}
          id="distancetoc"
          name="distancetoc"
          small
          required
          addonAfter={isMetric ? 'mm' : 'in'}
          containerStyle={{
            width: '200px',
          }}
          rules={[
            {
              type: 'number',
              transform: (val) => Number(val),
              min: 0,
              message: 'must be a number greater than 0',
            },
          ]}
          type="text"
          defaultValue={sidewallsData[sideName].distancetoc}
          onChange={(value) => {
            this.setState({ copiedFromSide: null })
            updateSidewalls({
              conveyorId,
              updatedSidewallsFields: { distancetoc: value },
              sideName,
              sideId,
            })
          }}
        />
      </span>
    )
  }

  renderCopyLeftRightButton = (sideId, sideName) => {
    const {
      conveyorId,
      cloneMaterialOrAccessoryWithSidesDefaults,
      sidewallsData,
      setLoading,
    } = this.props
    const otherSide = sideName === 'left' ? 'Right' : 'Left'
    return (
      <div
        className="copy-guiderails-button"
        onClick={() => {
          setLoading({ loading: true, loadingMessage: 'Copying Values...' })
          this.setState({ copiedFromSide: sideName })
          cloneMaterialOrAccessoryWithSidesDefaults({
            conveyorId: conveyorId,
            materialKey: 'sidewalls',
            type: 'accessories', // 'materials' or 'accessories'
            fromSideId: sideId,
            toSideId: sidewallsData[sideName === 'left' ? 'right' : 'left'].id,
            sideName,
            toSideName: sideName === 'left' ? 'right' : 'left',
          })
          setTimeout(() => this.setState({ copiedFromSide: null }), 10000)
        }}
      >
        <Icon icon="clone" color={colors.novaBlue} size={14} />
        <div className="copy-guiderails-button__text">Copy Values to {otherSide} Side</div>
      </div>
    )
  }

  render() {
    if (this.props.loading) {
      return null
    }
    const {
      sidewallsData: {
        left: { parameteractive: leftChecked, id: leftId },
        right: { parameteractive: rightChecked, id: rightId },
      },
      sidewallsMeta: { open },
      updateSidewalls,
      conveyorId,
      updateDefaultMaterialAcessoriesMetaData,
    } = this.props
    return (
      <div className="material-accessory-item">
        <DefaultHeader
          conveyorId={conveyorId}
          type="accessories"
          fieldKey="sidewalls"
          leftCheck={{
            leftId,
            leftChecked,
          }}
          rightCheck={{
            rightId,
            rightChecked,
          }}
          isLeftRight
          onClickCheck={(e, sideId, sideName) => {
            this.setState({ copiedFromSide: null })
            if (e.target.checked) {
              updateDefaultMaterialAcessoriesMetaData({
                conveyorId,
                type: 'accessories',
                name: 'sidewalls',
                updatedFields: {
                  open: true,
                },
              })
            } else {
              updateDefaultMaterialAcessoriesMetaData({
                conveyorId,
                type: 'accessories',
                name: 'sidewalls',
                updatedFields: {
                  open: (leftChecked || rightChecked) && open,
                },
              })
            }
            const parameteractive = sideName === 'left' ? !leftChecked : !rightChecked
            updateSidewalls({
              conveyorId,
              sideId,
              sideName,
              updatedSidewallsFields: { parameteractive },
            })
          }}
          checked={leftChecked || rightChecked}
          open={open}
          onClickOpen={() => {
            updateDefaultMaterialAcessoriesMetaData({
              conveyorId,
              type: 'accessories',
              name: 'sidewalls',
              updatedFields: {
                open: !open,
              },
            })
          }}
          title="Side Walls"
        >
          <Tabs onChange={() => {}} type="card">
            {leftChecked && (
              <TabPane style={{ margin: '4px' }} tab="Left" key={`sidetab-${leftId}`}>
                {this.renderFields(leftId, 'left')}
              </TabPane>
            )}
            {rightChecked && (
              <TabPane style={{ margin: '4px' }} tab="Right" key={`sidetab-${rightId}`}>
                {this.renderFields(rightId, 'right')}
              </TabPane>
            )}
          </Tabs>
        </DefaultHeader>
      </div>
    )
  }
}

/** @param {import('srcReducer').Store} state */
const mapStateToProps = (state, props) => {
  try {
    const {
      ConveyorReducer,
      EstimatorReducer,
      EstimatorMetaReducer,
      ProjectReducer,
    } = state
    const {
      conveyorId,
      match: {
        params: { versionId },
      },
    } = props

    const sidewallsData =
      EstimatorReducer[conveyorId] &&
      EstimatorReducer[conveyorId].accessories &&
      EstimatorReducer[conveyorId].accessories.sidewalls
    const sidewallsMeta =
      EstimatorMetaReducer[conveyorId].materialsAccessoriesMetadata.accessories.sidewalls

    if (!sidewallsData) {
      return { loading: true }
    }

    const { isactive, islocked } = ProjectReducer.versions[versionId]
    const isMetric = ConveyorReducer.conveyors[conveyorId].unit === 'Metric'

    return {
      versionId,
      conveyorId,
      sidewallsMeta,
      sidewallsData,
      sidewallsOptions: ProjectReducer.currentlistsettings.accessories.sidewalls,
      isactive,
      islocked,
      isMetric,
    }
  } catch (error) {
    captureSentryError(error, state)
  }
}

const connector = connect(mapStateToProps, {
  cloneMaterialOrAccessoryWithSidesDefaults,
  updateSidewalls,
  updateDefaultMaterialAcessoriesMetaData,
  setLoading,
})

export default withRouter(connector(Sidewalls))
