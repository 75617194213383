import { createReducer } from '@reduxjs/toolkit'
import { PURGE } from 'redux-persist'
import {
  IMUFUpdateResponse,
  IMiscellanousPartSelection,
  IGeneralCost,
  IChainSerie,
  ICustomer,
  IContact,
  IUserDetail,
  IStatus,
  IExternalSettings
} from 'shared/types/swagger'

export interface IListState {
  chains: Record<string, IChainSerie>
  accountManagers: Array<IUserDetail>
  estimators: Array<IUserDetail>
  designers: Array<IUserDetail>
  customers: Record<string, ICustomer>
  customerUsers: Array<Partial<IUserDetail>>
  contacts: Record<string, IContact>
  users: Record<string, IUserDetail>
  searchTermInternalUsers: string,
  externalUsers: Record<string, IUserDetail>
  searchTermExternalUsers: string,
  statuses: Array<IStatus>
  mufupdates: Record<string, IMUFUpdateResponse>
  generalcost: Partial<IGeneralCost>,
  miscparts: Partial<IMiscellanousPartSelection>,
  externalMandatoryMUFs: Partial<IExternalSettings>,
  externalOptionalMUFs: Partial<IExternalSettings>
}

const INITIAL_STATE: IListState = {
  // Conveyor
  chains: {},

  // Users
  accountManagers: [],
  estimators: [],
  designers: [],
  customers: {},
  customerUsers: [],
  contacts: {},
  users: {},
  searchTermInternalUsers: '',
  externalUsers: {},
  searchTermExternalUsers: '',

  // Project
  statuses: [],

  mufupdates: {},
  generalcost: {},
  miscparts: {},
  externalMandatoryMUFs: {},
  externalOptionalMUFs: {},
}

export { INITIAL_STATE }

export default createReducer(INITIAL_STATE, {
  SAVE_LISTS: (state, action) => {
    return {
      ...state,
      ...action.payload,
    }
  },
  [PURGE]: () => INITIAL_STATE,
})
