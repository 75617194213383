import React, { ReactNode, useEffect, useRef } from 'react'
import { Row, Col, Radio, Form as AntForm, Input } from 'antd'
import { Button, Form } from 'shared/components'
import addExternalUserConfig from './addExternalUserConfig'
import './AddExternalUser.scss'
import { createUser } from 'shared/redux/ListActions'
import {
  customerInfoConfig,
  contactInfoConfig,
} from 'features/Project/components/ProjectDetails/projectDetailsConfig'
import ProjectForm from 'features/Project/components/ProjectForm'
import ModalContent from 'features/Modal/components/ModalContent'
import { setModalContent, openConfirmModal } from 'shared/redux/ScreenActions'
import { DerivedUser, getDerivedUser } from '../ExternalUserDetail/helpers/getDerivedUser'
import { useDispatch } from 'react-redux'
import queryString from 'query-string'
import { getContacts, getCustomer } from 'shared/redux/ListActions'

interface Props {
  form: AntForm['props']['form']
}

function AddExternalUser({ form }: Props) {
  const search = sessionStorage.getItem('queryStringCreateAction')
  const values = queryString.parse(search)
  const { username, firstname, lastname, companyname, customerid, contactid } = values
  sessionStorage.removeItem('queryStringCreateAction')

  const [_user, setUser] = React.useState<DerivedUser>(getDerivedUser({
    key: '',
    firstname: '',
    lastname: '',
    email: username,
    username: username,
    roles: '',
    laproject: false,
    regularproject: false,
    customerid: customerid ? parseInt(customerid) : undefined,
    contactid: contactid ? parseInt(contactid) : undefined,
    customer: undefined,
    contact: undefined,
  }))
  const [errors, setErrors] = React.useState<boolean>(false)
  const [loading, setLoading] = React.useState<boolean>(false)
  const dispatch = useDispatch()

  const customerInfo = useRef<AntForm>(null)
  const contactInfo = useRef<AntForm>(null)

  const validateFields = () =>
    new Promise((resolve, reject) => {
      form.validateFields((errors, values) => {
        if (errors) {
          setErrors(true)
          reject()
        } else {
          setErrors(false)
          resolve(true)
        }
      })
    })

    useEffect(() => {
      if (customerid) {
        dispatch(getCustomer(customerid))
        dispatch(getContacts(customerid))
      }
    }, [customerid, dispatch])
  
  
  async function handleSubmit () {
    let errors = false
    customerInfo.current.props.form.validateFieldsAndScroll((err) => {
      errors = Boolean(err)
    })
    contactInfo.current.props.form.validateFields((err) => {
      errors = Boolean(err)
    })

    if (errors) return

    const payload = form.getFieldsValue()
    const contact = contactInfo.current.props.form.getFieldsValue()

    if (contact.emailaddress != payload.username)
    {
      dispatch(
        openConfirmModal({
          headerText: 'Attention',
          bodyText: 'You have entered a username that is different from the contact email address. Are you sure ?',
          onConfirm:completeSubmit,
          confirmButtonText: 'Yes',
          cancelButtonText: 'No, Cancel'
        })
      )
    }
    else
    {
      completeSubmit ();
    }
  }

  async function completeSubmit () {
    const payload = form.getFieldsValue()
    const contact = contactInfo.current.props.form.getFieldsValue()

    payload.customerid = customerInfo.current.props.form.getFieldsValue().customerid
    payload.contactid = contact.contactid
    payload.firstname = contact.firstname
    payload.lastname = contact.lastname
    payload.emailaddress = contact.emailaddress
    payload.roles = ['ExternalUser']

    payload.regularproject=payload.projecttype == 'regular' || payload.projecttype == 'all'
    payload.laproject=payload.projecttype == 'la' || payload.projecttype == 'all'

    delete payload.password_check
    delete payload.projecttype

    await dispatch(createUser(payload))
  }

  function resetFields(form) {
    if (form === 'customerid') {
      setUser((u) => ({ ...u, contactid: undefined }))
        customerInfo.current.props.form.setFieldsValue({
          name: '',
          number: '',
          streetaddress1: '',
          streetaddress2: '',
          city: '',
          state: '',
          zipcode: '',
          phonenumber: '',
          faxnumber: '',
        })
      }
    else if (form === 'contact') {
      setUser((u) => ({ ...u, contactid: undefined }))
      contactInfo.current.props.form.setFieldsValue({
        firstname: '',
        lastname: '',
        phonenumber: '',
        emailaddress: '',
        contactid:''
      })
    }
  }

  function renderUserInfoFields(): Array<ReactNode>  {
    const { getFieldDecorator, setFieldsValue } = form
    const config = addExternalUserConfig(_user.email, _user.password)

    return config.map((field, i) => {
      return (  
        <Col span={field.cols || 12} key={i}>
          {(() => {
            switch (field.type) {
              case 'input': {
                return (
                  <Form.Item label={field.label}>
                    {getFieldDecorator(field.key, {
                      initialValue: field.value,
                      rules: field.rules,
                    })(
                      <Input
                        placeholder={field.placeholder}
                        type={field.key.includes('password') ? 'password' : 'text'}
                        onChange={async (e) => {
                          e.persist()
                          setUser((u) => ({ ...u, [field.key]: e.target.value }))
                          await setFieldsValue({ [field.key]: e.target.value })
                          validateFields()
                        }}
                      />
                    )}
                  </Form.Item>
                )
              }
              case 'radio': {
                return (
                  <Form.Item label={field.label}>
                    {getFieldDecorator(field.key, { initialValue: field.value })(
                      <Radio.Group
                        name={field.key}
                        onChange={async (e) => {
                          const {
                            target: { value },
                          } = e
                          const optionValues = field?.options?.map((opt) => opt.value) || []
                          const isStringBool =
                            optionValues.includes('true') && optionValues.includes('false')
                          const targetValue= isStringBool ? value === 'true' : value
                          setUser((u) => ({ ...u, [field.key]: targetValue }))
                          await setFieldsValue({ [field.key]: targetValue })
                        }}
                        options={field.options}
                      />
                    )}
                  </Form.Item>
                )
              }
              default:
                return null
            }
          })()}
        </Col>
      )
    })
  }
    
  return (
    <ModalContent
      title={<>Add New External User</>}
      id="add-ext-user"
      width="628px"
      height="90vh"
    >
      <ModalContent.Section title="User Info" className="u-mb-4">
        <Form className="add-ext-user__form">
          <Row type="flex" gutter={16} className="ant-row-relative">
            {renderUserInfoFields()}
          </Row>
        </Form>
      </ModalContent.Section>

      <ModalContent.Section title="Customer Info">
        <ProjectForm
          wrappedComponentRef={(node) => {
            customerInfo.current = node
          }}
          config={customerInfoConfig}
          resetFields={resetFields}
          type="user"
          hint={companyname}
          user={_user}
        />
      </ModalContent.Section>

      <ModalContent.Section title="Contact Info">
        <ProjectForm
          wrappedComponentRef={(node) => {
            contactInfo.current = node
          }}
          config={contactInfoConfig}
            keyname='contact'
          resetFields={resetFields}
          type="user"
          hint={firstname+' '+lastname}
          user={_user}
        />
      </ModalContent.Section>

      <ModalContent.Footer>
        <Button small secondary text="Cancel" onClick={() => setModalContent(null)} />
        <Button
          small
  //          disabled={disabled}
          text="Submit"
          onClick={handleSubmit}
          data-testid="submitnewexternaluserbtn"
        />
      </ModalContent.Footer>
    </ModalContent>
  )
}

export default Form.create({ name: 'add-ext-user' })(AddExternalUser)
