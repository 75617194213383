import _ from 'lodash'
import React from 'react'
import { useDispatch } from 'react-redux'
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd'
import {
  updateAllConveyorSections,
} from 'features/Estimator/components/ConveyorBuilder/redux/ConveyorSectionActions'
import { SectionsList } from './components'
import './BuilderList.scss'
import { listStyle } from './helpers'
import { typedUseSelector } from 'utils/helpers'
import { IConveyorSection } from 'shared/types/swagger'

interface Props {
  conveyorId: number
}

function BuilderList(props: Props) {
  const { conveyorId } = props

  const dispatch = useDispatch()
  const conveyor = typedUseSelector((state) => state.ConveyorReducer.conveyors[conveyorId])

  function onDragEnd(result: DropResult): void {
    const sections = reorder(
      Object.values(conveyor.conveyorsections),
      result.source.index,
      result.destination.index
    )
    dispatch(updateAllConveyorSections(conveyor.id, sections))
  }

  function reorder(
    list: Array<IConveyorSection>,
    startIndex: number,
    endIndex: number
  ): Array<IConveyorSection> {
    const result = _.sortBy(list, (s) => s.sectionnumber)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)
    const reorderedSections = result.map((section, index) => {
      return { ...section, sectionnumber: index + 1 }
    })
    return reorderedSections
  }

  return (
    <div
      id={`builder-list-${conveyorId}`}
      className="builder-list"
    >
      <DragDropContext onDragEnd={onDragEnd}>
        <SectionsList conveyor={conveyor} type="Infeed" />
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div {...provided.droppableProps} ref={provided.innerRef} style={listStyle}>
              <SectionsList conveyor={conveyor} />
              {provided.placeholder}
            </div>
          )}
        </Droppable>
        <SectionsList conveyor={conveyor} type="Discharge" />
      </DragDropContext>
    </div>
  )
}

export default BuilderList
