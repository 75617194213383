import React, { ReactElement } from 'react'
import './ProjectSettingsScreen.scss'
import ProjectSettingsForm from 'features/Project/components/ProjectSettings/components/ProjectSettingsForm'
import useProjectSettings from 'features/Project/components/ProjectSettings/hooks/useProjectSettings'

export default function ProjectSettingsScreen(): ReactElement {
  const { loading, projectSettings, handleSave } = useProjectSettings()

  return (
    <main id="pnp" className="pnp">
      <section className="pnp__title">
        <h1>Project settings</h1>
      </section>
      <section className="pnp__body">
        <ProjectSettingsForm values={projectSettings} loading={loading} handleSave={handleSave} />
      </section>
    </main>
  )
}
