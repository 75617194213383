import _ from 'lodash'
import { useDispatch } from 'react-redux'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import {
  cloneVersion,
  deleteVersion,
  setMasterVersion,
  updateVersion,
  flagSoldClonePublishVersion
} from 'features/Project/redux/ProjectOperations'
import { checkProjectLock } from 'features/User/redux/UserActions'
import { selectVersion } from 'features/Version/redux/VersionActions'
import { ActionMenu, Button, Icon, Checkbox } from 'shared/components'
import Colors from 'shared/constants/colors'
import { setModalContent } from 'shared/redux/ScreenActions'
import { showDateTimeEST, displayValueOrDashes, typedUseSelector } from 'utils/helpers'
import './VersionCard.scss'
import { validateProjectAndUpdateTabs } from 'features/Project/components/ProjectSummary/utils/validateProject'
import { ActionMenuOption } from 'shared/components/ActionMenu'
import { IAction, IProject, IVersion } from 'shared/types/swagger'

interface Props {
  version: IVersion
  project: IProject
  handleClickCompare: (value,idVersion,indexVersion) => void
}

function VersionCard(props: Props) {
  const { version, project, handleClickCompare } = props
  const {
    currentstatusname,
    index,
    isactive,
    islocked,
    ismaster,
    lastmodifiedbyname,
    lastmodifieddatetime,
    netprice,
    validated,
    id,
  } = version

  const { versions } = typedUseSelector((state) => state.ProjectReducer)
  const currentVersion = typedUseSelector((state) => state.VersionReducer.versionId)
  const { roles, permissions, userType } = typedUseSelector((state) => state.UserReducer)

  const history = useHistory()
  const [notes, setNotes] = useState(version.notes)
  const dispatch = useDispatch()

  const versionCardActions: ActionMenuOption[] = [
    {
      label: 'Set as Master',
      handleSelect: () => {
        const oldVersionId = _.find(versions, (v) => v.ismaster).id
        dispatch(setMasterVersion(project.id, version.id, oldVersionId))
      },
      icon: 'star',
      className: `set-master-version-menu-action-${project.id}`,
    },
    {
      label: 'Clone Version',
      handleSelect: () => dispatch(cloneVersion({ projectId: project.id, versionId: id })),
      icon: 'clone',
      className: `clone-version-menu-action-${project.id}`,
    },
  ]

  if (permissions.includes('can_delete_user') && !ismaster && id !== currentVersion) {
    versionCardActions.push({
      label: 'Delete Version',
      handleSelect: () => dispatch(deleteVersion(id)),
      icon: 'delete',
      className: `delete-version-menu-action-${project.id}`,
      type: 'warning',
    })
  }

  if (!roles.includes('ExternalUser') 
      && versions[id].authorizedactions != null
      && (versions[id].authorizedactions as Array<IAction>).find((aa)=> aa.name == 'Sold') != null
      && versions[id].currentstatusname != 'Project Published') {
    versionCardActions.push({
      label: 'Flag as sold / Publish clone for Design Review',
      handleSelect: () => dispatch(flagSoldClonePublishVersion({ projectId: project.id, versionId: id, soldActionId: (versions[id].authorizedactions as Array<IAction>).find((aa)=> aa.name == 'Sold').id })),
      icon: 'clone',
      className: `clone-version-menu-action-${project.id}`,
    })
  }

  if (ismaster) {
    versionCardActions.shift()
  }

  const updateNotes = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (version.isactive && !version.islocked) {
      setNotes(e.target.value)
    }
  }

  function renderProjectStatusIcon() {
    return (
      <Icon
        icon="circle"
        size={12}
        svgStyle={{
          stroke: Colors.novaBlue,
          strokeWidth: 2,
          verticalAlign: 'inherit',
          marginRight: '4px',
        }}
      />
    )
  }

  async function selectProjectVersion(e) {
    const { id } = version

    const eventFromActionMenu = (e?.target?.className || '').includes('action-menu')
    if (eventFromActionMenu) return

    const islocked = await dispatch(checkProjectLock(project.id))
    if (!islocked) {
      dispatch(setModalContent(null))
      await dispatch(selectVersion(id))
      validateProjectAndUpdateTabs(id)
      history.push(`/project/${project.id}/${id}`)
    }
  }

  function handleKeyDown(e: React.KeyboardEvent) {
    e.stopPropagation()
    if (e.key === ' ' || e.key === 'Enter') {
      selectProjectVersion(e)
    }
  }

  const [checked, setChecked] = useState(false)

  if (!project) return null

  const disabled = !isactive || islocked

  return (
    <div className="version-modal-card" id={`version-modal-card-${index}`}>
      <div className="header">
        <div className="fields"
          role="button"
          onKeyDown={handleKeyDown}
          id={`version-modal-card-header-${index}`}
          onClick={selectProjectVersion}
        >
          <span className="field">Project: {project.name}</span>
          <span className="version-chip">{`v${index}`}</span>
          {project.isexternalproject && roles.includes('ExternalUser') ? null : (<span className="field">
            {renderProjectStatusIcon()} {currentstatusname}
          </span>)}
          {project.isexternalproject && project.externalprojecttype == 'la' ? null : (<span className="field">
            {`Total Estimate: ${validated ? displayValueOrDashes(netprice) : '$ - - -'}`}
          </span>)}
          <span className="field masterversion-icon">
            {ismaster ? <Icon icon="star" size={22} color={Colors.gold} /> : null}
          </span>
        </div>
        <div className="checkboxcompare">
          {userType !== 'External' && validated 
            ? (<Checkbox
                  dataKey={`v${index}`}
                  label="Compare"
                  checked={checked}
                  onChange={(value) => {
                    setChecked(value);
                    handleClickCompare(value,id,index)
                  }}
               />) 
            : null}
        </div>

        <div className="actions-menu">
          {(roles.includes('Designer')
            || (roles.includes('ExternalUser') 
                && _.find(versions, (v) => v.currentstatusname == 'Sold') != null ))
           ? null 
           : <ActionMenu options={versionCardActions} />}
        </div>
      </div>

      <div className="content">
        {isactive ? null : (
          <div className="expired-quote">
            <Icon icon="caution" color={Colors.bourbon} size={21} />
            <div className="expired-quote__text">
              <strong>Expired Quote.</strong> This version&apos;s price is no longer valid. You may
              only view this version.
            </div>
          </div>
        )}
        <div className="fields">
          <span className="field">
            <label>Last Modified:</label>
            <span>{showDateTimeEST(lastmodifieddatetime)}</span>
          </span>
          <span className="field">
            <label>Last Modified By:</label>
            <span>{lastmodifiedbyname}</span>
          </span>
        </div>
        <div className="notes">
          <div className="label">Version Notes:</div>
          <textarea
            id={`version-modal-notes-${index}`}
            className="body"
            onChange={updateNotes}
            value={notes}
            disabled={disabled}
          ></textarea>
        </div>

        {notes !== version.notes ? (
          <div className="buttons">
            <Button
              id={`version-modal-save-notes-${index}`}
              text="Save"
              extraSmall
              onClick={() => dispatch(updateVersion({ versionId: version.id, payload: { notes } }))}
            />
          </div>
        ) : null}
      </div>
    </div>
  )
}

export default VersionCard
