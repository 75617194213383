import React from 'react'
import ADP from 'awesome-debounce-promise'
import { Icon } from 'shared/components'
import Colors from 'shared/constants/colors'
import { formatCurrency } from 'utils/helpers'
import { isEZGuideOption } from 'features/Version/helpers'

/**
 * @typedef {import('antd/lib/table').ColumnProps} ColumnProps
 *
 * @typedef {Object} ColumnPropsPlus
 * @mixes ColumnProps
 * @property {string} [align]
 * @property {boolean} [editable]
 */

const onChangeFxn = (props, key, optionalpricevalidationbyversionid, optionId, value) => {
  const { form, saveOption } = props
  form.setFieldsValue({ [`${key} ${optionId}`]: value }, () => {
    form.validateFields([`${key} ${optionId}`], (error, row) => {
      if (error) {
        return
      }
      const fieldValues = form.getFieldsValue()
      const payload = {
        quantity: fieldValues[`quantity ${optionId}`],
        cost: fieldValues[`cost ${optionId}`],
        muf: fieldValues[`muf ${optionId}`],
        listprice: fieldValues[`listprice ${optionId}`],
        discountpercentage: fieldValues[`discountpercentage ${optionId}`],
        netprice: fieldValues[`netprice ${optionId}`],
        optionalpricevalidationbyversionid: fieldValues[`optionalpricevalidationbyversionid ${optionId}`],
        materialcost: fieldValues[`materialcost ${optionId}`],
        labhrs: fieldValues[`labhrs ${optionId}`],
        labcost: fieldValues[`labcost ${optionId}`],
        id: fieldValues[`id ${optionId}`],
        pricevalidationoptionname: fieldValues[`pricevalidationoptionname ${optionId}`],
        totalcost: fieldValues[`totalcost ${optionId}`],
      }

      if (fieldValues[`mandatory ${optionId}`] !== true && optionalpricevalidationbyversionid == null) {
        // case Other ?
        payload.details = payload.pricevalidationoptionname
      }
      saveOption(optionId, payload)
    })
  })
}

const debouncedOnChangeFxn = ADP((...args) => onChangeFxn(...args), 800, {
  key: (...args) => `${args[0]}-${args[2]}`,
})

/**
 * @returns {ColumnPropsPlus[]}
 */
export default function config(props) {
  const { deleteOption, isactive, islocked, permissions, isExternalAndHasEZGuideOption, optionalpricevalidationbyversions } = props

  const config = [
    {
      title: 'Global Options',
      dataIndex: 'pricevalidationoptionname',
      editable: (record) => permissions.includes('edit_options') 
                            && record.nameeditable,
      visible: permissions.includes('view_options') || isExternalAndHasEZGuideOption,
      render: (text, row, index) => {
        return {
          children: <div>{row.details || row.pricevalidationoptionname}</div>,
        }
      },
      rules: [
        {
        validator: (rule, value, cb) => {
          try {
            if (value.length <1 || value.length>30)
            {
              throw new Error('Option name length must be between 1 and 30.')
            }
            cb()
          } catch (error) {
            cb(error)
          }
        },
      }],
      onChange: (optionalpricevalidationbyversionid, optionId, value) =>
        debouncedOnChangeFxn(
          props,
          'pricevalidationoptionname',
          optionalpricevalidationbyversionid,
          optionId,
          value
        ),
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      width: '100px',
      align: 'right',
      editable: permissions.includes('edit_quantity'),
      visible: permissions.includes('view_quantity'),
      rules: [
        {
          validator: (rule, value, cb) => {
            try {
              if (isNaN(Number(value))) {
                throw new Error('Must be a number.')
              } else if (value === '') {
                throw new Error('Quantity is required.')
              }
              cb()
            } catch (error) {
              cb(error)
            }
          },
        },
      ],
      onChange: (optionalpricevalidationbyversionid, optionId, value) =>
        debouncedOnChangeFxn(props, 'quantity', optionalpricevalidationbyversionid, optionId, value),
    },
    {
      title: 'Cost',
      dataIndex: 'cost',
      width: '150px',
      editable: (record) => permissions.includes('edit_cost'),
      visible: permissions.includes('view_cost'),
      addonBefore: '$',
      className: 'cost',
      render: (value, record) => ({ children: <span>$ {value || '0'}</span> }),
      align: 'right',
      rules: [
        {
          validator: (rule, value, cb) => {
            try {
              if (isNaN(Number(value))) {
                throw new Error('Must be a number.')
              } else if (value === '') {
                throw new Error('Cost is required.')
              }
              cb()
            } catch (error) {
              cb(error)
            }
          },
        },
      ],
      onChange: (optionalpricevalidationbyversionid, optionId, value) =>
        debouncedOnChangeFxn(props, 'cost', optionalpricevalidationbyversionid, optionId, value),
    },
    {
      title: 'MUF',
      dataIndex: 'muf',
      width: '100px',
      editable: (record) => record.ismufdiscounteditable 
                            && permissions.includes('edit_muf'),
      visible: permissions.includes('view_muf'),
      className: 'muf',
      align: 'right',
      rules: [
        {
          validator: (rule, value, cb) => {
            try {
              if (isNaN(Number(value))) {
                throw new Error('Must be a number.')
              } else if (value === '') {
                throw new Error('MUF is required.')
              }
              cb()
            } catch (error) {
              cb(error)
            }
          },
        },
      ],
      onChange: (optionalpricevalidationbyversionid, optionId, value) =>
        debouncedOnChangeFxn(props, 'muf', optionalpricevalidationbyversionid, optionId, value),
    },
    {
      title: 'List Price',
      dataIndex: 'listprice',
      width: '150px',
      editable: false,
      visible: true,
      addonBefore: '$',
      render: (value) => ({ children: <span>{formatCurrency(value)}</span> }),
      align: 'right',
    },
    {
      title: 'Discount',
      dataIndex: 'discountpercentage',
      width: '100px',
      editable: (record) => {
        if (!permissions.includes('edit_discountpercentage')) return false
        if (record.ismufdiscounteditable === false) return false
        // if (record.pricevalidationoptionname === 'Ez GuideRails') return false
        return true
      },
      visible: permissions.includes('view_discountpercentage'),
      addonAfter: '%',
      className: 'discount',
      align: 'right',
      rules: [
        {
          validator: (rule, value, cb) => {
            try {
              if (isNaN(Number(value))) {
                throw new Error('Must be a number.')
              } else if (value === '') {
                throw new Error('Discount is required.')
              }
              cb()
            } catch (error) {
              cb(error)
            }
          },
        },
      ],
      render: (value, record) => ({ children: <span>{value || '0'}%</span> }),
      onChange: (optionalpricevalidationbyversionid, optionId, value) =>
        debouncedOnChangeFxn(props, 'discountpercentage', optionalpricevalidationbyversionid, optionId, value),
    },
    {
      title: 'Price',
      dataIndex: 'netprice',
      width: '150px',
      editable: false,
      visible: true,
      render: (value) => {
        return {
          children: <span>{formatCurrency(value)}</span>,
        }
      },
      align: 'right',
    },
  ]

  if (isactive && !islocked) {
    config.push({
      title: '',
      dataIndex: 'operation',
      editable: false,
      visible: permissions.includes('edit_options'),
      width: '10px',
      render: (text, record, index) => {
        if (isEZGuideOption(record)) return { children: null }
        return {
          children: (
            <div className="validation-table-actions">
              <Icon
                icon="delete"
                size={15}
                color={Colors.negative}
                onClick={() => {
                  props.openConfirmModal({
                    headerText: 'Delete Global Option',
                    bodyText: `Are you sure you want to delete ${record.pricevalidationoptionname}?`,
                    onConfirm: () => deleteOption(record.optionId),
                  })
                }}
              />
            </div>
          ),
          props: {
            style: {
              overflow: 'visible',
            },
          },
        }
      },
      align: 'right',
    })
  }
  return config
}
