import jwtDecode from 'jwt-decode'
import { IUser } from 'shared/types/user'

/**
 * decodes jwt, saves token to localStorage, and returns User
 */
export default function getUserDetailsAndToken({
  token,
}: {
  token: string
}): { user: IUser; token: string } {
  const data = jwtDecode(token)
  return {
    user: {
      authenticated: true,
      exp: data?.exp,
      token: token,
      firstName: data?.firstname,
      lastName: data?.lastname,
      email: data?.email,
      permissions: data?.permissions,
      roles: data?.roles,
      userId: data?.userid,
      username: data?.login,
      userType: data?.roles?.indexOf?.('InternalUser') > -1 ? 'Internal' : 'External',
      authorizedexternalprojecttypes: typeof data?.authorizedexternalprojecttypes == "string" ? new Array(data?.authorizedexternalprojecttypes) : data?.authorizedexternalprojecttypes,
      issparepartuser: data?.issparepartuser?.toLowerCase?.() === 'true',
      logoutUrl: data?.logouturl
    },
    token,
  }
}
