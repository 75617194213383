import STATES from 'shared/constants/states'
import { FormConfigItem } from 'shared/types/formConfig'
import { ICustomer, IContact, IProject } from 'shared/types/swagger'
import { ProjectFormProps } from '../ProjectForm'

import { IExternalUserReduced } from 'shared/types/user'

type Contacts = Record<string, IContact>
type Customers = Record<string, ICustomer>

export function projectInfoConfig(props: ProjectFormProps, state): FormConfigItem[] {
  const { accountManagers, designers, estimators, permissions, roles, authorizedexternalprojecttypes } = props
  const { project } = state
  const canEditProjectDetais = permissions.includes('view_edit_projects_details')
  const isExternalUser = roles.includes('ExternalUser')

  return [
    {
      key: 'name',
      prettyName: 'Project Name',
      type: 'input',
      placeholder: 'Enter Project Name',
      width: '289px',
      value: project ? project.name : undefined,
      disabled: !project,
      rules: [
        {
          required: true,
          message: 'Project name is required.',
        },
      ],
    },
    {
      key: canEditProjectDetais || (isExternalUser && project.externalprojecttype != null) ? 'number' : null,
      prettyName: 'Project Number',
      type: 'input',
      placeholder: 'Enter Project Number',
      width: '289px',
      value: project ? project.number : undefined,
      disabled: !project || !roles.includes('Admin'),
      rules: [
        {
          required: true,
          message: 'Project Number is required.',
        },
        {
          message: 'Project Number must be exactly 10 characters long.',
          pattern: /^.{10}$/,
        },
      ],
    },
    {
      key: canEditProjectDetais && (!project || !project.isexternalproject) ? 'accountmanagerid' : null,
      prettyName: 'Assigned Account Manager',
      type: 'select',
      placeholder: 'Select Account Manager',
      disabled: !project,
      width: '289px',
      value: project ? project.accountmanagerid : undefined,
      rules: [
        {
          required: true,
          message: 'Account Manager is required.',
        },
      ],
      options: accountManagers.map((option) => ({
        value: option.id,
        label: `${option.firstname} ${option.lastname}`,
      })),
      onSelect: (value) => ({ accountmanagerid: value }),
    },
    {
      key: canEditProjectDetais && (!project || !project.isexternalproject) ? 'estimatorid' : null,
      prettyName: 'Assigned Estimator',
      type: 'select',
      placeholder: 'Select Estimator',
      disabled: !project,
      width: '289px',
      value: project ? project.estimatorid : undefined,
      rules: [],
      options: estimators.map((option) => ({
        value: option.id,
        label: `${option.firstname} ${option.lastname}`,
      })),
      onSelect: (value) => ({ estimatorid: value }),
    },
    {
      key: canEditProjectDetais && (!project || !project.isexternalproject) ? 'designerid' : null,
      prettyName: 'Assigned Designer',
      type: 'select',
      placeholder: 'Select Designer',
      disabled: !project,
      width: '289px',
      value: project ? project.designerid : undefined,
      rules: [],
      options: designers.map((option) => ({
        value: option.id,
        label: `${option.firstname} ${option.lastname}`,
      })),
      onSelect: (value) => ({ designerid: value }),
    },
    {
      key: project && (project.isexternalproject || (project.isexternalproject ==null && isExternalUser)) && (authorizedexternalprojecttypes === undefined || authorizedexternalprojecttypes.length>1) ? 'externalprojecttype' : null,
      disabled: !project || project.externalprojecttype != null, 
      prettyName: 'Project type',
      type: 'radio',
      width: '289px',
      value: project?.externalprojecttype ?? 'la',
      options: [
        { label: 'LA project', value: 'la' },
        { label: 'Regular', value: 'regular' },
      ],
    },
    {
      key: (canEditProjectDetais 
            || (project.isexternalproject && project.externalprojecttype!='la') 
            || (project.isexternalproject==null && isExternalUser 
                && authorizedexternalprojecttypes !== undefined 
                && (authorizedexternalprojecttypes.length > 1 
                    ||authorizedexternalprojecttypes[0]!='la'))) 
           ? 'processFlow' : null,
      prettyName: 'Process Flow',
      disabled: !project,
      type: 'textarea',
      width: '100%',
      value: project ? project.processflow : '',
    },
    {
      key: project && ((project.isexternalproject && project.externalprojecttype=='la') 
                       || (project.isexternalproject ==null && isExternalUser && authorizedexternalprojecttypes !== undefined 
                           && authorizedexternalprojecttypes.length == 1 && authorizedexternalprojecttypes[0]=='la')) ? null : 'hasconveyorbuilderimage',
      prettyName: 'Conveyor Builder Preview Image Required',
      disabled: !project,
      type: 'checkbox',
      width: '289px',
      value: project ? project.hasconveyorbuilderimage : false,
    },
    {
      key: canEditProjectDetais && (!project || !project.isexternalproject) ? 'threedsolidfilerequired' : null,
      prettyName: '3D Solid File Required',
      disabled: !project,
      type: 'checkbox',
      width: '289px',
      value: project ? project.threedsolidfilerequired : false,
    },
  ]
}

export function customerInfoConfig(
  props: { customers: Customers; type: string; hint:string },
  state: { project: IProject; user:IExternalUserReduced;  customers },  
) {
  const { customers, type, hint } = props
  const { project, user } = state
  const isUser = type === 'user'

  return [
    {
      key: 'customerid',
      prettyName: 'Select Existing Customer'+(hint ? ' ('+hint+')' : ''),
      type: 'select_search',
      allowClear: true,
      disabled: Boolean(project && project.customer),
      showSearch: true,
      placeholder: 'Search Customer by ID Number or Name',
      width: '580px',
      rules: isUser
        ? [{ required: true, message: 'Customer is required for External Users' }]
        : undefined,
      value: project && project.customer ? project.customer.id : user?.customerid,
      options: Object.values(state.customers || {})
        .filter((customer: ICustomer) => {
          const searchString = customer.name + customer.number
          const searchcustomerid = state?.['searchcustomerid']?.toLowerCase?.()
          if (!searchString || !searchcustomerid) return true
          return searchString ? searchString.toLowerCase().indexOf(searchcustomerid) !== -1 : false
        })
        .slice(0, 50)
        .map((option: ICustomer) => ({
          value: option.id,
          label: option.name,
          number: option.number,
        })),
      onSelect: (value) => ({ key: 'customerid', value: customers?.[value] }),
    },
    {
      key: 'number',
      prettyName: 'Customer Number',
      type: 'text',
      placeholder: 'Enter Customer Number',
      width: '289px',
      value: project && project.customer ? project.customer.number : (user?.customerid ? customers?.[user?.customerid]?.number : undefined),
    },
    {
      key: 'name',
      prettyName: 'Customer Name',
      type: 'text',
      placeholder: 'Enter Customer Name',
      width: '289px',
      value: project && project.customer ? project.customer.name : (user?.customerid ? customers?.[user?.customerid]?.name : undefined),
    },
    {
      key: 'streetaddress1',
      prettyName: 'Street Address 1',
      type: 'text',
      placeholder: 'Enter Street Address 1',
      width: '100%',
      value: project && project.customer ? project.customer.streetaddress1 : (user?.customerid ? customers?.[user?.customerid]?.streetaddress1 : undefined),
    },
    {
      key: 'streetaddress2',
      prettyName: 'Street Address 2',
      type: 'text',
      placeholder: 'Enter Street Address 2',
      width: '100%',
      value: project && project.customer ? project.customer.streetaddress2 : (user?.customerid ? customers?.[user?.customerid]?.streetaddress2 : undefined),
    },
    {
      key: 'city',
      prettyName: 'City',
      type: 'text',
      placeholder: 'Enter City',
      width: '289px',
      value: project && project.customer ? project.customer.city : (user?.customerid ? customers?.[user?.customerid]?.city : undefined),
    },
    {
      key: 'state',
      prettyName: 'State',
      type: 'text',
      allowClear: true,
      placeholder: 'Select State',
      width: '24%',
      value: project && project.customer ? project.customer.state : (user?.customerid ? customers?.[user?.customerid]?.state : undefined),
      options: STATES,
    },
    {
      key: 'zipcode',
      prettyName: 'Zip',
      type: 'text',
      placeholder: 'Enter Zipcode',
      width: '24%',
      value: project && project.customer ? project.customer.zipcode : (user?.customerid ? customers?.[user?.customerid]?.zipcode : undefined),
    },
  ]
}

/** @returns {FormConfigItem[]} */
export const contactInfoConfig = (
  props: { contacts: Contacts; type: string; hint:string; keyname: string },
  state: { project: IProject; user:IExternalUserReduced }
) => {
  const { contacts, type, hint, keyname } = props
  const { project, user } = state
  const isUser = type === 'user'
  const keyid = keyname+'id'
  const checkIContact = (p: any): p is IContact => p?.hasOwnProperty('id');

  return [
    {
      key: keyid,
      prettyName: 'Select an Existing Customer Contact'+(hint ? ' ('+hint+')' : ''),
      type: 'select',
      placeholder: 'Search Contact by First or Last Name',
      width: '580px',
      rules: isUser
        ? [{ required: true, message: 'Contact is required for External Users' }]
        : undefined,
      value: project && checkIContact(project[keyname as keyof IProject]) ? (project[keyname as keyof IProject] as IContact).id : user?.contactid,
      options: Object.values(contacts).map((option) => ({
        value: option.id,
        label: `${option.firstname} ${option.lastname}`,
      })),
      disabled: !project,
      onSelect: (value) => ({ key: keyid, value: contacts[value] }),
    },
    {
      key: 'firstname',
      prettyName: 'Contact First Name',
      type: 'text',
      placeholder: 'Enter Contact First Name',
      width: '289px',
      value: project && checkIContact(project[keyname as keyof IProject]) ? (project[keyname as keyof IProject] as IContact).firstname : (user?.contactid ? contacts?.[user?.contactid]?.firstname : undefined),
    },
    {
      key: 'lastname',
      prettyName: 'Contact Last Name',
      type: 'text',
      placeholder: 'Enter Contact Last Name',
      width: '289px',
      value: project && checkIContact(project[keyname as keyof IProject]) ? (project[keyname as keyof IProject] as IContact).lastname : (user?.contactid ? contacts?.[user?.contactid]?.lastname : undefined),
    },
    {
      key: 'phonenumber',
      prettyName: 'Phone Number',
      type: 'text',
      placeholder: 'Enter Contact Phone Number',
      width: '289px',
      value: project && checkIContact(project[keyname as keyof IProject]) ? (project[keyname as keyof IProject] as IContact).phonenumber : (user?.contactid ? contacts?.[user?.contactid]?.phonenumber : undefined),
    },
    {
      key: 'emailaddress',
      prettyName: 'Email Address',
      type: 'text',
      placeholder: 'Enter Contact Email Address',
      width: '289px',
      value: project && checkIContact(project[keyname as keyof IProject]) ? (project[keyname as keyof IProject] as IContact).emailaddress : (user?.contactid ? contacts?.[user?.contactid]?.emailaddress : undefined),
    },
  ]
}
