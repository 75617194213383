import React, { useEffect, useRef, useState } from 'react'
import ListService from 'utils/api/list/ListService'
import { Button, Select, Icon } from 'shared/components'
import './ImportCostFromOtherEnv.scss'
import { setModalContent } from 'shared/redux/ScreenActions'
import { useAppDispatch } from 'shared/hooks/app'
import { useLoadingDots } from 'shared/hooks'
import { Table } from 'antd'
import { getABCSorter } from '../CostImport/utils'

function ImportCostFromOtherEnv() {
    const dispatch = useAppDispatch()

    const [availableEnvs, setAvailableEnvs] = useState([])
    const [selectedEnv, setSelectedEnv] = useState('')
    const [comparisonResult, setComparisonResult] = useState([])
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')

    useEffect(() => {
        (async () => {
            try 
            {
                await ListService.GetAvailableEZSuiteSourceEnv().then((data) => setAvailableEnvs(data));
            }
            catch (exception)
            {
                setError('Error loading the availables EZ-suite source envrionements.')
            }
        })()
    }, [])
    
    const selectedEnvChange = async (value) => {
        setSelectedEnv(value)
        setComparisonResult([])
        setError('')
        setLoading(true)
        try 
        {
            var comparisonResult = await ListService.CompareSytelineCostWithSourceEnv(value)
            setComparisonResult(comparisonResult)
        }
        catch (exception)
        {
            setError('Error fetching comparision results between current EZ-Suite and selected source.')
        }
        setLoading(false)
    }

    const ingestFromSelectedEnv = async () => {
        setError('')
        setLoading(true)
        try 
        {
            const response = await ListService.IngestSytelineCostsFromSourceEnv(selectedEnv)
            dispatch(setModalContent(null))
            window.location.href = window.location.href 
        }
        catch (exception)
        {
            setError('Error during ingestion to current EZ-Suite from selected source')
        }
        setLoading(false)
    }

    const dots = useLoadingDots(true)

    const columns = [
        {
          dataIndex: 'itemnumber',
          title: 'Item Number',
          sorter: getABCSorter('itemnumber'),
        },
        {
            dataIndex: 'comment',
            title: 'Comment',
            width: 150,
            sorter: getABCSorter('comment'),
        },
        {
            dataIndex: 'materialdiscrepency',
            title: 'Material',
            width: 150,
        },
        {
            dataIndex: 'freightdiscrepency',
            title: 'Freight',
            width: 150,
        },
        {
            dataIndex: 'dutydiscrepency',
            title: 'Duty',
            width: 150,
        }
    ]

    return (<div id="import-cost-other-envs">
        <h1 className="import-cost-other-envs__header">
            Import from another EZ-Suite environment
        </h1>
        {error ? <p className="error">{error}</p> : null}
        <Icon icon="x" size={30} onClick={() => dispatch(setModalContent(null))} />
        <div className="import-cost-other-envs__section">
            <h2>Ez-suite source</h2>
                <Select className="selectenv"               
                    defaultActiveFirstOption={false}
                    filterOption={false}
                    placeholder='Select'
                    value={selectedEnv}
                    onSelect={selectedEnvChange}
                    options={availableEnvs}/>
        </div>        
        <div className="import-cost-other-envs__section">
            <br/>
            <h2>Comparison results
            <Button className="ingestbtn"
                small
                antIcon="cloud-download"
                text={"Ingest from "+selectedEnv}
                disabled={comparisonResult.length == 0 || loading}
                onClick={ingestFromSelectedEnv}
            /></h2>
            <Table className='displayresult'
                columns={columns}
                dataSource={loading ? [] : comparisonResult}
                pagination={false}
                loading={loading}
            />
        </div>
    </div>)
}

export default ImportCostFromOtherEnv
