import store from 'utils/../reduxStore'
import { log, formatIdAsKey } from 'utils/helpers'
import { batch } from 'react-redux'
import { createAction } from '@reduxjs/toolkit'
import { updateConveyorChain } from 'features/Chain/redux/ChainActions'
import {
  addConveyorsMetadata,
  addConveyorToVersion,
  saveVersionConveyors,
  saveVersionOptions,
  saveConveyorToConveyors,
  resetConveyorStore,
  saveVersionOptionsOrder,
} from 'features/Conveyor/redux/ConveyorActions'
import { addConveyorSectionMetadata } from 'features/Estimator/components/ConveyorBuilder/redux/ConveyorBuilderActions'
import {
  setDefaultAccessoryDataForConveyors,
  initializeDefaultMaterialsAcessoriesMetadatas,
} from 'features/Estimator/redux/EstimatorActions'
import { history } from 'App'
import { initializematerials } from 'features/Estimator/components/Materials/redux/default'
import { initializeaccessories } from 'features/Estimator/components/Accessories/redux/default'
import { updateProjectReducerVersion, updateProject } from 'features/Project/redux/ProjectActions'
import {
  setLoading,
  setLoadingOverride,
  openConfirmModal,
  closeConfirmModal,
} from 'shared/redux/ScreenActions'
import { formatConveyorsAndConveyorSections } from 'utils/api/conveyor/helpers'
import ConveyorService from 'utils/api/conveyor/ConveyorService'
import { organizeChainSerieInfo } from 'utils/api/chain/helpers'
import VersionService from 'utils/api/version/VersionService'
import {
  getProductsForConveyor,
  removeConveyorsProductsForOtherProjects,
} from 'features/Product/redux/ProductActions'
import { IConveyorMeta } from 'features/Conveyor/shared/types'
import { IPriceValidation } from 'shared/types/swagger'
import { updateGearmotorOptimistic } from 'features/Estimator/components/Gearmotor/redux/GearmotorActions'
import { updateVersion } from 'features/Project/redux/ProjectOperations'
import { IOptionalPriceValidationByVersion } from 'utils/api/list/types/priceValidationOptions'

export const setCopiedProduct = createAction('SET_COPIED_PRODUCT')
export const resetVersionStore = createAction('RESET_VERSION_STORE')
export const setVersionId = createAction<number>('SET_VERSION_ID')
export const updateSelectedOptionConveyors = createAction('UPDATE_SELECTED_OPTION_CONVEYORS')
export const updateVersionMetadata = createAction<{
  versionId: number;
  metadata: Record<string, IConveyorMeta>;
}>('UPDATE_VERSION_METADATA')
export const setOptionalPriceValidationByVersion = createAction<Array<IOptionalPriceValidationByVersion>>('SET_OPTIONAL_PV')

export const downloadVersionFile = (versionId, downloadType, projectId) => async (dispatch) => {
  dispatch(setLoading({ loading: true, loadingMessage: 'Downloading...' }))
  await VersionService.downloadVersionFile(versionId, downloadType, projectId)
    .then((res) => dispatch(setLoading(false)))
    .catch((e) => {
      dispatch(setLoading(false))
      debugger
    })
}

export const downloadPriceValidationComparisonFile = (versionId, versionIdsToCompare) => async (dispatch) => {
  dispatch(setLoading({ loading: true, loadingMessage: 'Downloading...' }))
  await VersionService.downloadPriceValidationComparisonFile(versionId, versionIdsToCompare)
    .then((res) => dispatch(setLoading(false)))
    .catch((e) => {
      dispatch(setLoading(false))
      debugger
    })
}

export const setAllMetaDataForVersionsConveyors = (conveyors, versionId) => (dispatch) => {
  dispatch(addConveyorsMetadata(versionId, conveyors))
  dispatch(addConveyorSectionMetadata(conveyors))
  dispatch(initializeDefaultMaterialsAcessoriesMetadatas({ conveyorIds: Object.keys(conveyors) }))
}

export const getVersionWithAllDetails = (versionId, reset) => async (dispatch) => {
  return new Promise(async (resolve, reject) => {
    await VersionService.getVersionWithAllDetails(versionId)
      .then(async (data) => {
        const p1 = performance.now()
        const { conveyors = [], ...restVersion } = data
        const conveyorCount = conveyors.length
        const conveyorIds = conveyors.map((c) => c.id.toString())
        if (!conveyorCount) {
          await dispatch(addConveyorToVersion(versionId))
        }

        batch(() => {
          if (reset) {
            dispatch(resetConveyorStore())
          }

          const accessoriesUpdatePayload = []
          const parsedConveyorsData = conveyors.map((conveyor, n) => {
            // If we upgrade to react concurrent mode we can do this
            // window.dispatchEvent(new CustomEvent('conveyorload', { detail: { index: n, total: conveyorCount } }))
            log({
              message: `Processing ${n + 1} of ${conveyors.length} Conveyors...`,
              color: '#006BB6',
              type: 'LOADING',
            })

            const {
              accessories,
              materials,
              ceilingsupports,
              ceilingsupportdetails,
              floorsupports,
              floorsupportdetails,
              stdchaindescriptions,
              products,
              ...restConveyor
            } = conveyor

            ceilingsupports.details = ceilingsupportdetails
            floorsupports.details = floorsupportdetails

            dispatch(initializematerials(conveyor.id, materials))
            dispatch(initializeaccessories(conveyor.id, accessories))
            dispatch(
              saveConveyorToConveyors(
                formatConveyorsAndConveyorSections([restConveyor])[conveyor.id]
              )
            )
            dispatch(
              updateConveyorChain({
                conveyorId: conveyor.id,
                chain: organizeChainSerieInfo(stdchaindescriptions),
                versionId,
              })
            )
            dispatch(getProductsForConveyor(conveyor.id, products))
            accessoriesUpdatePayload.push({
              conveyorId: conveyor.id,
              accessoryKey: 'ceilingsupports',
              accessoryData: ceilingsupports,
            })
            accessoriesUpdatePayload.push({
              conveyorId: conveyor.id,
              accessoryKey: 'floorsupports',
              accessoryData: floorsupports,
            })

            return {
              ...restConveyor,
              conveyorsections: formatIdAsKey(restConveyor.conveyorsections),
            }
          })

          // Projects cloned with gearmotor values not available anymore must have
          // those values set to some default. In this case "None" is being set for 
          // variablespeedoptionsid
          try {
            const { ProjectReducer } = store.getState()
            const VSOptions = ProjectReducer.currentlistsettings.gearmotor.eurodrive.variablespeedoptions
            const validVFDOptionIds = Object.keys(VSOptions).filter(key => !isNaN(key as unknown as number)).map(Number)
            const noneOptionId = validVFDOptionIds.find(id => VSOptions[id].title === 'None')
            for (let i = 0; i < conveyors.length; i++) {
              const conveyor = conveyors[i];
              const hasValidVSO = validVFDOptionIds.includes(conveyor.gearmotor.variablespeedoptionsid)
              if (!hasValidVSO) {
                dispatch(updateGearmotorOptimistic(conveyor.id, { variablespeedoptionsid: noneOptionId }))
              }
            }
          } catch (error) {
            console.log('Failed Setting default gearmotor option for variablespeedoptionsid')
          }

          log({
            message: 'Saving floor and ceiling supports defaults..',
            color: '#006BB6',
            type: 'LOADING',
          })
          dispatch(setDefaultAccessoryDataForConveyors(accessoriesUpdatePayload))
          log({
            message: 'Saving floor and ceiling supports defaults..',
            color: '#006BB6',
            type: 'LOADING',
          })
          dispatch(setVersionId(versionId))
          log({
            message: 'Deleting other projects metadata and products data..',
            color: '#006BB6',
            type: 'LOADING',
          })
          // dispatch(deleteUnusedCoveyorsMetadata(conveyorIds))
          dispatch(removeConveyorsProductsForOtherProjects({ conveyorIds }))
          log({
            message: 'Setting metadata for version conveyors...',
            color: '#006BB6',
            type: 'LOADING',
          })
          dispatch(
            setAllMetaDataForVersionsConveyors(formatIdAsKey(parsedConveyorsData), versionId)
          )
          log({ message: 'Updating version information...', color: '#006BB6', type: 'LOADING' })
          dispatch(updateProjectReducerVersion(restVersion))
          log({
            message: `loading all conveyors took ${((performance.now() - p1) / 1000).toFixed(
              2
            )} seconds`,
            color: '#006BB6',
            type: 'LOADING',
          })
          resolve(conveyorCount)
        })
      })
      .catch((e) => {
        console.log(e)
        history.replace('/')
        dispatch(setLoadingOverride({ loadingOverride: false }))
      })
  })
}

export const getVersionConveyors = () => async (dispatch) => {
  dispatch(
    setLoading({
      loading: true,
      loadingMessage: 'Fetching version conveyors...',
    })
  )
  const { versionId } = store.getState().VersionReducer
  return new Promise(async (resolve, reject) => {
    await ConveyorService.getVersionConveyors(versionId)
      .then(async (conveyors) => {
        if (!Object.keys(conveyors).length) {
          dispatch(addConveyorToVersion(versionId))
        } else {
          dispatch(saveVersionConveyors(conveyors))
          dispatch(setAllMetaDataForVersionsConveyors(conveyors, versionId))
          dispatch(setLoading(false))
        }
      })
      .catch((e) => {
        dispatch(setLoading(false))
        reject(e)
      })
  })
}

export const getVersionOptions = () => async (dispatch): Promise<Array<IPriceValidation['id']>> => {
  const versionId =
    store.getState().VersionReducer.versionId || window.location.pathname.split('/')[3]

  return new Promise(async (resolve, reject) => {
    await ConveyorService.getVersionOptionsRaw(versionId)
      .then(async (options) => {
        const formatOptions = formatIdAsKey(options)
        const optionsOrder = options.map(opt => opt.id)
        batch(() => {
          dispatch(saveVersionOptions(formatOptions))
          dispatch(saveVersionOptionsOrder(optionsOrder))
          dispatch(setLoading(false))
        })
        resolve(optionsOrder)
      })
      .catch((e) => {
        debugger
        dispatch(setLoading(false))
        reject(e)
      })
  })
}

export const selectVersion = (versionId, shallow = false, reset = true) => async (dispatch) => {
  return new Promise(async (resolve, reject) => {
    if (!shallow) {
      await dispatch(
        setLoadingOverride({
          loadingOverride: true,
          loadingMessage: 'Loading Details for Version...',
        })
      )
    }
    await VersionService.getVersion(versionId)
      .then(async (versionData) => {
        await dispatch(setVersionId(versionId))
        await dispatch(updateProjectReducerVersion(versionData))
        if (!shallow) {
          await dispatch(getVersionWithAllDetails(versionId, reset))
          await dispatch(setLoadingOverride(false))
        }
        resolve(true)
      })
      .catch((e) => {
        debugger
        dispatch(setLoading(false))
        reject(e)
      })
  })
}

/**
 * @param {number} statusId
 *
 * @returns {import('redux').ActionCreator}
 */
export const updateVersionStatus = (statusId) => async (dispatch) => {
  const { versionId } = store.getState().VersionReducer
  await VersionService.updateVersionStatus(versionId, statusId)
    .then((versionData) => {
      dispatch(updateProjectReducerVersion(versionData))
    })
    .catch((e) => {
      debugger
    })
}

/**
 * @param {number} versionId
 *
 * @returns {import('redux').ActionCreator}
 */
export const validateVersion = (versionId) => async (dispatch) => {
  await dispatch(updateVersion({ versionId, payload: { validated: true } }))
}

/**
 * @param {number} versionId
 *
 * @returns {import('redux').ActionCreator}
 */
export const updateVersionConveyorsCosts = (versionId, history) => (dispatch) => {
  const { confirmModal } = store.getState().ScreenReducer
  dispatch(
    openConfirmModal({
      ...confirmModal,
      disableConfirm: true,
    })
  )
  return new Promise(async (resolve, reject) => {
    await VersionService.updateConveyorsCosts(versionId)
      .then((conveyors) => {
        batch(() => {
          dispatch(closeConfirmModal())
          dispatch(
            setLoading({ loading: true, loadingMessage: 'Loading Updated Costs for Conveyors...' })
          )
          dispatch(saveVersionConveyors(conveyors))
          dispatch(selectVersion(versionId, true))
          dispatch(setAllMetaDataForVersionsConveyors(conveyors, versionId))
        })
        resolve(true)
      })
      .catch((e) => {
        reject(e)
      })
  })
}

export const updateInactivationDate = (newDate) => async (dispatch) => {
  const { versionId } = store.getState().VersionReducer
  await VersionService.updateInactivationDate(versionId, newDate)
    .then(({ activationperiods }) => {
      const versionData = {
        ...store.getState().ProjectReducer.versions[versionId],
        activationperiods,
      }
      dispatch(updateProjectReducerVersion(versionData))
    })
    .catch((e) => {
      debugger
    })
}

export const updateVersionDiscount = (discountpercentage) => (dispatch) => {
  const { versionId } = store.getState().VersionReducer
  const projectVersion = store.getState().ProjectReducer.versions[versionId]
  const { conveyors } = store.getState().ConveyorReducer

  dispatch(
    setLoading({
      loading: true,
      loadingMessage: 'Updating Discount for all Conveyors and Options...',
    })
  )

  VersionService.updateDiscount(versionId, discountpercentage)
    .then((data) => {
      const _conveyors = { ...conveyors }
      for (let i = 0; i < data.conveyors.length; i++) {
        const conveyor = data.conveyors[i]
        const { id } = conveyor
        _conveyors[id] = {
          ..._conveyors[id],
          ...conveyor,
        }
      }
      const { pricevalidations, ...priceinfo } = data
      const versionData = {
        ...projectVersion,
        ...priceinfo,
        pricevalidations,
      }
      dispatch(saveVersionOptions(formatIdAsKey(pricevalidations)))
      dispatch(saveVersionConveyors(formatConveyorsAndConveyorSections(_conveyors)))
      dispatch(updateProjectReducerVersion(versionData))
      dispatch(
        openConfirmModal({
          headerText: 'Update Successful',
          bodyText: 'Successfully updated all discount values.',
          cancelButton: false,
          confirmButtonText: 'OK',
        })
      )
      dispatch(setLoading(false))
    })
    .catch((e) => {
      dispatch(
        openConfirmModal({
          headerText: 'Error while Updating Discounts',
          bodyText: `Could not update the discount for all fields.\nError encountered while updating ${e}.`,
          cancelButton: false,
          confirmButtonText: 'OK',
        })
      )
      dispatch(setLoading(false))
      debugger
    })
}

export const syncProjectToSyteline = (versionId: number) => async (dispatch) => {
  try {
    const success = await VersionService.syncToSyteline(versionId)
    if (success) {
      const { ProjectReducer } = store.getState()
      dispatch(updateProject({ ...ProjectReducer.selectedProject, issyncedwithsyteline: true }))
    }
  } catch (error) {
    console.log(error)
    debugger
    // TODO: handle sync fail
  }
}

export const getOptionalPriceValidationByVersion = (versionId) => async (dispatch) => {
  await VersionService.priceValidationOptions(versionId)
    .then((data) => dispatch(setOptionalPriceValidationByVersion(data)))
    .catch((e) => {
      dispatch(setLoading(false))
      debugger
    })
}

