import { connect } from 'react-redux'
import React, { Component } from 'react'
import ADP from 'awesome-debounce-promise'
import { withRouter } from 'react-router-dom'
import { Form, Input, Icon as AntIcon, Radio } from 'antd'
import { updateConveyor, updateCompletedTabs } from 'features/Conveyor/redux/ConveyorActions'
import {
  createProduct,
  updateProduct,
  setProductCollapsibleOpen,
  cloneProduct,
  deleteProduct,
  setProductValidity,
} from 'features/Product/redux/ProductActions'
import Colors from 'shared/constants/colors'
import { Icon } from 'shared/components'
import { setPendingResponse, deletePendingResponse } from 'shared/redux/ScreenActions'
import CollapsibleSection from './components/CollapsibleSection'
import ProductInner from './components/ProductInner'
import { contaminantsConfig, contaminantsText, generalSettingsConfig } from './productConfig'
import './Product.scss'
import { captureSentryError } from 'utils/helpers'

class Product extends Component {
  state = {
    payload: {},
    productsValidationStatuses: {},
    errors: {},
  }

  componentDidMount() {
    this.validateGeneralSettings()
  }

  componentDidUpdate(prevProps, prevState) {
    const { copiedProduct, conveyor, metadata, productsMeta } = this.props
    const oneProductIsValid = Object.values(productsMeta).some((meta) => meta.valid)
    const oneProductWasValid = Object.values(prevProps.productsMeta).some((meta) => meta.valid)

    if (prevProps.conveyor.unit !== conveyor.unit) {
      this.props.form.resetFields()
    }

    if (prevProps.conveyor !== conveyor || prevProps.metadata.currentTab !== metadata.currentTab) {
      this.validateGeneralSettings()
    }

    if (oneProductIsValid !== oneProductWasValid) {
      this.validateGeneralSettings()
    }

    if (!copiedProduct && copiedProduct !== prevProps.copiedProduct) {
      this.props.form.resetFields()
    }
  }

  setProductTabCompletion = (isCompleted) => {
    const { conveyor, metadata, updateCompletedTabs, versionId } = this.props
    updateCompletedTabs({
      conveyorId: conveyor.id,
      versionId,
      completedTabs: {
        ...metadata.completedTabs,
        product: isCompleted,
      },
    })
  }

  validateGeneralSettings = async () => {
    const { form, productsMeta } = this.props
    const oneProductIsValid = Object.values(productsMeta || {}).some((meta) => meta.valid)
    try {
      await form.validateFields((errors, values) => {
        const generalSettingsValid = !errors
        this.setProductTabCompletion(generalSettingsValid && oneProductIsValid)
        return generalSettingsValid
      })
    } catch (error) {
      console.error(error)
      return false
    }
  }

  renderContaminants = () => {
    return (
      <div className="contaminants-select-box">
        <div className="right">
          <h6>Presets</h6>
          {contaminantsText.map((o, i) => {
            const { conveyor, form, updateConveyor } = this.props
            const payload = {
              frictiontop: o.value,
              frictionload: o.value,
              frictionbottom: o.value,
            }
            const updateContaminants = async () => {
              form.setFieldsValue(payload)
              await updateConveyor(conveyor.id, payload, true).catch((e) => form.resetFields())
            }
            return (
              <div key={i} className="clickable" onClick={updateContaminants}>
                <Icon icon="add" size={16} color={Colors.novaBlue} />
                {o.text}
              </div>
            )
          })}
        </div>
      </div>
    )
  }

  lumpUpdateInputs = () => {
    const {
      conveyorId,
      deletePendingResponse,
      setPendingResponse,
      updateConveyor,
      form: { validateFields },
    } = this.props

    validateFields((errors, values) => {
      const payload = { ...this.state.payload }
      if (errors) {
        const keys = Object.keys(errors)
        keys.forEach((k) => delete payload[k])
      }
      if (Object.keys(payload).length) {
        setPendingResponse(`updateConveyor${conveyorId}`)
        updateConveyor(conveyorId, payload, true, false).then(() => {
          const newPayload = Object.keys(payload).reduce((acc, key) => {
            if (key in acc && acc[key] === payload[key]) {
              delete acc[key]
            }
            return acc
          }, this.state.payload)
          this.setState({ payload: newPayload })
          deletePendingResponse(`updateConveyor${conveyorId}`)
        })
      }
      this.validateGeneralSettings()
    })
  }

  debouncedInputs = ADP(this.lumpUpdateInputs, 1500)

  renderForm = (config) => {
    const {
      form: { getFieldDecorator },
      isactive,
      islocked,
    } = this.props
    const disabled = !isactive || islocked

    const formContents = config(this.props).map((field, i) => {
      if (field.visible) {
        switch (field.type) {
          case 'input': {
            const handleChange = (e) => {
              e.persist()
              if (e.target.value === '') return
              this.setState(
                ({ payload }) => ({
                  payload: {
                    ...payload,
                    [field.key]: e.target.value,
                  },
                }),
                this.debouncedInputs
              )
            }

            return (
              <Form.Item key={i} label={field.prettyName}>
                {getFieldDecorator(field.key, {
                  initialValue: field.value,
                  rules: field.rules,
                })(
                  <Input
                    disabled={disabled}
                    autoFocus={field.key === 'productlength'}
                    className={field.addonAfter ? 'right' : ''}
                    addonAfter={field.addonAfter ? field.addonAfter : false}
                    placeholder={field.placeholder}
                    name={field.key}
                    onChange={handleChange}
                  />
                )}
              </Form.Item>
            )
          }
          case 'radio': {
            const handleChange = (e) => {
              const { value } = e.target
              this.setState(
                ({ payload }) => ({
                  payload: {
                    ...payload,
                    [field.key]: value.toString() === 'true',
                  },
                }),
                this.debouncedInputs
              )
            }

            return (
              <Form.Item className="products-radio__container" key={field.key}>
                <div className="radio-title">{field.prettyName}</div>
                <Radio.Group disabled={disabled} onChange={handleChange} defaultValue={field.value}>
                  {field.options.map((opt) => (
                    <Radio value={opt.value} key={opt.value}>
                      {opt.label}
                    </Radio>
                  ))}
                </Radio.Group>
              </Form.Item>
            )
          }
          default:
            return null
        }
      } else {
        return null
      }
    })
    return formContents
  }

  validateAndUpdateConveyor = (key, value) => {
    const {
      conveyorId,
      deletePendingResponse,
      form,
      setPendingResponse,
      updateConveyor,
    } = this.props
    const pendingResponseKey = `${key}-${conveyorId}`
    form.setFieldsValue(
      {
        [key]: value,
      },
      () => {
        form.validateFields([key], (errors, values) => {
          if (!errors) {
            setPendingResponse(pendingResponseKey)
            updateConveyor(conveyorId, { [key]: value }, true, false).then(() => {
              deletePendingResponse(pendingResponseKey)
            })
          }
        })
      }
    )
  }

  addProduct = () => {
    const { conveyorId } = this.props
    this.props.createProduct(conveyorId)
  }

  renderProducts = () => {
    const {
      products,
      productsMeta,
      setProductCollapsibleOpen,
      conveyorId,
      cloneProduct,
      deleteProduct,
      conveyor,
      permissions,
      isactive,
      islocked,
    } = this.props
    const { maxproductid, mincrossbarspacingquantityproductids } = conveyor

    const canEditLegendFields = permissions.includes('edit_legendfields')
    const disabledActions = !isactive || islocked

    return Object.values(products)
      .sort((a, b) => a.order - b.order)
      .map((product, i) => {
        const { id } = product
        const open = productsMeta?.[id]?.open

        /** @type {import('shared/components/ActionMenu').ActionMenuOption[]} */
        const options = [
          {
            label: 'Clone Product',
            handleSelect: () => {
              cloneProduct(conveyorId, id)
            },
            icon: 'clone',
            disabled: disabledActions,
          },
          {
            label: 'Delete Product',
            handleSelect: () => {
              deleteProduct(conveyorId, id)
            },
            icon: 'delete',
            type: 'warning',
            disabled: disabledActions,
          },
        ]

        const afterTitle = (() => {
          const usedForTorque = maxproductid === id
          const usedForXbar = mincrossbarspacingquantityproductids.includes(id)
          const usedForBoth = usedForTorque && usedForXbar
          if (!(usedForTorque || usedForXbar)) return null
          return (
            <span className="collapse-section__name-after">
              <Icon icon="infoOutline" color={Colors.novaBlue} size={18} /> Used for{' '}
              {usedForTorque ? 'Torque' : ''}
              {usedForBoth ? ' and ' : ''}
              {usedForXbar ? 'Crossbar Spacing' : ''}
            </span>
          )
        })()

        return (
          <CollapsibleSection
            key={id}
            title={
              <>
                Product {i + 1}
                {afterTitle}
              </>
            }
            open={open}
            onClickOpen={() =>
              setProductCollapsibleOpen({
                open: !open,
                productId: id,
                conveyorId,
              })
            }
            options={options}
          >
            <ProductInner
              conveyorId={this.props.conveyorId}
              productId={id}
              product={product}
              updateProduct={this.props.updateProduct}
              deletePendingResponse={this.props.deletePendingResponse}
              setPendingResponse={this.props.setPendingResponse}
              setProductValidity={this.props.setProductValidity}
              validateGeneralSettings={this.validateGeneralSettings}
              validateAndUpdateConveyor={this.validateAndUpdateConveyor}
              isactive={isactive}
              islocked={islocked}
              conveyor={this.props.conveyor}
              materialtypes={this.props.materialtypes}
              canEditLegendFields={canEditLegendFields}
            />
          </CollapsibleSection>
        )
      })
  }

  render() {
    const { isactive, islocked, pendingApiResponse, style } = this.props
    const isSaving =
      Object.keys(pendingApiResponse).length && Object.prototype.hasOwnProperty.call(pendingApiResponse, 'payload')

    return (
      <div className="products-container" style={style}>
        <section className="products-top">
          <div className="products__subtitle">
            <span>General Settings</span>
            {isSaving ? (
              <span className="loading-text">
                Saving to server in progress...
                <AntIcon type="loading" />
              </span>
            ) : null}
          </div>

          <div className="products-top__inner">
            <section className="products__subsection general" style={{ width: 327 }}>
              <Form
                className={`products__subsection__form dimensions ${
                  isactive && !islocked ? '' : 'version-inactive'
                }`}
              >
                {this.renderForm(generalSettingsConfig)}
              </Form>
            </section>

            <section className="products__subsection environment">
              {/* <h4 className="products__subtitle">Environmental Conditions</h4>
              <Form
                className={`products__subsection__form conditions ${
                  isactive && !islocked ? '' : 'version-inactive'
                }`}
              >
                {this.renderForm(conditionsConfig)}
              </Form> */}

              <div className="products__subsection contaminants">
                <Form
                  className={`products__subsection__form contaminants ${
                    isactive && !islocked ? '' : 'version-inactive'
                  }`}
                >
                  <h5 className="products__subsubtitle">Contaminants</h5>
                  <div className="text">Coefficients of Frictions. Manual Inputs Allowed</div>
                  {this.renderForm(contaminantsConfig)}
                </Form>
                {isactive && !islocked ? this.renderContaminants() : null}
              </div>
            </section>
          </div>
        </section>

        <section className="products">
          {this.renderProducts()}
          {isactive && !islocked ? (
            <div role="button" className="add-a-product" onClick={this.addProduct}>
              <Icon icon="add" size={20} color="currentColor" />
              Add a Product
            </div>
          ) : null}
          {/* <Button
            className='auto-rotate-button'
            extraSmall
            secondary
            text={ `Auto-Rotate ${ autorotate ? 'ON' : 'OFF' }` }
            onClick={ () => this.setState({ autorotate: !autorotate }) }
          /> */}
        </section>
      </div>
    )
  }
}

/** @param {import('srcReducer').Store} state */
const mapStateToProps = (state, props) => {
  try {
    const { conveyorId, match } = props
    const { versionId } = match.params
    const { isactive, islocked } = state.ProjectReducer.versions[versionId]
    const { copiedProduct } = state.VersionReducer
    const { meta: productsMeta = {}, ...products } = state.ProductReducer?.[conveyorId] || {}

    return {
      copiedProduct,
      conveyor: state.ConveyorReducer.conveyors[conveyorId],
      isactive,
      islocked,
      metadata: state.ConveyorMetaReducer[versionId][conveyorId],
      materialtypes: state.ProjectReducer.currentlistsettings.product.materialtypes,
      pendingApiResponse: state.ScreenReducer.pendingApiResponse || {},
      versionId,
      products,
      productsMeta,
      permissions: state.UserReducer.permissions,
    }
  } catch (error) {
    captureSentryError(error, state)
  }
}

const mapDispatchToProps = {
  deletePendingResponse,
  setPendingResponse,
  updateConveyor,
  updateProduct,
  updateCompletedTabs,
  createProduct,
  setProductCollapsibleOpen,
  setProductValidity,
  cloneProduct,
  deleteProduct,
}

const connectedProduct = connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create({ name: 'product' })(Product))

const withRouterProduct = withRouter(connectedProduct)

export default withRouterProduct
