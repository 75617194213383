import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { DefaultHeader } from '../../shared/Headers'
import {
  updateFloorsupports,
  addFloorSupport,
  updateFloorSupport,
  deleteFloorSupport,
} from '../redux/default/FloorSupportsActions'
import floorsupportsConfig from './FloorSupportsConfig'
import { Radio, EditableTable, Checkbox } from 'shared/components'
import { updateDefaultMaterialAcessoriesMetaData } from '../../../redux/EstimatorActions'
import { captureSentryError } from 'utils/helpers'
import './Supports.scss'

class Floorsupports extends Component {
  renderSupportMaterialField = (config) => {
    const { dataKey, prettyName, options, rules } = config.materials
    const { updateFloorsupports, conveyorId, floorsupportsOptions, isactive, islocked } = this.props
    return (
      <div style={{ display: 'flex', alignItems: 'center', marginRight: '60px' }}>
        <Radio
          key="default-accessories-floorsupports-material"
          disabled={!isactive || islocked}
          dataKey={dataKey}
          prettyName={prettyName}
          margin="0 0 10px 0"
          options={options}
          doNotShowImages
          onChange={(val) =>
            updateFloorsupports({ conveyorId, updatedFloorsupportsFields: { [dataKey]: val } })
          }
          rules={rules}
          vertical
          initialValue={this.props.floorsupportsData.materialid}
        />
        <img
          style={{ maxHeight: '180px' }}
          src={floorsupportsOptions.materials[this.props.floorsupportsData.materialid].image}
          alt="mounting location"
        />
      </div>
    )
  }

  renderSupportMountingField = (config) => {
    const { dataKey, prettyName, options, rules } = config.supportmounting
    const { updateFloorsupports, conveyorId, isactive, islocked } = this.props
    return (
      <div style={{ display: 'flex', alignItems: 'center', marginRight: '60px' }}>
        <Radio
          key="default-accessories-floorsupports-supportmounting"
          disabled={!isactive || islocked}
          dataKey={dataKey}
          prettyName={prettyName}
          margin="0 0 10px 20px"
          options={options}
          doNotShowImages
          onChange={(val) =>
            updateFloorsupports({ conveyorId, updatedFloorsupportsFields: { [dataKey]: val } })
          }
          rules={rules}
          vertical
          initialValue={this.props.floorsupportsData.supportmountingid}
        />
        <img src={config.supportMountingPreview()} alt="mounting location" />
      </div>
    )
  }

  renderDoubleStandoff = () => {
    const {
      conveyorId,
      floorsupportsData: { doublestandoff },
      isactive,
      islocked,
      updateFloorsupports,
    } = this.props
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Checkbox
          key="doublestandoff"
          disabled={!isactive || islocked}
          dataKey="doublestandoff"
          label="Double Standoff (All supports per Conveyor)"
          onChange={(val) =>
            updateFloorsupports({ conveyorId, updatedFloorsupportsFields: { doublestandoff: val } })
          }
          checked={doublestandoff}
        />
      </div>
    )
  }

  render() {
    if (this.props.loading) {
      return null
    }

    const {
      floorsupportsData: { parameteractive, details },
      floorsupportsMeta: { open },
      updateFloorsupports,
      conveyorId,
      updateDefaultMaterialAcessoriesMetaData,
      isactive,
      islocked,
    } = this.props

    const config = floorsupportsConfig(this.props)

    return (
      <div className="material-accessory-item">
        <DefaultHeader
          noReset
          conveyorId={conveyorId}
          fieldKey="floorsupports"
          checked={parameteractive}
          onClickCheck={() => {
            updateDefaultMaterialAcessoriesMetaData({
              conveyorId,
              type: 'accessories',
              name: 'floorsupports',
              updatedFields: {
                open: !parameteractive,
              },
            })
            updateFloorsupports({
              conveyorId,
              updatedFloorsupportsFields: { parameteractive: !parameteractive },
            })
          }}
          open={open}
          onClickOpen={() => {
            updateDefaultMaterialAcessoriesMetaData({
              conveyorId,
              type: 'accessories',
              name: 'floorsupports',
              updatedFields: {
                open: !open,
              },
            })
          }}
          title="Floor Supports"
        >
          <div
            style={{
              display: 'flex',
            }}
          >
            {this.renderSupportMaterialField(config)}
            {this.renderSupportMountingField(config)}
            {this.renderDoubleStandoff()}
          </div>
          <div class="supports-table">
            <EditableTable
              style={{ margin: '10px 0px' }}
              disabled={!isactive || islocked}
              data={details || []}
              columnConfig={config.floorsupportsTable.columns}
              context={React.createContext()}
              onSaveRow={(rowData) => this.props.updateFloorSupport(conveyorId, rowData)}
              onSaveNewRow={(rowData) => this.props.addFloorSupport(conveyorId, rowData)}
              onDeleteRowConfirm={(rowData) => this.props.deleteFloorSupport(conveyorId, rowData.id)}
              onSaveValidationError={(errors) => console.warn(errors)}
              onTableSaveError={(error) => {
                throw new Error(error)
              }}
            />
          </div>
        </DefaultHeader>
      </div>
    )
  }
}

/** @param {import('srcReducer').Store} state */
const mapStateToProps = (state, props) => {
  try {
    const {
      ConveyorReducer,
      EstimatorReducer,
      EstimatorMetaReducer,
      ProjectReducer,
    } = state
    const {
      conveyorId,
      match: {
        params: { versionId },
      },
    } = props

    const floorsupportsData =
      EstimatorReducer[conveyorId] &&
      EstimatorReducer[conveyorId].accessories &&
      EstimatorReducer[conveyorId].accessories.floorsupports
    const floorsupportsMeta =
      EstimatorMetaReducer[conveyorId].materialsAccessoriesMetadata.accessories.floorsupports
    const { isactive, islocked } = ProjectReducer.versions[versionId]
    const isMetric = ConveyorReducer.conveyors[conveyorId].unit === 'Metric'

    if (!floorsupportsData) {
      return { loading: true }
    }

    return {
      versionId,
      conveyorId,
      floorsupportsMeta,
      floorsupportsData,
      floorsupportsOptions: ProjectReducer.currentlistsettings.accessories.floorsupports,
      isactive,
      islocked,
      isMetric,
    }
  } catch (error) {
    captureSentryError(error, state)
  }
}

export default withRouter(
  connect(mapStateToProps, {
    updateFloorsupports,
    updateDefaultMaterialAcessoriesMetaData,
    addFloorSupport,
    updateFloorSupport,
    deleteFloorSupport,
  })(Floorsupports)
)
