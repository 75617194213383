import { connect } from 'react-redux'
import React, { Component } from 'react'
import ConveyorCard from 'features/Conveyor/ConveyorCard'
import { addConveyorToVersion, updateConveyorsOrder } from 'features/Conveyor/redux/ConveyorActions'
import { getConveyorsOrdersSorted } from 'features/Conveyor/redux/ConveyorSelectors'
import ProjectSummary from 'features/Project/components/ProjectSummary'
import ProjectNotes from 'features/Project/components/ProjectNotes'
import { selectProjectFromQueueOrSearch } from 'features/Project/redux/ProjectActions'
import { loadProject } from 'features/Project/redux/ProjectOperations'
import { getVersionWithAllDetails } from 'features/Version/redux/VersionActions'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { Icon } from 'shared/components'
import {
  deletePendingResponse,
  setLoadingOverride,
  setModalContent,
} from 'shared/redux/ScreenActions'
import AuthenticationService from 'utils/api/authentication/AuthenticationService'
import './ProjectScreen.scss'
import { validateProjectAndUpdateTabs } from 'features/Project/components/ProjectSummary/utils/validateProject'
import { captureSentryError } from 'utils/helpers'

class ProjectScreen extends Component {
  state = {
    loaded: false,
    order: [],
  }

  componentDidMount() {
    const { projectId, versionId } = this.props.match.params
    const {
      deletePendingResponse,
      getVersionWithAllDetails,
      loadedProject,
      setLoadingOverride,
      setModalContent,
    } = this.props
    deletePendingResponse()
    setModalContent(null)

    // Waiting 1sec for App.js didmount to complete first.
    setTimeout(async () => {
      setLoadingOverride({
        loadingOverride: true,
        loadingMessage: `Loading Details for ${loadedProject?.name || 'Project'}...`,
      })
      if (loadedProject == null)
      {
        await this.props.loadProject(projectId)
      }
      else
      {
        this.props.selectProjectFromQueueOrSearch(projectId)
      }
      await getVersionWithAllDetails(versionId, true).then((conveyorCount) => {
        if (conveyorCount) setLoadingOverride({ loadingOverride: false })
        this.setState({ loaded: true })
        validateProjectAndUpdateTabs(versionId)
      })
    }, 1000)

    // If we upgrade to react concurrent mode we can do this
    // const messagekey = 'conveyorload'
    // window.addEventListener(messagekey, (e) => {
    //   const { detail: { index, total } } = e
    //   const loadingMessage = document.querySelector('.loading-message')
    //   loadingMessage.textContent = `Loading Conveyor ${ index + 1 } of ${ total }`
    // })
  }

  componentWillUnmount() {
    const { projectId } = this.props.match.params
    AuthenticationService.releaseProjectLock(projectId)
  }

  addConveyor = () => {
    const { addConveyorToVersion, versionId } = this.props
    addConveyorToVersion(versionId)
  }

  renderConveyors = () => {
    const { conveyors, versionMetadata, orders, isactive, islocked } = this.props
    const disableDND = !isactive || islocked
    return orders.map(({ conveyorid, order }, i) => {
      const conveyor = conveyors[conveyorid]
      return versionMetadata && versionMetadata[conveyorid] ? (
        <Draggable
          key={`draggable-conveyor--${conveyorid}`}
          draggableId={`draggable-conveyor--${conveyorid}`}
          index={Number(i)}
          isDragDisabled={disableDND}
        >
          {(provided, snapshot) => {
            const onMouseDown = (() => {
              return (event) => {
                if (event.target.classList.contains('grabzone')) {
                  provided.dragHandleProps.onMouseDown(event)
                }
              }
            })()

            return (
              <div
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                onMouseDown={onMouseDown}
                style={{
                  // padding: '10px 8px',
                  margin: '0 0 16px',
                  userSelect: 'none',
                  minWidth: '1200px',
                  background: 'transparent',
                  border: 'none',
                  outline: 'none',
                  cursor: 'auto',
                  ...provided.draggableProps.style,
                }}
              >
                <ConveyorCard
                  key={conveyor.id}
                  conveyorId={conveyor.id}
                  isDragging={snapshot.isDragging}
                  isDragDisabled={disableDND}
                />
              </div>
            )
          }}
        </Draggable>
      ) : null
    })
  }

  onDragEnd = async (result) => {
    const { orders, versionId } = this.props
    const { destination, source } = result

    if (destination?.index === undefined || source?.index === undefined) {
      return
    }

    let newOrders = [...orders]
    newOrders.splice(destination.index, 0, newOrders.splice(source.index, 1)[0])
    newOrders = newOrders.map((no, i) => ({ ...no, order: i + 1 }))

    this.props.updateConveyorsOrder(versionId, newOrders)
  }

  render() {
    const {
      conveyors,
      listReducerFetched,
      versionMetadata,
      versionId,
      isactive,
      islocked,
    } = this.props
    const { loaded } = this.state

    const noConveyors = !Object.keys(conveyors || {}).length

    return (
      <div className="version-container">
        {!loaded || noConveyors || !listReducerFetched || !versionMetadata ? null : (
          <>
            <ProjectSummary />
            <div className="conveyor-container">
              <DragDropContext onDragEnd={this.onDragEnd}>
                <Droppable droppableId="conveyor-droppable">
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                      style={{ width: '100%', minWidth: '1200px' }}
                    >
                      {this.renderConveyors()}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
              {isactive && !islocked ? (
                <div
                  role="button"
                  className="add-a-conveyor"
                  onClick={this.addConveyor}
                  data-testid="addaconveyor"
                >
                  <Icon icon="add" size={20} color="currentColor" />
                  Add a Conveyor
                </div>
              ) : null}
            </div>
            <ProjectNotes key={versionId} versionId={versionId} />
          </>
        )}
      </div>
    )
  }
}

/** @param {import('srcReducer').Store} state */
const mapStateToProps = (state, props) => {
  try {
    const { projectId, versionId } = props.match.params
    const versionMetadata = state.ConveyorMetaReducer[versionId]
    const {
      projectQueueResults,
      searchResults,
      versions,
    } = state.ProjectReducer
    const { conveyors } = state.ConveyorReducer
    const loadedProject = projectQueueResults?.[projectId] ?? searchResults?.[projectId]
    const orders = getConveyorsOrdersSorted(conveyors)
    const { isactive, islocked } = versions?.[versionId] || { isactive: false, islocked: true }

    return {
      conveyors,
      listReducerFetched: Object.keys(state.ListReducer.chains).length,
      loadedProject,
      versionId,
      versionMetadata,
      orders,
      isactive,
      islocked,
    }
  } catch (error) {
    captureSentryError(error, state)
  }
}

const mapDispatchToProps = {
  addConveyorToVersion,
  deletePendingResponse,
  getVersionWithAllDetails,
  setLoadingOverride,
  setModalContent,
  selectProjectFromQueueOrSearch,
  updateConveyorsOrder,
  loadProject
}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectScreen)
