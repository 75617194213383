import React, { Component } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { Form, Radio, Col, Row } from 'antd'
import { Button, Icon, Input, Select } from 'shared/components'
import addUserConfig from './addUserConfig'
import './AddUser.scss'
import { setModalContent } from 'shared/redux/ScreenActions'
import { createUser } from 'shared/redux/ListActions'

interface State {
  error?: boolean
  newUserInfo: {
    firstname: string
    lastname: string
    username: string
    email: string
    password: string
    password_check: string
    roles: Array<string>
  }
}

interface Props extends PropsFromRedux {
  form: Form['props']['form']
}

class AddUser extends Component<Props, State> {
  state: Readonly<State> = {
    error: null,
    newUserInfo: {
      firstname: '',
      lastname: '',
      username: '',
      email: '',
      password: '',
      password_check: '',
      roles: [],
    },
  }

  handleSubmit = () => {
    const { newUserInfo } = this.state
    const { createUser } = this.props
    const payload = { ...newUserInfo }
    this.validateFields().then(async () => {
      delete payload.password_check
      payload.roles.push('InternalUser')
      createUser(payload)
        .catch((e) => this.setState({ error: e }))
    })
  }

  renderAddUserFields = () => {
    const { newUserInfo } = this.state
    const { getFieldDecorator } = this.props.form
    return addUserConfig(newUserInfo).map((field, i) => {
      return (
        <Col span={field.cols || 12} key={i}>
          {(() => {
            switch (field.type) {
              case 'input':
                return (
                  <Form.Item label={field.label}>
                    {getFieldDecorator(field.key, {
                      rules: field.rules,
                    })(
                      <Input
                        placeholder={field.placeholder}
                        onChange={(_value) => {
                          this.setState({
                            error: null,
                            newUserInfo: {
                              ...newUserInfo,
                              [field.key]: _value,
                            },
                          })
                        }}
                        type={field.key.includes('password') ? 'password' : 'text'}
                      />
                    )}
                  </Form.Item>
                )
              case 'select': {
                return (
                  <Form.Item label={field.label}>
                    {getFieldDecorator(field.key, {
                      rules: field.rules,
                    })(
                      <Select
                        mode="multiple"
                        large
                        placeholder={field.placeholder}
                        data-testid={`select${field.key}`}
                        onChange={(value) =>
                          this.setState({
                            error: null,
                            newUserInfo: {
                              ...newUserInfo,
                              [field.key]: value,
                            },
                          })
                        }
                        options={field.options}
                      />
                    )}
                  </Form.Item>
                )
              }
              case 'radio': {
                return (
                  <Form.Item label={field.label}>
                    {getFieldDecorator(field.key, { initialValue: field.value })(
                      <Radio.Group
                        name={field.key}
                        onChange={(e) => {
                          const {
                            target: { value },
                          } = e
                          const optionValues = field.options.map((opt) => opt.value)
                          const isStringBool =
                            optionValues.includes('true') && optionValues.includes('false')
                          this.setState(({ newUserInfo }) => ({
                            error: null,
                            newUserInfo: {
                              ...newUserInfo,
                              [field.key]: isStringBool ? value === 'true' : value,
                            },
                          }))
                        }}
                        options={field.options}
                      />
                    )}
                  </Form.Item>
                )
              }
              default:
                return null
            }
          })()}
        </Col>
      )
    })
  }

  validateFields = () => {
    const { validateFields } = this.props.form
    return new Promise((resolve, reject) => {
      validateFields((errors) => {
        if (errors) {
          reject()
        } else {
          resolve(false)
        }
      })
    })
  }

  render() {
    const {
      error,
      newUserInfo: { email, roles, ...requiredInfo },
    } = this.state
    const disabled = !Object.values(requiredInfo).every(Boolean) || !roles.length

    return (
      <div id="add-user">
        <h1 className="add-user__title" data-testid="modaltitle">
          Add New Internal User
          {error ? <p className="error">{error}</p> : null}
        </h1>
        <Icon
          className="add-user__close"
          icon="x"
          size={30}
          onClick={() => this.props.setModalContent(null)}
        />
        <Form className="add-user__form">
          <Row gutter={16} className="ant-row-relative">
            {this.renderAddUserFields()}
          </Row>
        </Form>
        <div className="add-user__button">
          <Button
            small
            disabled={disabled}
            text="Submit"
            onClick={this.handleSubmit}
            data-testid="submitnewuserbtn"
          />
        </div>
      </div>
    )
  }
}

const AddUserForm = Form.create({ name: 'add-user' })(AddUser)

const mapDispatchToProps = { createUser, setModalContent }
const connector = connect(null, mapDispatchToProps)
type PropsFromRedux = ConnectedProps<typeof connector>
export default connector(AddUserForm)
