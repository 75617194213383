import React, { Component } from 'react'
import { Form, Input } from 'antd'
import { Select } from 'shared/components'
import Box from './Box'
import ADP from 'awesome-debounce-promise'
import { isInputValueTypeValid } from 'utils/helpers'
import { productDetailsConfig, productDimensionsConfig, legendFields } from '../productConfig'
import '../Product.scss'

const CONFIG_MAP = {
  dimensions: productDimensionsConfig,
  details: productDetailsConfig,
  legend: legendFields,
}

/**
 * @typedef {import('antd/lib/form').FormProps['form']} FormComponentProps
 * @typedef {import('../redux/ProductReducer').Product} Product
 */

/**
 * @typedef {Object} Props
 * @prop {number|string} conveyorId
 * @prop {number|string} productId
 * @prop {Object} conveyor
 * @prop {Product} product
 * @prop {Function} updateProduct
 * @prop {Function} deletePendingResponse
 * @prop {Function} setPendingResponse
 * @prop {Function} setProductValidity
 * @prop {Function} validateGeneralSettings
 * @prop {Function} validateAndUpdateConveyor
 * @prop {boolean} isactive
 * @prop {boolean} islocked
 * @prop {FormComponentProps} form
 * @prop {boolean} canEditLegendFields
 *
 * @extends {Component<Props>}
 */

class ProductInner extends Component {
  state = {
    autorotate: false,
    boxNeedsUpdating: false,
    payload: {},
  }

  componentDidMount() {
    this.validateProduct()
  }

  componentDidUpdate(prevProps) {
    const { conveyor } = this.props

    if (prevProps.conveyor.unit !== conveyor.unit) {
      this.props.form.resetFields()
    }
  }

  validateProduct = (callback) => {
    const { form } = this.props
    form.validateFields((errors, values) => {
      if (callback) callback(errors, values)
    })
  }

  lumpUpdateInputs = () => {
    const {
      conveyorId,
      productId,
      updateProduct,
      deletePendingResponse,
      setPendingResponse,
      validateGeneralSettings,
      setProductValidity,
    } = this.props

    this.validateProduct((errors, values) => {
      const payload = { ...this.state.payload }
      if (errors) {
        const keys = Object.keys(errors)
        keys.forEach((k) => delete payload[k])
      }
      setProductValidity({
        conveyorId,
        productId,
        valid: !errors,
      })
      if (Object.keys(payload).length) {
        setPendingResponse(`updateConveyor-${conveyorId}-${productId}`)

        updateProduct(conveyorId, productId, payload)

        const newPayload = Object.keys(payload).reduce((acc, key) => {
          if (key in acc && acc[key] === payload[key]) {
            delete acc[key]
          }
          return acc
        }, this.state.payload)
        this.setState({ payload: newPayload })
        deletePendingResponse(`updateConveyor-${conveyorId}-${productId}`)
      }
      validateGeneralSettings()
    })
  }

  debouncedInputs = ADP(this.lumpUpdateInputs, 1500)

  /** @param {import('shared/types/formConfig.d').FormConfigItem[]} config */
  getFieldsFromConfig = (config) => {
    const {
      form: { getFieldDecorator },
      isactive,
      islocked,
    } = this.props
    const disabled = !isactive || islocked

    return config.map((field, i) => {
      if (field.visible) {
        switch (field.type) {
          case 'input': {
            const handleChange = (e) => {
              e.persist()
              const { value } = e.target
              if (value === '') return
              if (!isInputValueTypeValid(value, field.inputType)) return
              this.setState(
                ({ payload }) => ({
                  payload: {
                    ...payload,
                    [field.key]: e.target.value,
                  },
                }),
                this.debouncedInputs
              )
            }

            return (
              <Form.Item key={i} label={field.prettyName}>
                {getFieldDecorator(field.key, {
                  initialValue: field.value,
                  rules: field.rules,
                })(
                  <Input
                    disabled={disabled}
                    autoFocus={field.key === 'productlength'}
                    className={field.addonAfter ? 'right' : ''}
                    addonAfter={field.addonAfter ? field.addonAfter : false}
                    placeholder={field.placeholder}
                    name={field.key.toString()}
                    onChange={handleChange}
                  />
                )}
              </Form.Item>
            )
          }
          case 'select': {
            const handleChange = (value) => {
              if (value === '') return
              this.setState(
                ({ payload }) => ({
                  payload: {
                    ...payload,
                    [field.key]: value,
                  },
                }),
                this.debouncedInputs
              )
            }

            return (
              <Form.Item key={i} label={field.prettyName}>
                {getFieldDecorator(field.key, {
                  initialValue: field.value,
                  rules: field.rules,
                })(
                  <Select
                    disabled={disabled}
                    placeholder={field.placeholder}
                    onChange={handleChange}
                    width="140px"
                    options={field.options}
                  />
                )}
              </Form.Item>
            )
          }
          case 'text': {
            return (
              <Form.Item key={i} label={field.prettyName}>
                {getFieldDecorator(field.key, {
                  initialValue: field.value,
                  rules: field.rules,
                })(
                  <div className="product__text-form-item">
                    <div className="value">
                      {field.value}
                      {field.addonAfter || ''}
                    </div>
                  </div>
                )}
              </Form.Item>
            )
          }
          case 'textarea': {
            const handleChange = (e) => {
              e.persist()
              if (e.target.value === '') return
              this.setState(
                ({ payload }) => ({
                  payload: {
                    ...payload,
                    [field.key]: e.target.value,
                  },
                }),
                this.debouncedInputs
              )
            }

            return (
              <Form.Item key={i} label={field.prettyName} className="ant-form-item-fullwidth">
                {getFieldDecorator(field.key, {
                  initialValue: field.value,
                  rules: field.rules,
                })(
                  <Input.TextArea
                    disabled={disabled}
                    name={field.key.toString()}
                    autoSize={{ minRows: 4 }}
                    onChange={handleChange}
                  />
                )}
              </Form.Item>
            )
          }
          default:
            return null
        }
      } else {
        return null
      }
    })
  }

  renderForm = (key) => {
    const { product } = this.props
    const config = CONFIG_MAP[key]
    const formConfig = product ? config(this.props, product) : config(this.props)
    return this.getFieldsFromConfig(formConfig)
  }

  render() {
    const { conveyorId, isactive, islocked, product, canEditLegendFields } = this.props
    const { autorotate, boxNeedsUpdating } = this.state

    return (
      <div className="product-container" id={`product-${product.id}`}>
        <div className="product__left">
          <div className="products__subsubtitle">Product Dimensions</div>
          <div className="products__subsection dimensions">
            <Form
              className={`products__subsection__form dimensions ${
                isactive && !islocked ? '' : 'version-inactive'
              }`}
            >
              {this.renderForm('dimensions')}
            </Form>
          </div>

          <div className="products__subsubtitle">Product Details</div>
          <div className="products__subsection details">
            <Form
              className={`products__subsection__form details ${
                isactive && !islocked ? '' : 'version-inactive'
              }`}
            >
              {this.renderForm('details')}
            </Form>
          </div>

          {canEditLegendFields ? (
            <>
              <div className="products__subtitle">Legend Details (Optional)</div>
              <div className="products__subsection details">
                <Form
                  className={`products__subsection__form details ${
                    isactive && !islocked ? '' : 'version-inactive'
                  }`}
                >
                  {this.renderForm('legend')}
                </Form>
              </div>
            </>
          ) : null}
        </div>
        <Box
          conveyorId={conveyorId}
          autorotate={autorotate}
          boxNeedsUpdating={boxNeedsUpdating}
          boxHeight={canEditLegendFields ? '456px' : '320px'}
          updateBoxFxn={(needsUpdate) => this.setState({ boxNeedsUpdating: needsUpdate })}
          {...product}
        />
      </div>
    )
  }
}

/**
 * @type {React.ComponentClass<Pick<FormComponentProps & Props, any>, any>}
 */
const productInnerForm = Form.create({ name: 'product-inner' })(ProductInner)

export default productInnerForm
