const roles = [
  'AccountManager',
  'Manager',
  'Estimator',
  'Designer',
  'CustomerService',
] as const

type ArrayToTuple<T extends ReadonlyArray<string>, V = string> = keyof {
  [K in T extends ReadonlyArray<infer U> ? U : never]: V
}

export type RoleOption = ArrayToTuple<typeof roles>

export const roleOptions = roles.map((role) => ({
  value: role,
  label: role.split(/(?=[A-Z])/).join(' '),
}))

export default roles
