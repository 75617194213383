import React from 'react'
import { connect, ConnectedProps } from 'react-redux'
import AuthenticationService from 'utils/api/authentication/AuthenticationService'
import { Form, Icon } from 'shared/components'
import SignatureButton from './SignatureButton'
import { setModalContent } from 'shared/redux/ScreenActions'
import { Store } from 'reduxStore'
import './updateSignature.scss'

/** @param {Props} props */
function UpdateSignature ({setModalContent}) {
  const [_signature, setSignature] = React.useState<string>('')

    const fetchSignature = React.useCallback(async () => {
        try {
          const sig = await AuthenticationService.getSignature()
          setSignature(sig.filecontent)
        } catch (error) {
          setSignature('')
        }
      },[])
    
      React.useEffect(() => {
        fetchSignature()
      }, [fetchSignature])
        
  return (
    <div id="update-signature">
      <h1 className="update-signature__title">Update Signature</h1>
      <Icon className="update-signature__close" icon="x" size={30} onClick={() => setModalContent(null)} />
      <Form className="update-signature__form">
        <img className="update-signature__signature" src={_signature}/>
      </Form>
      <SignatureButton handleSuccess={fetchSignature} />
      </div>
  )
}

const UpdateSignatureForm = Form.create({ name: 'update-signature' })(UpdateSignature)

const mapStateToProps = (state: Store) => {
    return {
    }
}

const mapDispatchToProps = {
  setModalContent,
}

const connector = connect(mapStateToProps, mapDispatchToProps)
type PropsFromRedux = ConnectedProps<typeof connector>
export default connector(UpdateSignatureForm)
