import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Collapse } from 'react-collapse'
import BuilderList from './components/BuilderList'
import BuilderCanvas from './components/BuilderCanvas'
import { Button } from 'shared/components'
import './ConveyorBuilder.scss'
import TMResultsTable from './components/TMResultsTable'
import { Icon } from 'antd'
import Colors from 'shared/constants/colors'
import { getConveyor } from '../../../Conveyor/redux/ConveyorActions'
import conveyorSectionTypes from 'shared/constants/conveyorSectionTypes'
import {
  createConveyorSection,
} from 'features/Estimator/components/ConveyorBuilder/redux/ConveyorSectionActions'
import { DRIVE_SELECTION_DISABLED_REASON } from './components/BuilderList/helpers'
import { typedUseSelector } from 'utils/helpers'
import {
  selectHasInfeedDrive,
  selectHasDischargeDrive,
  getAllowIntermediateDrives,
} from './redux/selectors'
import { useDispatch } from 'react-redux'
import { selectVersionId } from 'features/Version/redux/VersionSelectors'
import { AddSectionButton } from './components/BuilderList/components'
import './components/BuilderList/BuilderList.scss'

interface Props {
  conveyorId: number
}

function ConveyorBuilder (props: Props) {
  const { conveyorId } = props

  const dispatch = useDispatch()
  const conveyor = typedUseSelector((state) => state.ConveyorReducer.conveyors[conveyorId])
  const versionId = typedUseSelector(selectVersionId)
  const isactive = typedUseSelector(
    (state) => state.ProjectReducer?.versions?.[versionId]?.isactive
  )
  const islocked = typedUseSelector(
    (state) => state.ProjectReducer?.versions?.[versionId]?.islocked
  )
  const hasInfeedDrive = typedUseSelector((state) => selectHasInfeedDrive(state, conveyorId))
  const hasDischargeDrive = typedUseSelector((state) => selectHasDischargeDrive(state, conveyorId))
  const allowIntermediatedrives = typedUseSelector((state) =>
    getAllowIntermediateDrives(state, conveyorId)
  )
  const chainsList = typedUseSelector((state) => state.ListReducer.chains)
  const isMultiSpanClosedTop =
    chainsList[conveyor.chain.chainserieid].name === 'MultiSpan Closed-Top'
  const hasIntermediateDrive = Object.values(conveyor?.conveyorsections || {}).some(
    (section) => section.type === 'IntermediateDrive'
  )

  const [pendingRequest, setPendingRequest] = React.useState(false)
  const [tmresultsCollapsed, seTmresultsCollapsed] = React.useState(true)

  React.useEffect(() => {
    getConveyor(conveyorId)
  },[conveyorId])

  function getDisabledStatus(key: string) {
    let disabledReason = ''
    let disabled = false

    if (key === 'HorizontalCurve') {
      if (isMultiSpanClosedTop) {
        disabled = true
        disabledReason = DRIVE_SELECTION_DISABLED_REASON.MULTISPAN_CLOSEDTOP
      }
    } else if (key === 'IntermediateDrive') {
      if (!allowIntermediatedrives) {
        disabled = true
        disabledReason = DRIVE_SELECTION_DISABLED_REASON.CHAIN_STYLE_DOESNT_ALLOW_INTERMEDIATE
      } else if (hasIntermediateDrive) {
        disabled = true
        disabledReason = DRIVE_SELECTION_DISABLED_REASON.HAS_INTERMEDIATE
      } else if (hasInfeedDrive || hasDischargeDrive) {
        disabled = true
        disabledReason = DRIVE_SELECTION_DISABLED_REASON.HAS_INFEED_OR_DISCHARGE
      }
    }

    return { disabled, disabledReason }
  }

  function handleAddSection(sectionType) {
    dispatch(createConveyorSection(conveyor.id, sectionType))
  }

  function AddAddSection()
  {
    if (isactive && !islocked) {
      return (
        <div className="add-a-section">
          <div className="section-type-buttons">
            <div className="text">Add a Section</div>
            {Object.entries(conveyorSectionTypes).map(([k, sectionType]) => {
              const { disabled, disabledReason } = getDisabledStatus(k)

              return (
                <AddSectionButton
                  key={k}
                  onClick={() => handleAddSection(k)}
                  disabled={disabled}
                  disabledReason={disabledReason}
                >
                  {sectionType}
                </AddSectionButton>
              )
            })}
          </div>
        </div>
      )
    }
    
    return null
  }

  return (
    <div className={`conveyorbuilder conveyor-${conveyorId}`}>
      {AddAddSection()}
      <div className="conveyorbuilder__container">
        <BuilderList conveyorId={conveyorId} />
        <BuilderCanvas conveyorId={conveyorId} />
      </div>
      {AddAddSection()}
      <div className="buttons-container">
        <Button
          secondary
          small
          text={`${tmresultsCollapsed ? 'Show' : 'Hide'} Torquemaster Results`}
          onClick={() => seTmresultsCollapsed(!tmresultsCollapsed)}
          width={232}
        />
        {pendingRequest ? (
          <Icon className="tm-pending" type="loading" spin style={{ color: Colors.novaBlue }} />
        ) : null}
      </div>
      <Collapse isOpened={!tmresultsCollapsed}>
        <TMResultsTable conveyorId={conveyorId} />
      </Collapse>
    </div>
  )
}

export default ConveyorBuilder