import React from 'react'
import { useDispatch } from 'react-redux'
import queryString from 'query-string'
import { useLocation, useParams  } from 'react-router-dom'
import { setLoading, closeConfirmModal } from 'shared/redux/ScreenActions'
import { codeExchange } from 'features/User/redux/UserActions'
import { history } from '../../App'
import { purgeAndRefresh } from 'shared/redux/ScreenActions'

function CallbackScreen() {
    const dispatch = useDispatch()
    const { search } = useLocation()
    const values = queryString.parse(search)
    const { code } = values
    const { callbackId } = useParams()

    React.useEffect(() => {
        if (process.env.NODE_ENV !== 'test') {
          dispatch(purgeAndRefresh())
        }
        dispatch(closeConfirmModal())
        dispatch(setLoading(false))
      }, []) // eslint-disable-line react-hooks/exhaustive-deps
    
    if (code !== '' && callbackId !== '') {
        dispatch(codeExchange({ code, callbackId }))
        history.push('/')
      }
  
    return (
      <div>

      </div>
    )
}
export default CallbackScreen
