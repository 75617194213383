import * as React from 'react'
import { Select } from 'shared/components'
import { Form, Tooltip } from 'antd'
import { FormConfigItem } from 'shared/types/formConfig'
import { WrappedFormUtils } from 'antd/lib/form/Form'
import { IConveyorSection } from 'shared/types/swagger'

interface Props {
  isactive: boolean
  islocked: boolean
  field: FormConfigItem
  form: WrappedFormUtils
  item: IConveyorSection
  updateSection: (payload: { [key: string]: unknown }) => void
}

/** @param {Props} props */
export default function BuilderTileSelect(props: Props) {
  const { form, field, item, isactive, islocked, updateSection } = props
  // MultiSpanClosedTop cannot have Horizontal Curves

  const selectOptions = (
    <Form.Item label={field.label}>
      {form.getFieldDecorator(field.key, { initialValue: item[field.key] })(
        <Select
          disabled={!isactive || islocked}
          placeholder={field.placeholder}
          onChange={(value, option) => {
            updateSection({ [field.key]: value })
          }}
        >
          {Array.isArray(field.options)
            ? field.options.map((option) => {
                return (
                  <Select.Option
                    key={`select-item-${option.value}`}
                    value={option.value as string}
                    disabled={option.disabled}
                  >
                    {option.disabled ? (
                      <Tooltip
                        mouseEnterDelay={0}
                        mouseLeaveDelay={0}
                        overlay={option.disabledReason}
                      >
                        <span style={{ display: 'block', width: '100%' }}>{option.label}</span>
                      </Tooltip>
                    ) : (
                      <span>{option.label}</span>
                    )}
                  </Select.Option>
                )
              })
            : null}
        </Select>
      )}
    </Form.Item>
  )
  return (
    <div
      className="builder-tile__expanded__content__wrapper"
      key={`form-item-${field.key}-${item.id}`}
    >
      <div className="option-label">{field.prettyName}</div>
      <div
        className={`option-content option-content__${field.prettyName}`}
        style={{ width: '200px' }}
      >
        {selectOptions}
      </div>
    </div>
  )
}
