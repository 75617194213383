import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import _ from 'lodash'
import VersionCard from '../VersionCard'
import { Icon, Button } from 'shared/components'
import './VersionSelect.scss'
import { setModalContent } from 'shared/redux/ScreenActions'
import { getVersions } from 'features/Project/redux/ProjectOperations'
import ProjectService from 'utils/api/project/ProjectService'
import { typedUseSelector } from 'utils/helpers'
import { downloadPriceValidationComparisonFile } from 'features/Version/redux/VersionActions'

function VersionSelect(props) {
  const {  selectedProject, versions } = typedUseSelector(
    (state) => state.ProjectReducer
  )
  const dispatch = useDispatch()

  useEffect(() => {
    (async () => {
      if (selectedProject?.id) {
        await dispatch(getVersions(selectedProject.id))
      }
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { roles, userId, userType } = typedUseSelector((state) => state.UserReducer)
  const [compareIds, setCompareIds] = useState([])
  const [compareIndexes, setCompareIndexes] = useState([])
  const [disableCompare, setDisableCompare] = useState(true)
  const [compareButtonTitle, setCompareButtonTitle] = useState("Compare prices")

  const handleClickCompare = (value: any, idVersion: number, indexVersion: number) =>
  {
    var updatedCompareIds = compareIds;
    var updatedCompareIndexes = compareIndexes;
    if (value === true && !compareIds.includes(idVersion))
    {
      setDisableCompare(compareIds.length<1)
      updatedCompareIds.push(idVersion)
      updatedCompareIndexes.push(indexVersion)
    }
    if (value === false && compareIds.includes(idVersion))
    {
      setDisableCompare(compareIds.length<3)
      updatedCompareIds = compareIds.filter((e, i) => e !== idVersion)
      updatedCompareIndexes = compareIndexes.filter((e, i) => e !== indexVersion)
    } 

    setCompareIds(updatedCompareIds)
    setCompareIndexes(updatedCompareIndexes)
    setCompareButtonTitle("Compare prices" 
        + (updatedCompareIndexes.length > 0 ? " v"+ updatedCompareIndexes[0] : "")
        + (updatedCompareIndexes.length > 1 ? " with v"+ updatedCompareIndexes.filter((e, i) => e !== compareIndexes[0]).join(", v") : ""))
  }

  const renderVersionCards = () => {
    return _.map(versions, (version, i) => {
          if (!roles.includes('ExternalUser') || version.userid == userId)
          {
            return <VersionCard key={i} version={version} project={selectedProject} handleClickCompare={handleClickCompare} />
          }
          else{
            return null
          }
        })
  }

  return (
    <div className="select-version">
      <div className="select-version__top-container">
        <h1>Select a Version</h1>
        <div className="select-version__right-container">
        {userType !== 'External'
            ? (<Button
                  small
                  text={compareButtonTitle} 
                  disabled={disableCompare}
                  onClick={() =>
                    dispatch(downloadPriceValidationComparisonFile(compareIds[0], compareIds.filter((e, i) => e !== compareIds[0])))
                  }
                />)
            :  null}
          <Icon icon="x" size={30} onClick={() => dispatch(setModalContent(null))} />
          </div>
        </div>
      {renderVersionCards()}
    </div>
  )
}

export default VersionSelect
