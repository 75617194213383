import * as React from 'react'
import { useRef } from 'react'
import { useDispatch } from 'react-redux'
import { Button, Input } from 'shared/components'
import { login, signup } from 'features/User/redux/UserActions'
import './LoginScreen.scss'
import { setLoading, closeConfirmModal, openConfirmModal } from 'shared/redux/ScreenActions'
import { logout } from 'features/User/redux/UserActions'
import ReCAPTCHA from 'react-google-recaptcha'
import { validateEmail } from 'utils/helpers'

function LoginScreen() {
  const dispatch = useDispatch()
  const [username, setUsername] = React.useState('')
  const [password, setPassword] = React.useState('')
  const [signUp, setSignUp] = React.useState(false)
  const [firstname, setFirstname] = React.useState('')
  const [lastname, setLastname] = React.useState('')
  const [companyname, setCompanyName] = React.useState('')
  const [email, setEmail] = React.useState('')
  const [phone, setPhone] = React.useState('')
  const [isexistingcustomer, setIsExistingCustomer] = React.useState(false)
  const [message, setMessage] = React.useState('')
  const [signupRequestSent, setSignupRequestSent] = React.useState(false)
  const recaptcha = useRef()

  React.useEffect(() => {
    if (process.env.NODE_ENV !== 'test') {
      dispatch(logout())
    }
    dispatch(closeConfirmModal())
    dispatch(setLoading(false))
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  async function handleSubmit(e) {
    e.preventDefault()
    const captchaValue = recaptcha.current.getValue()    
    if (!captchaValue) {
      alert('Please verify the reCAPTCHA!')
    } 
    else if (signUp && !validateEmail(email))
    {
      alert('Invalid email address!')
    }
    else 
    {
      recaptcha.current.reset()
      if (signUp)
      { 
        try {
          await dispatch(signup({ firstname, lastname, companyname, email, phone, message, isexistingcustomer, captchaValue }))
          setSignupRequestSent(true)
        } catch (e) {
          dispatch(
            openConfirmModal({
              headerText: 'Error',
              bodyText: e.error,
              cancelButton: false,
              confirmButtonText: 'OK',
            })
          )
        }
      }
      else
      {
        dispatch(login({ username, password, captchaValue }))
      }
    }
  }

  return (
    <div className="login-container">
      <div className="login-left">
        <img
          className="login-left__background"
          alt="Artistic blueprint designs of spantech's conveyors and parts"
          src={`${process.env.PUBLIC_URL}/img/login_background.jpg`}
        />
        <img
          className="login-left__logo"
          alt="Logo. Spantech. Outlast, Outperform, Outdeliver"
          src={`${process.env.PUBLIC_URL}/img/spantech_logo.png`}
        />
      </div>
      <main className="login-right">
        <div className="login-right__container">
          <img
            className="login-right__logo"
            src={`${process.env.PUBLIC_URL}/img/ez_suite_logo.png`}
            alt="EZ Suite logo showing the words EZ Suite inside a gear"
          />
          {signUp ? (<h1 className="login-right__heading">SIGN UP</h1>) : (<h1 className="login-right__heading">LOG IN</h1>)}          
          <h2 className="login-right__subheading">Outlast. Outperform. Outdeliver.</h2>
          {!signupRequestSent ? (
            <div>
              <p>
                Span Tech’s EZ Suite helps you design innovative conveyor solutions for your needs. We
                are revolutionizing the conveyor industry, one link at a time.
              </p>
              {signUp ? (<p>
                Access to EZ-Suite is only granted to our distributors.
              </p>) : (<div/>)}
              <div className="login-right__signuploginlinks">
                {signUp ? (<div>You have your credentials? Login</div>) : (<div>Not a user yet? Sign-up</div>)}
                <div
                  onClick={() => setSignUp(!signUp)} 
                  className="login-right__switch"
                >here</div>
              </div>
              <p/>
              <form className="login-right__form" onSubmit={handleSubmit}>
                {signUp ? 
                (
                  <div className="login-right__form-signup">
                    <label htmlFor="firstname">First name</label>
                    <Input
                      id="firstname"
                      name="firstname"
                      type="text"
                      required
                      value={firstname}
                      onChange={(value) => setFirstname(value)}
                    />
                    <label htmlFor="lastname">Last name</label>
                    <Input
                      id="lastname"
                      name="lastname"
                      type="text"
                      required
                      value={lastname}
                      onChange={(value) => setLastname(value)}
                    />
                    <label htmlFor="companyname">Company name</label>
                    <Input
                      id="companyname"
                      name="companyname"
                      type="text"
                      required
                      value={companyname}
                      onChange={(value) => setCompanyName(value)}
                    />
                    <Input
                      id="isexistingcustomer"
                      name="isexistingcustomer"
                      small
                      width="25px"
                      type="checkbox"
                      label="I am already a Spantech customer"
                      value={isexistingcustomer}
                      onChange={(value) => setIsExistingCustomer(value)}
                    />
                    <label htmlFor="email">Email</label>
                    <Input
                      id="email"
                      name="email"
                      type="text"
                      required
                      value={email}
                      onChange={(value) => setEmail(value)}
                    />
                    <label htmlFor="phone">Phone</label>
                    <Input
                      id="phone"
                      name="phone"
                      type="text"
                      required
                      value={phone}
                      onChange={(value) => setPhone(value)}
                    />
                    <label htmlFor="message">Message</label>
                    <Input
                      id="message"
                      name="message"
                      autosize="true"
                      type="textarea"
                      value={message}
                      onChange={(value) => setMessage(value)}
                    />
                  </div>
                ) : (
                  <div>
                    <label htmlFor="username">Username</label>
                    <Input
                      id="username"
                      name="username"
                      large
                      type="text"
                      value={username}
                      onChange={(value) => setUsername(value)}
                    />
                    <label htmlFor="password">Password</label>
                    <Input
                      id="password"
                      name="password"
                      large
                      type="password"
                      value={password}
                      onChange={(value) => setPassword(value)}
                    />
                  </div>
                )} 
                <ReCAPTCHA ref={recaptcha} sitekey={process.env.REACT_APP_SITE_KEY} />
                <section className="login-right__form-bottom">
                  <Button
                    disabled={signUp 
                              ? false 
                              : [username, password].some((v) => !v)}
                    text={signUp ? 'SEND SIGN-UP REQUEST' : 'LOG IN'}
                    aria-label="Click to submit the form and sign in"
                    type="submit"
                    className="login-right__submit"
                  />
                </section>
              </form>
            </div>
          ) : (
            <p>
              Your Sign-up request has been transfered to our commercial team. Once reviewed, you will receive your credentials through email. You can close this window.
            </p>
          )}
        </div>
      </main>
    </div>
  )
}

export default LoginScreen
