import React, { memo } from 'react'
import { Icon } from 'shared/components'
import Colors from 'shared/constants/colors'
import { typedUseSelector } from 'utils/helpers'

/**
 * @typedef {Object} Props
 * @prop {*} handleClick
 */

/** @param {Props} props */
function SwitchVersionButton({ handleClick }) {
  const isExternal = typedUseSelector((state) => state.UserReducer.userType === 'External')
  return (
    <button className="project-summary__firstrow__switch-version" onClick={handleClick}>
      <Icon icon="sync" size={16} color={Colors.novaBlue} />
      {isExternal ? 'Switch Version' : 'Switch/Compare Version(s)'}
    </button>
  )
}

export default memo(SwitchVersionButton)
